<template>
    <div class="page-content">
        <nav class="page-breadcrumb" id="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{breadcrumbA}}</li>
                <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
            </ol>
        </nav>

        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                        <p class="card-description">
                            {{descripcion}}
                        </p>

                        <!-- Mensaje de carga -->
                        <div v-if="isLoading" class="alert alert-warning">
                            Cargando datos... Esto puede tardar varios segundos.
                        </div>

                        <!-- Semáforo de Sectores -->
                        <div v-else>
                            <div v-for="(sectores, fecha) in semaforo" :key="fecha" class="block">
                                <h2>Novedades para el {{ fecha }}</h2>
                                <div v-for="sector in sectores" :key="sector.nombre" :class="['sector', sector.estado]">
                                    {{ sector.nombre }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Semaforo',
   
data() {
    // Calcular las fechas dinámicamente
    const hoy = new Date();
    const fechaHasta = new Date(hoy);
    fechaHasta.setDate(hoy.getDate() - 1); // Hasta el día de ayer

    const fechaDesde = new Date(hoy);
    fechaDesde.setDate(hoy.getDate() - 4); // Desde hace 4 días

    const desde = fechaDesde.toISOString().split('T')[0]; // Formatear la fecha a 'YYYY-MM-DD'
    const hasta = fechaHasta.toISOString().split('T')[0]; // Formatear la fecha a 'YYYY-MM-DD'

    const mes = (fechaHasta.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes actual y formatear a dos dígitos
    const anio = fechaHasta.getFullYear(); // Obtener el año actual

    return {
        breadcrumbA: 'Inicio',
        breadcrumbB: 'Semaforo de sectores',
        descripcion: 'En este modulo usted podra visualizar el status de carga de novedades en los sectores',
        semaforo: {}, // Aquí se almacenarán los datos del semáforo
        isLoading: true, // Variable para controlar el estado de carga
        excluirSectores: [
            "DIRECCIÓN/PLANTA",
            "ASIGNACIÓN TEMPORAL",
            "PRE-LEGALES",
            "SECTOR ZONALES",
            "VOLANTES",
            "EATY/PLANTA",
            "CALIDAD/OPERACIONES",
            "CAPACITACIÓN/OPERACIONES",
            "GERENTES",
            "JEFES DE SECTOR",
            "MANTENIMIENTO/OPERACIONES",
            "JEFES REGIONALES",
            "PERSONAL SUPERNUMERARIO/CAPACITACION",
            "OPERACIONES"
        ],
        sectoresApiUrl: 'https://aplicativos.firebaseio.com/sectores.json?print=pretty',
        presentismoApiUrl: `https://auth.foodservice.com.ar/?type=presentismo_reporte_semaforo&desde=${desde}&hasta=${hasta}&mes=${mes}&anio=${anio}&access_token=1234567`
    };
},

methods: {
    obtenerUltimos4Dias() {
        const hoy = new Date();
        const fechas = [];

        for (let i = 1; i <= 4; i++) {
            const fecha = new Date(hoy);
            fecha.setDate(hoy.getDate() - i);
            const fechaFormateada = fecha.toISOString().split('T')[0];
            fechas.push(fechaFormateada);
        }

        return fechas;
    },
    cargarDatos() {
        this.isLoading = true; // Mostrar mensaje de carga al iniciar la carga de datos

        axios.get(this.sectoresApiUrl).then(responseSectores => {
            axios.get(this.presentismoApiUrl).then(responsePresentismo => {
                const sectores = responseSectores.data;
                const presentismo = responsePresentismo.data;
                const fechas = this.obtenerUltimos4Dias();

                const semaforoData = {};

                fechas.forEach((fechaCompleta) => {
                    const diaBloque = parseInt(fechaCompleta.split('-')[2]); // Extraer solo el día del mes de la fecha del bloque

                    semaforoData[fechaCompleta] = sectores.filter(sector => 
                        !this.excluirSectores.includes(sector.operacion)
                    ).map(sector => {
                        // Verificamos si hay alguna "A" en el sector para el día específico del bloque
                        const tieneAusente = presentismo.some(persona => 
                            persona.sector === sector.operacion && 
                            persona.asistencia.some(asistencia => 
                                parseInt(asistencia.dia) === diaBloque && asistencia.asistencia === 'A'
                            )
                        );
                        return {
                            nombre: sector.operacion,
                            estado: tieneAusente ? 'red' : 'green'
                        };
                    });
                });

                this.semaforo = semaforoData;
                this.isLoading = false; // Ocultar mensaje de carga después de completar la carga de datos
            }).catch(error => {
                console.error('Error al cargar el reporte de presentismo:', error);
                this.isLoading = false; // Ocultar mensaje de carga en caso de error
            });
        }).catch(error => {
            console.error('Error al cargar sectores:', error);
            this.isLoading = false; // Ocultar mensaje de carga en caso de error
        });
    }
}

,
    mounted() {
        this.cargarDatos();
    }
};
</script>

<style scoped>
.block {
    margin-bottom: 20px;
}
.block h2 {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
}
.sector {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    width: 200px;
    text-align: center;
    font-size: 10px; /* Tamaño de la fuente ajustado a 18px */
}
.green { background-color: green; }
.red { background-color: red; }
.alert {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
}
</style>
