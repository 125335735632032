<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">
              {{ descripcion }}
            </p>
            <!-- <button @click="borrarLosIlegales()">Boton</button> -->
          </div>
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="legales"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">DNI</span></th>
                    <th><span class="text-muted">Legajo</span></th>
                    <th><span class="text-muted">CUIL</span></th>
                    <!-- <th><span class="text-muted">legajo</span></th> -->
                    <th><span class="text-muted">Nombre</span></th>


                    <th><span class="text-muted">Localidad</span></th>
                    <th><span class="text-muted">Domicilio</span></th>
                    <th><span class="text-muted">Entre Calles</span></th>
                    <th><span class="text-muted">Telefono</span></th>
                    <th><span class="text-muted">Email</span></th>
                    <!-- <th><span class="text-muted">Responsable</span></th> -->
                    <th><span class="text-muted">Acción</span></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(caso, k) in legales" :key="k">
                        <td>
                            <span 
                              class="badge text-uppercase"
                              :class="{'badge-success': caso.status, 'badge-danger': !caso.status}"
                            >
                              {{caso.statusMensaje}}
                            </span>
                        </td>
                        <td>{{ caso.dni }}</td>
                         <td>{{ caso.legajo }}</td>
                          <td>{{ caso.cuil }}</td>
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>

                        <td class="">{{ caso.localidad }}</td>
                        <td class="">{{ caso.domicilio }}</td>
                        <td class="">{{ caso.calles }}</td>
                        <td class="">{{ caso.telefono }}</td>
                        <td class="">{{ caso.email }}</td>


                        <td>
                            <button v-if="isEditor('Legales')==true" 
                                class="btn btn-sm btn-primary"
                                @click.prevent="cambiarStatus(caso)"
                            >
                            Cambiar status
                            </button>
                            <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Cambiar status</button>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");

import ListaCarga from '../ListaCarga';

import firebase from "firebase/app";
import "firebase/database";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
  name: "Legales",
  components: {ListaCarga},
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Solicitud de Mi Food Service",
    descripcion: "Listado de solicitudes de cambio de ingormacion en el legajo del personal",
    legales: [],
    load:true,

  }),
  methods: {
    // borrarLosIlegales(){
    //   // this.borrarlo("3277693")
    //   axios
    //     .get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
    //     .then(legalesResponse=>{
    //       Object.keys(legalesResponse.data).forEach(dni=>{
    //         this.borrarlo(dni);
    //       })
    //     })
    // },
    // borrarlo(dni){
    //   // let legajo = null;
    //   axios
    //   .get("https://apprrhh-707b9.firebaseio.com/personal/"+dni+".json?print=pretty")
    //   .then(personalResponse=>{
    //     let legajoObtenido = personalResponse.data.leg_numero;
    //     axios
    //     .get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty')
    //     .then(distri=>{
    //       Object.keys(distri.data).forEach(opereta=>{
    //         Object.keys(distri.data[opereta]).forEach(fireId=>{
    //           if (distri.data[opereta][fireId].legajo == legajoObtenido) {
    //             console.log("Esta en distribucion con la key: " + fireId);
    //             firebase.database().ref("/distribucion/"+opereta+"/"+fireId).remove()
    //           }
    //         })
    //       })
    //     })
        
    //   })
    // },
    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
    cambiarStatus(caso){
        let statusMensaje = (caso.statusMensaje == "Resuelto") ? "Sin Resolver" : "Resuelto"; 

        firebase.database().ref('datos_personal/' + caso.dni ).update({
            status: statusMensaje,
        })
        .then(()=>{
            caso.status = !caso.status;
            caso.statusMensaje = statusMensaje;
        })
        .catch(e => {
            console.log(e);
        });
    }
  },
  async mounted() {


               const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

                    const reemplazarStatus = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "En Espera"
                    }
                    return string
                }







          const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
          const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/datos_personal.json?print=pretty');

          await axios.all([axiosrequest1, axiosrequest2]).then(
               axios.spread((personal, response) => {



                 const getCuil = (dni) => {
                        var dato = "";
                        $.each(personal.data, (key, value) => {

                          if (value.leg_numdoc==dni) {
                            dato = value.leg_cuil;
                          }

                        })

                        return dato;

                }


                 const getLegajo = (dni) => {
                        var dato = "";
                        $.each(personal.data, (key, value) => {

                          if (value.leg_numdoc==dni) {
                            dato = value.leg_numero;
                          }

                        })

                        return dato;

                }


                const getNombre = (dni) => {
                        var dato = "";
                        $.each(personal.data, (key, value) => {

                          if (value.leg_numdoc==dni) {
                            dato = value.leg_apellido+" "+value.leg_nombre;
                          }

                        })

                        return dato;

                }


   
                        var vue = this;
                        $.each(response.data, (dni, item) => {
                            
                          
                            if (item != null) {
                              vue.legales.push({
                                  dni: dni,
                                  fireId: dni,
                                  nombre: getNombre(dni),
                                  cuil:getCuil(dni),
                                  legajo:getLegajo(dni),

                                  statusMensaje: reemplazarStatus(item.status),
                                  
                                  status: (item.status == "Resuelto") ? true : false,

                                  localidad: reemplazarIndefinido(item.localidad),
                                  domicilio: reemplazarIndefinido(item.domicilio),
                                  calles: reemplazarIndefinido(item.calles),
                                  telefono: reemplazarIndefinido(item.telefono),
                                  email: reemplazarIndefinido(item.email)

                              });
                            }
                        });
                        
                        this.load = false;



                        setTimeout(function(){

                            $('#legales').dataTable({
                                      "language": {
                                      "decimal": ",",
                                      "thousands": ".",
                                      "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                      "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                      "infoPostFix": "",
                                      "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                      "loadingRecords": "Cargando...",
                                      "lengthMenu": "Mostrar _MENU_ registros",
                                      "paginate": {
                                      "first": "Primero",
                                      "last": "Último",
                                      "next": "Siguiente",
                                      "previous": "Anterior"
                                      },
                                      "processing": "Procesando...",
                                      "search": "Buscar:",
                                      "searchPlaceholder": "Término de búsqueda",
                                      "zeroRecords": "No se encontraron resultados",
                                      "emptyTable": "Ningún dato disponible en esta tabla",
                                      "aria": {
                                      "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                      "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                      },
                                      },
                                      "scrollX": false, 
                                      "search": {regex:true},
                                      dom: 'Bfrtip',
                                      responsive: false,
                                      nowrap: false,
                                      buttons: [
                                      'copy',
                                      'excel',
                                      'csv',
                                      'pdf'
                                      ],
                                       "initComplete": function(settings, json) {
  
                                          
                                                console.log("completo");
                                                $('#legales').DataTable().order([0, 'desc']).draw();
                                           
                
                
                                            // call your function here
                                        },
                                  });


                        },1000)
            
                    
          }));

}

  
}
</script>

            