<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>

            <div class="d-flex align-items-center">
              <p class="card-description w-50 p-0 m-0">
                {{ descripcion }}
              </p>
              <div class="w-50 d-flex align-items-center">
                  <!-- Select mes -->
                  <select class="custom-select mr-2" id="mes">
                      <option v-for="mes in meses" :key="mes.codigo" :value="mes.codigo" >
                          {{ mes.nombre }}
                      </option>
                  </select>
                  <!-- Select año -->
                  <select id="anio" class="custom-select mr-2">
                    <option v-for="anio in anios" :key="anio" :value="anio"> {{ anio }} </option>
                  </select>
                  <!-- Button -->
                  <button
                    class="btn btn-success"
                    id="consultarbaja"
                    v-on:click="consultarbaja()"
                  >
                    Consultar
                  </button>
              </div>
              <button class="btn btn-success w-25" v-if="empleados!=null && empleados.length!=0" @click="modalAsignar=true">Asignar nuevo locker</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-info" v-if="datos.length == 0" role="alert">
      Seleccioná una fecha para comenzar la búsqueda de bajas.
    </div>

    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="padding">
              <div class="table-responsive">
                <table
                  id="bajas"
                  class="table table-theme table-row v-middle"
                  
                >
                  <thead>
                    <tr>
                      <th><span class="text-muted">Documento</span></th>
                      <th><span class="text-muted">Legajo</span></th>
                      <th><span class="text-muted">Nombre</span></th>
                      <th><span class="text-muted">Apellido</span></th>
                      <th><span class="text-muted">Categoria</span></th>
                      <th><span class="text-muted">Operación</span></th>
                      <th><span class="text-muted">Fecha Ingreso</span></th>
                      <th><span class="text-muted">Fecha Egreso</span></th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(dato, index) in datos" :key="index">
                      <td>{{ dato.leg_numdoc }}</td>
                      <td>{{ dato.leg_numero }}</td>
                      <td>
                        <span
                          class="item-amount d-none d-sm-block text-sm text-wrap"
                          >{{ dato.leg_nombre }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="item-amount d-none d-sm-block text-sm text-wrap"
                          >{{ dato.leg_apellido }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="item-amount d-none d-sm-block text-sm text-wrap"
                          >{{ categorias[dato.cat_codigo] }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="item-amount d-none d-sm-block text-sm text-wrap"
                          >{{ dato.lpg_codigo }}</span
                        >
                      </td>
                      <td>
                        {{
                          dato.leg_fecingr.date.replace(" 00:00:00.000000", "")
                        }}
                      </td>
                      <td>
                        {{
                          dato.leg_fecegr.date.replace(" 00:00:00.000000", "")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
import moment from "moment";
$.fn.dataTable.ext.errMode = "none";





export default {
  name: "Bajas",
  data() {
    return {
      breadcrumbA: "Inicio",
      breadcrumbB: "Bajas de personal",
      descripcion: "Lista de personal dadas de baja en el mes en curso",
      datos: [],
      categorias: [],
      operaciones: [],
      meses: [
        { codigo: "01", nombre: "Enero" },
        { codigo: "02", nombre: "Febrero" },
        { codigo: "03", nombre: "Marzo" },
        { codigo: "04", nombre: "Abril" },
        { codigo: "05", nombre: "Mayo" },
        { codigo: "06", nombre: "Junio" },
        { codigo: "07", nombre: "Julio" },
        { codigo: "08", nombre: "Agosto" },
        { codigo: "09", nombre: "Septiembre" },
        { codigo: "10", nombre: "Octubre" },
        { codigo: "11", nombre: "Noviembre" },
        { codigo: "12", nombre: "Diciembre" },
      ],
      anios: [],
    };
  },
  methods: {
    consultarbaja: function() {

$('#bajas').DataTable().destroy();

      // Obtenemos las fechas a partir
      let mes = $("#mes option:selected").val();
      let anio = $("#anio option:selected").val();

      let fechas = this.obtenerFechasDelRango(mes, anio);
      const inicio = fechas[0];

      var d = new Date(inicio);
      d.setDate(d.getDate() -1);



       var me = d.getMonth() + 1;
    var mese = me < 10 ? "0" + me : me;
    var diae = d.getDate();
    diae = diae < 10 ? "0" + diae : diae;
    var principio = d.getFullYear() +"-" + mese  + "-" + diae ;

      const hoy = fechas[1];

      let url ='https://auth.foodservice.com.ar/?type=bajas&desde='+principio+'&hasta='+hoy+'&access_token=1234567';

      axios.get(url).then((response) => {

          

          this.datos = response.data;
             
          this.$nextTick(() => {
            $('#bajas').DataTable({
                "language": {
                    "decimal": ",",
                    "thousands": ".",
                    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "infoPostFix": "",
                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "loadingRecords": "Cargando...",
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "paginate": {
                        "first": "Primero",
                        "last": "Último",
                        "next": "Siguiente",
                        "previous": "Anterior"
                    },
                    "processing": "Procesando...",
                    "search": "Buscar:",
                    "searchPlaceholder": "Término de búsqueda",
                    "zeroRecords": "No se encontraron resultados",
                    "emptyTable": "Ningún dato disponible en esta tabla",
                    "aria": {
                        "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                        "sortDescending": ": Activar para ordenar la columna de manera descendente"
                    },
                },
                "scrollX": false, 
                "search": {regex:true},
                dom: 'Bfrtip',
                responsive: false,
                nowrap: false,
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]
            });

          })

            var vm = this;
           axios
              .get(
                "https://aplicativos.firebaseio.com/categorias.json?print=pretty"
              )
              .then((response) => {
                $.each(response.data, function(i, item) {
                  vm.categorias[item.codigo] = item.nombre;
                });
              });
    });      

    },


    obtenerFechasDelRango(mes, anio) {
      let d = anio + "-" + mes + "-01";
      let check = moment(d, "YYYY/MM/DD");
      let diasmes = moment(d, "YYYY/MM/DD").daysInMonth();
      let month = check.format("MM");
      let year = check.format("YYYY");

      let nfe = year + "-" + month + "-01";
      let nfs = year + "-" + month + "-" + diasmes;

      let beforeSevenDays = moment(nfe).format("YYYY-MM-DD");
      let hoy = moment(nfs).format("YYYY-MM-DD");

      let inicio = moment(beforeSevenDays).format();

      return [inicio, hoy];
    },
  },
  mounted() {},
  beforeMount() {
    let today = moment();
    let year = today.format("YYYY");

    let x;
    for (x = parseInt(year); x >= 2018; x--) {
      this.anios.push(x);
    }
  },
};
</script>


<style scoped>
.custom-select {
  font-size: 0.875rem;
  line-height: 1;
  padding: .5rem 1rem .4rem;
}
</style>