<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters">
                            <div class="col-10">Bloquear acceso para {{ nombreEmpleado }}</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" data-dismiss="modal" @click.prevent="close()" id="cerrar"> x </button>
                            </div>
                        </div>
                        <!-- Success title -->
                        <div class="modal-body" v-if="!success">
                            <label for="listaEmpleados" class="form-label mt-2">Agregar una observación</label>
                            <div class="alert alert-success w-100" v-if="triggers.success">✓ {{triggers.successMessage}}</div>
                            <div class="alert alert-danger w-100" v-if="(!triggers.success) && triggers.dangerMessage!=null">✓ {{triggers.dangerMessage}}</div>                            
                            

                            <textarea v-if="isEditor('Personal')==true" class="w-100 form-control" v-model="observacion" @change="errors.observacion = false" rows="3"></textarea>
                            <textarea v-else class="w-100 form-control" v-model="observacion" @change="errors.observacion = false" rows="3" disabled="true"></textarea>
                           

                            <p class="text-danger my-2" v-if="errors.observacion">· Lo observación no puede estar vacia</p>
                            
                            <div class="w-100 text-right my-2" v-if="isEditor('Personal')==true">
                                <button type="button" class="btn btn-danger" @click.prevent="quitarObservacion()">Quitar bloqueo</button>
                                <button type="button" class="btn btn-success ml-2" @click.prevent="agregarObservacion()">Bloquear ahora</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: "ModalBloquear",
    props: ['dniEmpleado','nombreEmpleado','apellidoEmpleado','legajoEmpleado'],
    data(){
        return {
            errors: {
                observacion: false 
            },
            triggers: {
                success: false,
                successMessage: null,
                dangerMessage: null,
            },
            observacion: '',
            seObservo: false,
        }
    },
    mounted(){
            this.obtenerObservacion();

           // console.log(this.nombreEmpleado + " " + this.legajoEmpleado);
        },
    methods: {
         isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        obtenerObservacion(){
            this.seObservo = true;
           

            this.limpiarTriggers();
            this.observacion = null;
           
            axios
            .get('https://aplicativos.firebaseio.com/bloqueos/'+ this.dniEmpleado +'.json?print=pretty')
            .then(response => {
                if (response.status == 200) {
                    console.log('https://aplicativos.firebaseio.com/bloqueos/'+ this.dniEmpleado +'.json?print=pretty');
                    this.observacion = (response.data != null) ? response.data.observacion : '';
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        agregarObservacion(){

            this.limpiarTriggers();
            if (this.observacion == '') {
                this.errors.observacion = true;
                return null;
            }else {
                console.log("bloqueado")
               const timestamp = new Date().getTime();
                
                firebase.database().ref('bloqueos/' + this.dniEmpleado).update({
                    observacion:this.observacion,
                    timestamp:timestamp
                }, (error) => {
                    if (error) {
                        alert("Datos no cargados! verifique su conexión a internet")
                    } else {
                        console.log("insertado ok");
                        this.triggers.successMessage = "Observacion realizada correctamente";
                        this.triggers.success = true;
                        this.obtenerObservacion();
                        this.seObservo = true;
                        this.close()
                    }
                });
                



            }

            
        },
        quitarObservacion(){
           

            this.limpiarTriggers();
            if(this.observacion != '' && this.observacion != null){
                firebase.database().ref('bloqueos/' + this.dniEmpleado).remove()
                .then(function() {
                    console.log("Remove succeeded.")
                    console.log("cerrar")
                    $("#cerrar").click();
                })
                .catch(function(error) {
                    console.log("Remove failed: " + error.message)
                });

                this.triggers.successMessage = "Observación eliminada";
                this.observacion = '';
                this.triggers.success = true;
                this.obtenerObservacion();
                this.seObservo = true;
                return true;
            }
            this.triggers.dangerMessage = "No hay nada para eliminar";

            
            
        },
        limpiarTriggers(){
            this.success = false;
            this.successMessage = null;
            this.dangerMessage = null;
        },
        close(){
            console.log("cerrar")
            this.seObservo = true;
            this.$emit('cerrar-modal-observar', this.seObservo);

        },

    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>