<template>
    <div class="page-content">

<!--  ABRE MODAL LEGAJO -->

<div id="info_legajo" style="overflow-y:scroll;">
    <div id="header_legajo">
        <button id="cerrar_legajo" style="position: absolute;right: 20px;margin-top: 3px;background-color:red;font-weight: bold;color:#fff;">CERRAR INFORME</button>
    </div>
    <table width="100%">
        <tr>
            <td width="210px"><center style="padding: 10px;"><div id="legajo_foto"></div></center></td>
            <td valign="top" style="padding: 10px;">
                <table width="100%">
                    
                    <tr>
                        <td width="200px">Nombre y Apellido</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>DNI</td>
                        <td></td>
                    </tr>

                     <tr>
                        <td>Fecha de Nacimiento</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Fecha de Ingreso</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Categoría</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Domicilio</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Provincia</td>
                        <td></td>
                    </tr>

                     <tr>
                        <td>Ciudad</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Nacionalidad</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Tel. Celular</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Email</td>
                        <td></td>
                    </tr>

                   

                </table>
            </td>

            <td width="700px" valign="top" style="padding: 10px;">
                
                <table width="100%">
                    
                    <tr>
                        <td align="center">DIAS</td>
                        <td align="center">VACUNAS</td>
                        <td align="center">CMA</td>
                    </tr>

                    <tr>
                        <td align="center"><div class="circulo"></div></td>
                        <td align="center"><div class="circulo"></div></td>
                        <td align="center"><div class="circulo"></div></td>
                    </tr>

                </table>

                <br><br>

                <table width="100%">
                    
                    <tr>
                        <td width="200px">OPERACIÓN</td><td></td>
                    </tr>

                    <tr>
                        <td>RESPONSABLE</td><td></td>
                    </tr>

                   
                    
                </table>

            </td>
        </tr>
    </table>

   <!-- TABLA DE NOVEDADES -->

<div style="margin-top:30px;">
     <table
                id="novedades_legajo"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                   
                    <th><span class="text-muted">Operación</span></th>

                    
                    <th><span class="text-muted">Novedad</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Horas</span></th>
                    
                    <th><span class="text-muted">Desde</span></th>
                    <th><span class="text-muted">Hasta</span></th>
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Destino</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Doc</span></th>
                    <th><span class="text-muted">Obs</span></th>
                
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                        

                        <td v-if="caso.status=='En espera'">
                            <span  class="badge text-uppercase badge-warning">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Resuelto'">
                            <span  class="badge text-uppercase badge-success">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Observado'">
                            <span  class="badge text-uppercase badge-info">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Rechazado'">
                            <span  class="badge text-uppercase badge-danger">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='-'">
                             <span  class="badge text-uppercase badge-warning">
                             En espera
                            </span>
                        </td>

                        <!--<td :id="'key'+caso.fireId">{{ caso.legajo }}</td>-->
                         <td class="text-sm text-wrap">{{ caso.legajo }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.cuil }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_raiz }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.detalle }}</td>
                        <td class="text-sm text-wrap">{{ caso.horas }}</td>
                       
                        <td class="text-sm text-wrap">{{ caso.desde }}</td>
                        <td class="text-sm text-wrap">{{ caso.hasta }}</td>
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.destino }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                       

                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow(caso.fireId)">Ver Certificado</button></td>
                        
                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes_a'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow_a(caso.fireId)">Ver Certificado</button></td>

                        <td class="text-sm text-wrap" v-if="caso.certificado=='not'">-</td>

                        <td class="text-sm text-wrap comentario" v-if="caso.observacion=='1'"> 

                          <i :data-fire="caso.fireId" onclick="localStorage.setItem('status_id',$(this).data('fire'))" class="feather icon-mail" @click="mensajes()" style="font-size: 20px;color: green;"></i>

                          <i v-if="caso.noti=='1'" :id="'key'+caso.fireId" class="feather icon-radio" style="font-size: 20px;color: red;"><span style="visibility:hidden;font-size:1px;">Sin responder</span></i>
                          <i v-else></i>

                        </td>
                        
                        <td class="text-sm text-wrap" v-else> - </td>
                       


                       



                    </tr>
                </tbody>
              </table>
</div>
   <!-- CIERRA TABLA DE NOVEDADES -->

</div>

<!--  CIERRA MODAL LEGAJO -->


                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>

                             <div class="w-50 d-flex align-items-center">
                  <!-- Select mes -->
                  <select class="custom-select mr-2" id="mes">
                      <option v-for="mes in meses" :key="mes.codigo" :value="mes.codigo" >
                          {{ mes.nombre }}
                      </option>
                  </select>
                  <!-- Select año -->
                  <select id="anio" class="custom-select mr-2">
                    <option v-for="anio in anios" :key="anio" :value="anio"> {{ anio }} </option>
                  </select>

                   <select id="tipo" class="custom-select mr-2">
                    <option value="alta">INGRESOS</option>
                    <option value="baja">EGRESOS</option>
                  </select>
                  <!-- Button -->
                  <button
                    class="btn btn-success"
                    id="consultarbaja"
                    v-on:click="obtenerPersonal()"
                  >
                    Consultar
                  </button>
              </div>
                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  <th>Perfil</th>
                                  <th>Cod</th>
                                  <th>Legajo</th>
                                  <th>Nombre</th>
                                  <th>Fec. Nacimiento</th>
                                  <th>CUIL</th>
                                  
                                  <th>Empleado por</th>
                                  <th>Ingreso</th>
                                  <th>Egreso</th>
                                  <th>Categoria</th>
                                  <th>Operación</th>
                                  <th>Empresa Madre</th>
                                  <th>Tipo</th>
                                  <th>Zonal</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                
                                <tr v-for="personal in personal" v-bind:key="personal" :class="{'fila-no-distribuido':personal.cod=='-'}">
                                    <td v-if="personal.avatar=='-'" style="color:#fff;">2<img width="40px" height="40px" style="border: 1px solid #8b8b8b99;object-fit:cover" src="https://thumbs.dreamstime.com/b/perfil-de-usuario-vectorial-avatar-predeterminado-179376714.jpg"></td>
                                    <td v-else style="color:#fff;">1<img width="40px" height="40px" style="object-fit:cover" :src="personal.avatar"></td>
                                    <td>{{personal.bejerman}}</td>
                                    <!--<td><a href="#" @click="getLegajo(personal.legajo)">{{personal.legajo}}</a></td>-->
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.apellido}} <br> {{personal.nombre}}</td>

                                    <td>{{personal.nacimiento}}</td>
                                    <td>{{personal.cuil}}</td>

                                   

                                      <td v-if="personal.razonsocial=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                    <td v-else><span class="badge badge-info">Food Service Catering</span></td>


                                    


                                    <td>{{personal.ingreso}}</td>
                                    <td>{{personal.egreso}}</td>
                                    <td>{{personal.categoria}}</td>
                                    <td>{{personal.operacion}}</td>
                                     <td v-if="personal.madre=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                   <td v-if="personal.madre=='Food Service Catering'"><span class="badge badge-info">Food Service Catering</span></td>
                                   <td v-if="personal.madre=='-'"><span class="badge badge-danger">Planta</span></td>
                                    <td>{{personal.tipo}}</td>
                                    <td>{{personal.zonal}}</td>
                                  
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>


        <modal-observar
            v-if="modalObservar.open" 
                    :dniEmpleado="modalObservar.dni"
                    :legajoEmpleado="modalObservar.legajo"
                    :nombreEmpleado="modalObservar.nombre"
                    :apellidoEmpleado="modalObservar.apellido"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalObservar.open=false"
        ></modal-observar>


         <modal-vacunas
            v-if="modalVacunas.open" 
                    :dniEmpleado="modalVacunas.dni"
                    :legajoEmpleado="modalVacunas.legajo"
                    :nombreEmpleado="modalVacunas.nombre"
                    :apellidoEmpleado="modalVacunas.apellido"
                    :certificadoEmpleado="modalVacunas.certificado"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalVacunas.open=false"
        ></modal-vacunas>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import modalVacunas from './ModalVacunas.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    components: {ListaCarga, ModalAdministrar, ModalLegales,ModalObservar,modalVacunas},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:false},
            personal: [],
            novedades: [],
             meses: [
        { codigo: "01", nombre: "Enero" },
        { codigo: "02", nombre: "Febrero" },
        { codigo: "03", nombre: "Marzo" },
        { codigo: "04", nombre: "Abril" },
        { codigo: "05", nombre: "Mayo" },
        { codigo: "06", nombre: "Junio" },
        { codigo: "07", nombre: "Julio" },
        { codigo: "08", nombre: "Agosto" },
        { codigo: "09", nombre: "Septiembre" },
        { codigo: "10", nombre: "Octubre" },
        { codigo: "11", nombre: "Noviembre" },
        { codigo: "12", nombre: "Diciembre" },
      ],
      anios: [],
             modalObservar: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
            },
             modalVacunas: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
                certificado:null
            },
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        observarPersonalId(dni, nombre, apellido, legajo){
            this.modalObservar.dni = dni;
            this.modalObservar.nombre = nombre;
            this.modalObservar.apellido = apellido;
            this.modalObservar.legajo = legajo;

            console.log(apellido)
            setTimeout(() => {
                this.modalObservar.open = true;
            }, 200);
        },
        vacunas(dni, nombre, apellido, legajo,certificado){
            

            this.modalVacunas.dni = dni;
            this.modalVacunas.nombre = nombre;
            this.modalVacunas.apellido = apellido;
            this.modalVacunas.legajo = legajo;

            if (certificado!=null) {
                this.modalVacunas.certificado = certificado;
            }
            

            console.log(apellido)
            setTimeout(() => {
                this.modalVacunas.open = true;
            }, 200);
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion,empleade){
            console.log(empleade);

            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){

             // Obtenemos las fechas a partir
      let mes = $("#mes option:selected").val();
      let anio = $("#anio option:selected").val();
      let tipo = $("#tipo option:selected").val();

      let fechas = this.obtenerFechasDelRango(mes, anio);
     
var today = new Date(fechas[1]);
var d = new Date(today.getFullYear(), today.getMonth()+1, 0);



if (tipo=="baja") {
    d.setDate(d.getDate() - 28);
}




      


       var me = d.getMonth() + 1;
    var mese = me < 10 ? "0" + me : me;
    var diae = d.getDate();
    diae = diae < 10 ? "0" + diae : diae;
    var principio = d.getFullYear() +"-" + mese  + "-" + diae ;
    var mesanio = anio +"-" + mes;

console.log(mesanio);


            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?orderBy="$key"&limitToLast=7000&print=pretty');
            const axiosrequest3 = axios.get('https://auth.foodservice.com.ar/?type=bajas_historico&desde='+principio+'&hasta='+principio+'&access_token=1234567');
            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
            const axiosrequest6 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');
            const axiosrequest7 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

            const axiosrequest8 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?orderBy="$key"&limitToLast=7000&print=pretty');
            const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/observacion_personal.json?print=pretty');
            const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/manipulacion.json?print=pretty');
            const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/certificado_vacunacion.json?print=pretty');
            const axiosrequest12 = axios.get('https://aplicativos.firebaseio.com/vacunas_personal.json?print=pretty');
            const axiosrequest13 = axios.get('https://aplicativos.firebaseio.com/perfil.json?print=pretty');

            await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5, axiosrequest6, axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11,axiosrequest12,axiosrequest13]).then(
            axios.spread((operaciones, personal, distribucion, zonal, categorias, legales, sectores,personal_cat,observaciones,manipulacion,vacunacion,dosis,avatar) => {
                var lista = [];
                var observacion = false;

                


                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }

                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }





                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }




                const result_madre = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "-";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.empresa_madre;
                             
                            }
                        })
                    }
                    return operacion;
                }




const result_tipo = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "Planta Central";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.tipo_empresa;
                             
                            }
                        })
                    }
                    return operacion;
                }



                const result_operacion_bejerman = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = 'S'+sectores.data[sector].id;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.catering_id;
                             
                            }
                        })
                    }
                    return operacion;
                }


                 const result_apropiacion = (id) => {
                    var operacion = "";
                    var apropiacion = "";
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                          
                            apropiacion = sectores.data[sector].id_empresa;

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                             
                                // apropiacion = value.centroapropiacion_id
                                apropiacion = value.id
                            }
                        })
                    }
                    return apropiacion;
                }

                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        if (clave == id) {
                            zonales = value.nombre;
                        }
                    })
                    return zonales;
                }


                 const result_manipulador = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.link;
                        }
                    })
                    return manip;
                }


                   const result_manipulador_vto = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.vto;
                        }
                    })
                    return manip;
                }

                const result_vacunacion = (legajo) => {
                    var vacu = "";
                    $.each(vacunacion.data, function(clave, value) {
                        if (clave == legajo) {
                            vacu = value.certificado;
                        }
                    })
                    return vacu;
                }

                const result_dosis = (dni) => {
                    var dos = "";
                    $.each(dosis.data, function(clave, value) {
                        if (clave == dni) {
                            // console.log("datos obtenidos!")
                           dos = Object.keys(value).length/2
                        }
                    })
                    return dos;
                }


                const result_avatar = (dni) => {
                    var avtr = "";
                    $.each(avatar.data, function(clave, value) {
                        if (clave == dni) {
                            avtr = value.imagen;
                        }
                    })
                    return avtr;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

                const reemplazarIndefinidoDosis = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "0"
                    }
                    return string
                }

               
                $.each(personal.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                     console.log(personal.data)
                    
                    if (tipo=="alta") {
                          

                            var egreso = "-";

                            if (valor.leg_fecegr!=null) {
                                egreso=reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", ""));
                            }
                            
                            let distribucion = result_distribucion(valor.leg_numero);
                            


                                if (reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")).includes(mesanio)) {

                                    lista.push({
                                        "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                                        "razonsocial": "Food Service America",
                                        "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                                        "legajo": reemplazarIndefinido(valor.leg_numero),
                                        "nombre": reemplazarIndefinido(valor.leg_nombre),
                                        "apellido": reemplazarIndefinido(valor.leg_apellido),
                                        "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                                        "egreso": egreso,
                                        "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                                        "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                                        "cuil": reemplazarIndefinido(valor.leg_cuil),
                                        "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                                         "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                                        "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                                        "idDistribucion": distribucion.idDistribucion,
                                        "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                                        "dni": valor.leg_numdoc,
                                        "observacion":observacion,
                                        "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                                        "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                                        "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                        "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                        "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                    })

                                }

                            
                        

                    }


                    if (tipo=="baja") {
                            if (valor.leg_fecegr != null) {


                            
                            let distribucion = result_distribucion(valor.leg_numero);
                           


                                if (reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", "")).includes(mesanio)) {

                                    lista.push({
                                        "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                                        "razonsocial": "Food Service America",
                                        "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                                        "legajo": reemplazarIndefinido(valor.leg_numero),
                                        "nombre": reemplazarIndefinido(valor.leg_nombre),
                                        "apellido": reemplazarIndefinido(valor.leg_apellido),
                                        "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                                        "egreso": reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", "")),
                                        "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                                        "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                                        "cuil": reemplazarIndefinido(valor.leg_cuil),
                                        "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                                         "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                                        "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                                        "idDistribucion": distribucion.idDistribucion,
                                        "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                                        "dni": valor.leg_numdoc,
                                        "observacion":observacion,
                                        "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                                        "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                                        "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                        "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                        "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                    })

                                }

                            
                        }

                    }


                });

                $.each(personal_cat.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
           
console.log(personal_cat.data)
                    if (tipo=="alta") {
                           

                            
                            let distribucion = result_distribucion(valor.leg_numero);
                            
 var egreso = "-";

                            if (valor.leg_fecegr!=null) {
                                egreso=reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", ""));
                            }
                            
                            

                                if (reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")).includes(mesanio)) {

                                    lista.push({
                                        "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                                        "razonsocial": "Food Service Catering",
                                        "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                                        "legajo": reemplazarIndefinido(valor.leg_numero),
                                        "nombre": reemplazarIndefinido(valor.leg_nombre),
                                        "apellido": reemplazarIndefinido(valor.leg_apellido),
                                        "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                                        "egreso": egreso,
                                        "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                                        "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                                        "cuil": reemplazarIndefinido(valor.leg_cuil),
                                        "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                                         "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                                        "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                                        "idDistribucion": distribucion.idDistribucion,
                                        "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                                        "dni": valor.leg_numdoc,
                                        "observacion":observacion,
                                        "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                                        "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                                        "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                        "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                        "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                    })

                                }

                            
                        

                    }


                    if (tipo=="baja") {
                            if (valor.leg_fecegr != null) {


                            
                            let distribucion = result_distribucion(valor.leg_numero);
                           


                                if (reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", "")).includes(mesanio)) {

                                    lista.push({
                                        "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                                        "razonsocial": "Food Service Catering",
                                        "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                                        "legajo": reemplazarIndefinido(valor.leg_numero),
                                        "nombre": reemplazarIndefinido(valor.leg_nombre),
                                        "apellido": reemplazarIndefinido(valor.leg_apellido),
                                        "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                                        "egreso": reemplazarIndefinido(valor.leg_fecegr.date.replace(" 00:00:00.000000", "")),
                                        "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                                        "nacimiento": reemplazarIndefinido(valor.leg_fecnac.date.replace(" 00:00:00.000000", "")),
                                        "cuil": reemplazarIndefinido(valor.leg_cuil),
                                        "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                                         "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                                        "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                                        "idDistribucion": distribucion.idDistribucion,
                                        "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                                        "dni": valor.leg_numdoc,
                                        "observacion":observacion,
                                        "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                                        "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                                        "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                        "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                        "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                    })

                                }

                            
                        }

                    }



                });


               
               

                // console.log(lista);
                this.personal = lista;
                this.triggers.load = false;
                })).then(() => {
                    $('#empresas').DataTable({
                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                        dom: 'Bfrtip',
                        
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });
                });

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            this.modalObservar.open = false;
            this.modalVacunas.open = false;
            
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

             
        },

         getLegajo(legajo){
               
$('#novedades_legajo').dataTable().fnDestroy();
 //$("body").css("overflow","hidden");

 $("#info_legajo").slideDown();


  $("#cerrar_legajo").click(function() {
  
                         $("#info_legajo").slideUp();
                          //$("body").css("overflow","scroll");
  
  
                      })

  this.novedades = [];
            this.triggers.load = true;

            axios.get("https://auth.foodservice.com.ar/index_test.php?type=novedades_legajo&legajo="+legajo+"&access_token=1234567")
                .then((response) => {
                    var vue = this;
                    var obs = 0;




                    $.each(response.data, (id, item) => {
                        let caso = item;

                        var certif_u = '-';
                        if (caso != null) {

                            if (caso.observacion != undefined) {

                                obs = 1;
                            } else {
                                obs = 0;
                            }




                            if (caso.certificado_update != undefined) {

                                $.each(caso.certificado_update, function(clab, itb) {

                                    certif_u = itb.certificado;



                                });

                                cert_c = certif_u;
                            }




                            const reemplazarIndefinido = (string) => {
                                if (string == undefined || string == null || string == '') {
                                    return "-"
                                }
                                return string
                            }



                            var fechaInicio = new Date(caso.desde).getTime();
                            var fechaFin = new Date(caso.hasta).getTime();

                            var diff = fechaFin - fechaInicio;
                            var bejerman = "-";
                            if (caso.bejerman_id) {
                                bejerman = caso.bejerman_id;
                            }




                            vue.novedades.push({
                                fireId: id,
                                desde: caso.desde,
                                destino: caso.operacion,
                                detalle: caso.detalle,
                                fecha: caso.fecha,
                                fecha_novedad: caso.fecha_novedad,
                                hasta: caso.hasta,
                                certificado: caso.certificado,
                                legajo: caso.legajo,
                                nombre: caso.nombre,
                                novedad: caso.novedad,
                                horas: reemplazarIndefinido(caso.horas),
                                noti: reemplazarIndefinido(caso.obs),
                                bejerman_id: bejerman,
                                operacion_raiz: caso.operacion_raiz,
                                responsable: caso.responsable,
                                responsable_dni: caso.responsable_dni,
                                status: reemplazarIndefinido(caso.status),
                                observacion: obs,
                                statusMensaje: caso.status,
                                dias: (diff / (1000 * 60 * 60 * 24)) + 1
                            });




                        }
                    });



                })
                .then(() => {

                  this.triggers.load = false;
                  this.$nextTick(()=>{
                 
   
                        
  
                      $('#novedades_legajo').DataTable({
                          "language": {
                              "decimal": ",",
                              "thousands": ".",
                              "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                              "infoEmpty": "Mostrando registros del0 al0 de un total de0 registros",
                              "infoPostFix": "",
                              "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                              "loadingRecords": "Cargando...",
                              "lengthMenu": "Mostrar _MENU_ registros",
                              "paginate": {
                                  "first": "Primero",
                                  "last": "Último",
                                  "next": "Siguiente",
                                  "previous": "Anterior"
                              },
                              "processing": "Procesando...",
                              "search": "Buscar:",
                              "searchPlaceholder": "Término de búsqueda",
                              "zeroRecords": "No se encontraron resultados",
                              "emptyTable": "Ningún dato disponible en esta tabla",
                              "aria": {
                                  "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                                  "sortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                          },
                          "scrollX": false,
                          "search": {
                              regex: true
                          },
                          dom: 'Bfrtip',
                          responsive: false,
                          nowrap: false,
                          "initComplete": function(settings, json) {
  
                              setTimeout(function() {
                                  console.log("completo");
                                  $('#novedades_legajo').DataTable().order([7, 'desc']).draw();
                              }, 2000)
  
  
                              // call your function here
                          },
                          buttons: [
  
                              'excel',
                              'csv',
  
                              {
                                  text: 'En espera',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("En espera").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Observado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Observado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Rechazado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Rechazado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Resuelto',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Resuelto").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Sin responder',
                                  className: 'btn btn-danger',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(16).search("Sin responder").draw();
                                  }
                              },
                              {
                                  text: 'Todos',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("").draw();
                                      table.columns(6).search("").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }
                          ]
                      })
  
  
  
                      const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar_legajo").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades_legajo').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })

                  })


                })

        

                      






        },
         obtenerFechasDelRango(mes, anio) {
      let d = anio + "-" + mes + "-01";
      let check = moment(d, "YYYY/MM/DD");
      let diasmes = moment(d, "YYYY/MM/DD").daysInMonth();
      let month = check.format("MM");
      let year = check.format("YYYY");

      let nfe = year + "-" + month + "-01";
      let nfs = year + "-" + month + "-" + diasmes;

      let beforeSevenDays = moment(nfe).format("YYYY-MM-DD");
      let hoy = moment(nfs).format("YYYY-MM-DD");

      let inicio = moment(beforeSevenDays).format();

      return [inicio, hoy];
    },
    },
    mounted() {
       
    },
    beforeMount() {
    let today = moment();
    let year = today.format("YYYY");

    let x;
    for (x = parseInt(year); x >= 2018; x--) {
      this.anios.push(x);
    }
  },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}


#info_legajo {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999999;
    padding: 40px;
    left: 0;
    top: 0;
    display: none;
}

#info_legajo td{
    border: 1px solid #ccc;
}

#legajo_foto{
    width: 300px;
    height: 300px;
    background-color: #ccc;
}

.circulo {
    width: 100px;
    height: 100px;
    background: #b7b5b5;
    border-radius: 50%;
}

#novedades_legajo td{
    font-size: 10px;
}

#header_legajo{
    width: 100%;
    background-color: #7e86ed;
    height: 37px;
    position: fixed;
    left: 0;
    top: 0;
}
</style>