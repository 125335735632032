<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">
              {{ descripcion }}
              </p>
              <button v-if="isEditor('Orientacion')==true"  class="btn btn-success w-25"  @click="nuevoTicket()">Nueva asignacion</button>
              <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Nueva asignacion</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    


    <lista-carga v-if="load"></lista-carga>
    <!-- Table -->
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="orientaciones"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Estado</span></th>
                    <th><span class="text-muted">Fecha</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Tipo licencia</span></th>
                    <th><span class="text-muted">Inicio licencia</span></th>
                    <th><span class="text-muted">Vigencia</span></th>
                    <th><span class="text-muted">Total dias</span></th>
                    <th><span class="text-muted">Restan</span></th>
                    <th><span class="text-muted">Diagnostico</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    
                    <th><span class="text-muted">Editar</span></th>
                    <th><span class="text-muted">Operacion</span></th>
                    <th><span class="text-muted">Categoria</span></th>
                    <th><span class="text-muted">Sector</span></th>
                    <th><span class="text-muted">Zonal</span></th>
                    <th><span class="text-muted">Tipo de accidente</span></th>
                    <th><span class="text-muted">Plan de accion</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Datos adjuntos</span></th>
                    <th><span class="text-muted">Acción</span></th>
                    
                   
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(caso, k) in orientaciones" :key="k" :class="{'fila-no-distribuido':caso.hoy<1}">
                        <td>
                            <span
                              class="badge text-uppercase"
                              :class="{

                                  'badge-danger': (caso.status == 'ABIERTO'),
                                 
                                  'badge-success': (caso.status == 'CERRADO')
                              }"
                            >
                              {{caso.status}}
                            </span>
                        </td>

                         <td class="text-center">{{caso.fecha}}</td>

                         <td>{{caso.nombre}}</td>

                         <td class="text-center">{{caso.legajo}}</td>

                         <td class="text-uppercase">{{caso.tipo}}</td>

                         <td>{{caso.fechaInicioLicencia}}</td>

                         <td>{{caso.vigencia}}</td>

                         <td>{{caso.dias}}</td>
                         <td>{{caso.hoy}}</td>

                         <td>{{caso.detalle}}</td>
                          <td>{{caso.diagnostico}}</td>

                         <td>
                            <select v-if="isEditor('Orientacion')==true" class="custom-select mr-3 resolucionSelect" @change="editar($event, caso)" id="accion">
                                <option value="0">Selecciona una accion</option>
                                
                               <option value="plan">Editar plan de accion</option>
                               <option value="vigencia">Editar fecha de vigencia</option>
                               <option value="inicio">Editar fecha de inicio</option>
                               <option value="operacion_m">Editar Operacion/Sector</option>
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                <option value="0">Selecciona una accion</option>
                                
                               <option value="plan">Editar plan de accion</option>
                               <option value="vigencia">Editar fecha de vigencia</option>
                               <option value="inicio">Editar fecha de inicio</option>
                               <option value="operacion_m">Editar Operacion/Sector</option>
                            </select>


                        </td>

                         <td>{{caso.distribucionName}}</td>

                         <td>{{caso.categoria}}</td>

                         <td>{{caso.sector}}</td>

                         <td>{{caso.zonal}}</td>

                         <td>{{caso.novedad}}</td>

                         <td>{{caso.plan}}</td>

                          <td>
                           <span
                              class="badge text-uppercase badge-info"
                              
                            >
                              {{caso.responsable}}
                            </span>
                        </td>

                         <td align="center"><a href="#" v-on:click="adjuntos(caso.fireId)" :id="'adj'+caso.fireId">+{{caso.archivos}}</a></td>

                        <td>
                            <select v-if="isEditor('Orientacion')==true" class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="ABIERTO" :selected="caso.status == 'ABIERTO'">ABIERTO</option>
                                
                                <option value="CERRADO" :selected="caso.status == 'CERRADO'">CERRADO</option>
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                <option value="ABIERTO" :selected="caso.status == 'ABIERTO'">ABIERTO</option>
                                
                                <option value="CERRADO" :selected="caso.status == 'CERRADO'">CERRADO</option>
                            </select>


                        </td>


                         
                        
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<div id="archivos">
    <table width="100%">
            <tr>
                <td align="right"><div style="margin:20px;"><button id="cerrar">CERRAR</button></div></td>
            </tr>

            <tr>
                <div id="loading"></div>
                <td id="adj">
                    
                </td>
            </tr>
    </table>
</div>



<div id="plan">
    <table width="100%">
            <tr>
                <td align="right"><div style="margin:20px;"><button id="cerrarPlan">CERRAR</button></div></td>
            </tr>

            <tr>
               
                <td>
                   <div class="form-group">
                    <label for="exampleFormControlTextarea1">Escribe el plan de accion</label>
                    <textarea class="form-control" id="textoPlan" rows="3" style="height:300px;"></textarea>
                  </div>
                </td>
            </tr>

            <tr>
               
                <td>
                    <center><button class='btn-success' id="guardarPlan">GUARDAR PLAN DE ACCION</button></center>
                </td>
            </tr>
    </table>
</div>



<div id="vigencia">
    <table width="100%">
            <tr>
                <td align="right"><div style="margin:20px;"><button id="cerrarVigencia">CERRAR</button></div></td>
            </tr>

            <tr>
               
                <td>
                   <div class="form-group">
                    <label for="exampleFormControlTextarea1">Selecciona nueva fecha de fin de licencia</label>
                    <input type="date" class="form-control" id="textoVigencia">
                  </div>
                </td>
            </tr>

             <tr>
               
                <td>
                   <div class="form-group">
                    <b>Fecha actual de vigencia:</b> <span id="fechaactual"></span>
                  </div>
                </td>
            </tr>

            <tr>
               
                <td>
                    <center><button class='btn-success' id="guardarVigencia">GUARDAR NUEVA FECHA DE VIGENCIA</button></center>
                </td>
            </tr>
    </table>
</div>




<div id="inicio">
    <table width="100%">
            <tr>
                <td align="right"><div style="margin:20px;"><button id="cerrarInicio">CERRAR</button></div></td>
            </tr>

            <tr>
               
                <td>
                   <div class="form-group">
                    <label for="exampleFormControlTextarea1">Selecciona nueva fecha de inicio de licencia</label>
                    <input type="date" class="form-control" id="textoInicio">
                  </div>
                </td>
            </tr>

             <tr>
               
                <td>
                   <div class="form-group">
                    <b>Fecha actual de inicio:</b> <span id="fechaactualInicio"></span>
                  </div>
                </td>
            </tr>

            <tr>
               
                <td>
                    <center><button class='btn-success' id="guardarInicio">GUARDAR NUEVA FECHA DE INICIO</button></center>
                </td>
            </tr>
    </table>
</div>



<div id="operacion_m">
    <table width="100%">
            <tr>
                <td align="right"><div style="margin:20px;"><button id="cerrarOperaciones">CERRAR</button></div></td>
            </tr>

            <tr>
               
                <td>
                   <div class="form-group">
                    <label for="exampleFormControlTextarea1">Selecciona una nueva operacion</label>
                    <select class="form-control" id="operacion_id"></select>
                  </div>
                </td>
            </tr>

             <tr>
               
                <td>
                   <div class="form-group">
                    <b>Operacion actual:</b> <span id="operacion_actual"></span>
                  </div>
                </td>
            </tr>

            <tr>
               
                <td>
                    <center><button class='btn-success' id="guardarOperacion">GUARDAR NUEVA OPERACION/SECTOR</button></center>
                </td>
            </tr>
    </table>
</div>



  <modal-detalle 
    v-if="modalDetalle.open" 
    @cerrar-modal-detalle="refrescarOrientaciones($event)"
    :caso="modalDetalle.caso"
  ></modal-detalle>

  <modal-nuevo-ticket 
    v-if="modalNuevoTicket.open"
    @cerrar-modal-nuevo="refrescarOrientaciones($event)"
    :personal="personal"
  ></modal-nuevo-ticket>
</template>

<script>
import axios from "axios";
import $ from "jquery";

require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");

import firebase from "firebase/app";
import "firebase/database";
import ModalDetalle from './ModalDetalle.vue';
import ModalNuevoTicket from './ModalNuevoTicket.vue';
import ListaCarga from '../ListaCarga.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
  const empleado = JSON.parse(sessionStorage.getItem("personal"));
export default {
  components: { ModalDetalle, ModalNuevoTicket, ListaCarga },
  name: "Orientacion",
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Asignacion Temporal",
    descripcion: "Listado de reclamos del personal, podés listar por fecha, resolver y detallar los inconvenientes",
    orientaciones: [],
    personal: [],
    distribuido:[],
    desde: null,
    hasta: null,
    modalDetalle : {
        open: false,
        caso: null,
    },
    modalNuevoTicket: {
        open: false,
    },
    load: true,

  }),
  methods: {
    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
           
            return status;
          }else{
            
            return false;
          }
        },
    obtenerOrientaciones(){
      this.load = true;
      this.orientaciones = null;
      $('#orientaciones').dataTable().fnDestroy();
      this.$nextTick(()=>{
        this.orientaciones = [];
      })
      

        
        let url = "https://aplicativos.firebaseio.com/asignaciontemporal.json?orderBy=%22$key%22&print=pretty";
        
        axios
        .get(url)
        .then(response => {

           
            var vue = this;
            $.each(response.data, (fireId, item) => {


               
                    

                    if (item.status==undefined) {
                        var status = "Sin resolver";
                    }else{
                        var status = item.status;
                    }

                     if (item.responsable==undefined) {
                        var responsable = "Sin asignar";
                    }else{
                        var responsable = item.responsable;
                    }

                  
                    const dias = (new Date(item.fechaFinLicencia) - new Date(item.fechaInicioLicencia)) / (1000 * 60 * 60 * 24);
                    

                    const fechaInicio = new Date(item.fechaFinLicencia);
                    fechaInicio.setHours(0, 0, 0, 0); // Establece la hora, minutos, segundos y milisegundos a cero
                    const fechaFinal = new Date();
                    fechaFinal.setHours(0, 0, 0, 0); // Igualmente para la fecha actual
                    const hoy = (fechaInicio-fechaFinal) / (1000 * 60 * 60 * 24);



                    var contable_id="";
                    var contable_desc="";
                    var ubicacion=item.distribucion;
                    if (ubicacion=="029081515"||ubicacion=="033503144"||ubicacion=="033503155"||ubicacion=="033503166"||ubicacion=="033503177"||ubicacion=="67"||ubicacion=="68"||ubicacion=="69"||ubicacion=="70"||ubicacion=="71"||ubicacion=="75"||ubicacion=="999992"||ubicacion=="999993"||ubicacion=="999999") {
                    contable_id=1;
                    contable_desc="Producción";
                    }else if (ubicacion=="0335029"||ubicacion=="1001") {
                    contable_id=2;
                    contable_desc="Comercialización";
                    }else if (ubicacion=="000558"||ubicacion=="000553"||ubicacion=="000555"||ubicacion=="001016"||ubicacion=="010101999"||ubicacion=="0335030"||ubicacion=="0335031"||ubicacion=="033503188"||ubicacion=="033503199"||ubicacion=="033503200"||ubicacion=="033503201"||ubicacion=="095447887"||ubicacion=="100111928992"||ubicacion=="1002"||ubicacion=="1003"||ubicacion=="1004"||ubicacion=="1005"||ubicacion=="1006"||ubicacion=="1007"||ubicacion=="1008"||ubicacion=="1009"||ubicacion=="1010"||ubicacion=="1011"||ubicacion=="1012"||ubicacion=="1014"||ubicacion=="67"||ubicacion=="70"||ubicacion=="71"||ubicacion=="75"||ubicacion=="100112"||ubicacion=="000559"||ubicacion=="000544"||ubicacion=="000554"||ubicacion=="000564"||ubicacion=="100111"||ubicacion=="100111928992") {
                    contable_id=4;
                    contable_desc="Administración";
                    }else{
                    contable_id=3;
                    contable_desc="Operación";
                    }


                    var adjuntos = 0;
                    if (item.archivosAdjuntos!=undefined) {
                        adjuntos = item.archivosAdjuntos.length;
                    }
                    
              
                                        
                    

                    vue.orientaciones.push({
                        dni: item.legajo, // Asumiendo que 'legajo' puede funcionar como DNI
                        nombre:empleado[item.dni].leg_apellido+" "+empleado[item.dni].leg_nombre,
                        fireId: fireId, // No está claro qué es 'fireId', podría necesitar ser generado o consultado
                        fechaInicioLicencia:item.fechaInicioLicencia,
                        vigencia:item.fechaFinLicencia,
                        dias:dias,
                        hoy:hoy,
                        legajo: empleado[item.dni].leg_numero,
                        categoria: empleado[item.dni].cat_descrip,
                        fecha: item.fecha, // Asumiendo que esta es la fecha relevante
                        hora: item.hora, // No se proporciona información sobre la hora en los datos originales
                        id_novedad: null, // No se proporciona un equivalente directo en los datos originales
                        novedad: item.tipoAccidente, // Suponiendo que 'Tipo licencia' puede representar 'novedad'
                        diagnostico: item.diagnostico, // Usando 'Diagnostico' como observación
                         detalle: item.detalle, // Usando 'Diagnostico' como observación
                        resolucion: null, // No hay un campo equivalente en los datos originales
                        status: item.status,
                        responsable: responsable, // Asumiendo que hay un campo 'Responsable' correspondiente
                        distribucion:item.distribucion,
                        distribucionName:item.distribucionName,
                        sector:item.sector,
                        zonal:item.zonal,
                       
                        archivos:adjuntos,
                        plan:item.plan,
                        tipo:item.novedad
                    });

            })
        })
        .then(()=>{
            this.load = false;
            this.$nextTick(()=>{
              this.refrescarTabla(true);
            });
        })
        .catch(e => {
            console.log(e);
        });
    },
   
    nuevoTicket(){
        this.modalNuevoTicket.open = true;
    }, 
    adjuntos(id){

        const vm = this;
        $("#archivos").slideDown()

        $("#adj").html("");

        $("#adj").html("ARCHIVOS CARGADOS<hr>");

    var url = "https://aplicativos.firebaseio.com/asignaciontemporal/"+id+".json";

    $.get(url, function(data) {
        
        if (data.archivosAdjuntos!=undefined) {
            for (var i = 0; i < data.archivosAdjuntos.length; i++) {
                
                 $("#adj").append("<br><a target='_blank' href='"+data.archivosAdjuntos[i]+"'>Abrir archivo "+(i+1)+"</a><br>");
            }
        }




       $("#adj").append("<br><br>AGREGAR NUEVO ARCHIVO<hr>");

       $("#adj").append("<center><input type='file' class='form-control' multiple style='display: block;' id='nuevoarchivo'></center><span class='text-danger'>· Adjunta al menos un archivo</span><br>");

        $("#adj").append("<br><br><center><button id='guardararchivo'>GUARDAR NUEVO ARCHIVO</button></center>");



             $('#guardararchivo').click(function() {


                    $("#adj").append("<br><br><center><b>CARGANDO ARCHIVOS! ... ESPERE UNOS SEGUNDOS</b></center>");
                    //$("#adj").html("CARGANDO ARCHIVO ...");

                    var files = $('#nuevoarchivo')[0].files;
                    if (files.length === 0) {
                        alert("Por favor, selecciona al menos un archivo.");
                        return;
                    }

                    var uploadPromises = [];
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        var storageRef = firebase.storage().ref('asignaciones/' + file.name);
                        var uploadTask = storageRef.put(file);
                        uploadPromises.push(uploadTask.then((snapshot) => snapshot.ref.getDownloadURL()));
                    }

                    Promise.all(uploadPromises).then(newUrls => {
                        var ticketRef = firebase.database().ref('/asignaciontemporal/'+id); // Asegúrate de cambiar esto por la ruta correcta

                        ticketRef.once('value').then(snapshot => {
                            var currentData = snapshot.val();
                            var currentFiles = currentData.archivosAdjuntos || [];
                            var updatedFiles = currentFiles.concat(newUrls);

                            // Actualiza el nodo en Firebase con el nuevo array de archivos adjuntos
                            ticketRef.update({
                                archivosAdjuntos: updatedFiles
                            }).then(() => {
                                console.log("Archivos guardados con éxito.");

                                $("#archivos").slideUp()

                                var cant = parseInt($("#adj"+id).text().replace("+",""))+1;

                                $("#adj"+id).html("+"+cant)



                                

                                    


                            }).catch(error => {
                                console.error("Error al guardar los archivos: ", error);
                                console.log("Error al guardar los archivos.");
                            });
                        });
                    }).catch(error => {
                        console.error("Error al subir los archivos: ", error);
                        alert("Error al subir los archivos.");
                    });
                });


      
    }).fail(function() {
        console.error("Error al cargar los datos.");
        $('#datos').html('<p>Error al cargar los datos.</p>');
    });



        $("#cerrar").click(function(){
            $("#archivos").slideUp()



        })

    },
    obtenerFechaActual(){
        return new Date().toISOString().slice(0, 10);
    },
    async  obtenerPersonal(desde,hasta){
      
     const axiosrequest1 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
     const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');
     const axiosrequest3 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
     const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');
     var operaciones_x = [];
       await axios.all([axiosrequest1, axiosrequest2,axiosrequest3,axiosrequest4]).then(
                axios.spread((personal,personal_cat,operaciones,sectores) => {

                      var personal_mezcla = Object.assign(personal.data, personal_cat.data)

                      var operaciones_val = operaciones.data;

                      $.each(operaciones_val,function(key,val){
                       
                       if (val.estado=="ACTIVA") {
                        operaciones_x.push(val.name)
                        }
                       //$("#operacion_id").append("<option>"+val.name+"</option>")
                      })

                      $.each(sectores.data,function(key,val){
                        operaciones_x.push(val.operacion)
                       //$("#operacion_id").append("<option>"+val.name+"</option>")
                      })

                      for (var i = 0; i < operaciones_x.length; i++) {
                          
                        $("#operacion_id").append("<option val='"+operaciones_x[i]+"'>"+operaciones_x[i]+"</option>")
                      }

                     this.personal = personal_mezcla; 
            

             })).then(() => {
                this.obtenerOrientaciones();
                $("#operacion_id").select2();
            })
            .catch((e)=>{
                console.log(e);
            })


        },

    
    obtenerEmpleadoConDni(dni){
        return this.personal[dni];
    },
    
    cambiarStatus(event, caso){
        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
            status: event.target.value,
        })
        .then(()=>{
            caso.status = event.target.value;
        })
        .catch(e => {
            console.log(e);
        });


    },

    editar(event, caso){
        
        var vm = this;

        if (event.target.value=="plan") {
                $("#textoPlan").val("")
                $("#plan").slideDown()

                    $('#guardarPlan').click(function() {


                        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
                            plan: $("#textoPlan").val(),
                        })
                        .then(()=>{
                            caso.plan = $("#textoPlan").val();
                            $("#plan").slideUp()

                            vm.obtenerOrientaciones()
                        })
                        .catch(e => {
                            console.log(e);
                        });

                })


                $("#cerrarPlan").click(function(){
                    $("#plan").slideUp()
                    $("#textoPlan").val("")

                })

            }




            if (event.target.value=="vigencia") {
                $("#textoVigencia").val("")
                $("#vigencia").slideDown()
                console.log(caso)
                $("#fechaactual").html(caso.vigencia);
                    $('#guardarVigencia').click(function() {


                        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
                            fechaFinLicencia: $("#textoVigencia").val(),
                        })
                        .then(()=>{

                    const dias = (new Date($("#textoVigencia").val()) - new Date(caso.fechaInicioLicencia)) / (1000 * 60 * 60 * 24);
                    

                    const fechaInicio = new Date($("#textoVigencia").val());
                    fechaInicio.setHours(0, 0, 0, 0); // Establece la hora, minutos, segundos y milisegundos a cero
                    const fechaFinal = new Date();
                    fechaFinal.setHours(0, 0, 0, 0); // Igualmente para la fecha actual
                    const hoy = (fechaInicio-fechaFinal) / (1000 * 60 * 60 * 24);



                            caso.hoy = hoy;
                            caso.dias = dias;
                            caso.vigencia = $("#textoVigencia").val();
                            $("#vigencia").slideUp()
                            vm.obtenerOrientaciones()
                        })
                        .catch(e => {
                            console.log(e);
                        });

                })


                $("#cerrarVigencia").click(function(){
                    $("#vigencia").slideUp()
                    $("#textoVigencia").val("")

                })

            }





 if (event.target.value=="inicio") {
                $("#textoInicio").val("")
                $("#inicio").slideDown()
                console.log(caso)
                $("#fechaactualInicio").html(caso.fechaInicioLicencia);
                    $('#guardarInicio').click(function() {


                        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
                            fechaInicioLicencia: $("#textoInicio").val(),
                        })
                        .then(()=>{

                    const dias = (new Date($("#textoInicio").val()) - new Date(caso.fechaInicioLicencia)) / (1000 * 60 * 60 * 24);
                    

                    const fechaInicio = new Date($("#textoInicio").val());
                    fechaInicio.setHours(0, 0, 0, 0); // Establece la hora, minutos, segundos y milisegundos a cero
                    const fechaFinal = new Date();
                    fechaFinal.setHours(0, 0, 0, 0); // Igualmente para la fecha actual
                    const hoy = (fechaInicio-fechaFinal) / (1000 * 60 * 60 * 24);



                            caso.hoy = hoy;
                            caso.dias = dias;
                            caso.vigencia = $("#textoInicio").val();
                            $("#inicio").slideUp()
                            vm.obtenerOrientaciones()
                        })
                        .catch(e => {
                            console.log(e);
                        });

                })


                $("#cerrarInicio").click(function(){
                    $("#inicio").slideUp()
                    $("#textoInicio").val("")

                })

            }



 if (event.target.value=="operacion_m") {
                $("#operacion_id").val("")
                $("#operacion_m").slideDown()
                console.log(caso)
                $("#operacion_actual").html(caso.distribucionName);
                    $('#guardarOperacion').click(function() {

                        console.log($("#operacion_id").val())

                        
                        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
                            distribucionName: $("#operacion_id").val(),
                        })
                        .then(()=>{

                    
                            caso.distribucionName = $("#operacion_id").val();
                            $("#operacion_m").slideUp()
                            vm.obtenerOrientaciones()

                        })
                        .catch(e => {
                            console.log(e);
                        });

                })


                $("#cerrarOperaciones").click(function(){
                    $("#operacion_m").slideUp()
                    $("#operacion_id").val("")

                })

            }



$('#accion').val('0').trigger('change');



    },


     cambiarResponsable(event, caso){
        firebase.database().ref('asignaciontemporal/' + caso.fireId).update({
            responsable: event.target.value,
        })
        .then(()=>{
            caso.responsable = event.target.value;
            console.log("RESPONSABLE")
        })
        .catch(e => {
            console.log(e);
        });


    },


    
    refrescarTabla(limpiar){
        if (limpiar==true) {
            $('#orientaciones').dataTable().fnDestroy();
        }
        this.$nextTick(()=>{
           
    var table = $('#orientaciones').DataTable({
        columnDefs: [{ width: "100px", targets: 6 }],
        language: {
            // tu configuración de idioma aquí
        },
        scrollX: false,
        search: { regex: true },
        dom: '<"top"Bf<"clear">>rt<"bottom"lip><"clear">', // Modifica aquí para colocar elementos en el top junto a los botones
        buttons: [
            'copy', 'excel', 'csv', 'pdf',
            {
                text: 'Novedades',
                action: function (e, dt, node, config) {
                    $('#filtroNovedades').toggle();
                }
            },
            {
                text: 'Tipo',
                action: function (e, dt, node, config) {
                    $('#filtroTipo').toggle();
                }
            },
            {
                text: 'Responsable',
                action: function (e, dt, node, config) {
                    $('#filtroResponsable').toggle();
                }
            },
            {
                text: 'Status',
                action: function (e, dt, node, config) {
                    $('#filtroStatus').toggle();
                }
            }
        ],
        initComplete: function () {
            var table = this.api();
            
            table.column(0).search('ABIERTO', true, false).draw();
            

            // Crear y colocar select para Novedades
            var selectNovedades = $('<select id="filtroNovedades" class="select2" style="display:none;"><option value="">TODOS</option></select><br>')
                .appendTo('.dt-buttons')
                .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    table.column(4).search(val ? '^' + val + '$' : '', true, false).draw();
                });
            ["ART","ENFERMEDAD", "RESERVA DE PUESTO", "LICENCIA SIN SUELDO", "MATERNIDAD", "EXEDENCIA"].forEach(function(value) {
                selectNovedades.append('<option value="' + value + '">' + value + '</option>');
            });

            // Crear y colocar select para Tipo
            var selectTipo = $('<select id="filtroTipo" class="select2" style="display:none;"><option value="">TODOS</option></select><br>')
                .appendTo('.dt-buttons')
                .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    table.column(14).search(val ? '^' + val + '$' : '', true, false).draw();
                });
            ["ACCIDENTE DE TRABAJO", "IN-ITINERE", "REAGRAVACION REINGRESO","ENFERMEDAD PROFESIONAL"].forEach(function(value) {
                selectTipo.append('<option value="' + value + '">' + value + '</option>');
            });

            // Crear y colocar select para Responsable
            var selectResponsable = $('<select id="filtroResponsable" class="select2" style="display:none;"><option value="">TODOS</option></select><br>')
                .appendTo('.dt-buttons')
                .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    table.column(16).search(val ? '^' + val + '$' : '', true, false).draw();
                });
            ["GONZALO", "MARIANO", "EDGARDO", "SOL", "LEGALES"].forEach(function(value) {
                selectResponsable.append('<option value="' + value + '">' + value + '</option>');
            });


             // Crear y colocar select para Responsable
            var selectResponsable = $('<select id="filtroStatus" class="select2" style="display:none;"><option value="">SELECCIONA UN ESTADO</option><option value="">TODOS</option></select><br>')
                .appendTo('.dt-buttons')
                .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                    table.column(0).search(val ? '^' + val + '$' : '', true, false).draw();
                });
            ["ABIERTO", "CERRADO"].forEach(function(value) {
                selectResponsable.append('<option value="' + value + '">' + value + '</option>');
            });
        }
    });

   

        })
    },
    refrescarOrientaciones(seReasigno){
        this.modalNuevoTicket.open = false;
        this.modalDetalle.open = false;
        this.obtenerOrientaciones();
    },
    abrirModalDetalle(caso){
        this.modalDetalle.open = true;
        this.modalDetalle.caso = caso;
    },
    
  },
  async mounted() {
    //   this.orientacionesConPersonal();
      this.obtenerPersonal();



  }
}
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select {
    font-size: 0.7rem;
    padding: .2rem 1.2rem .2rem .2rem;
}

.resolucionSelect {
    min-width: max-content;
}

.badge {
    margin-bottom: 0;
    font-size: 0.6rem;
    font-weight: bold;
}

.table .custom {
    word-wrap: break-word; /* Asegura que las palabras largas se rompan y pasen a la siguiente línea */
    white-space: normal; /* Permite que el texto ocupe más de una línea */
}


#archivos{
    width: 50%;
    height: 80%;
    background-color: #f1f1f1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    border:2px solid #bdbdbd;
    top: 60px;
    display: none;


}


#plan{
    width: 50%;
    height: 80%;
    background-color: #f1f1f1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    border:2px solid #bdbdbd;
    top: 60px;
    display: none;


}


#vigencia{
    width: 50%;
    height: 80%;
    background-color: #f1f1f1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    border:2px solid #bdbdbd;
    top: 60px;
    display: none;


}


#inicio{
    width: 50%;
    height: 80%;
    background-color: #f1f1f1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    border:2px solid #bdbdbd;
    top: 60px;
    display: none;


}

#operacion_m{
    width: 50%;
    height: 80%;
    background-color: #f1f1f1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0;
    right:0;
    border:2px solid #bdbdbd;
    top: 60px;
    display: none;


}

#adj{
    padding: 20px;
}



.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}



.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: 500px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 14px !important;
}

</style>