<template>
    <div v-if="!trigger.list && !data">
        <TablePlaceholder/>
    </div>
    <div v-else>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h4 class="table-title" v-if="title!=undefined">
                {{title}}
                <div class="badge badge-primary" v-if="titleBadge!=undefined">
                    {{titleBadge}}
                </div>
            </h4>
            <button class="btn btn-light" v-if="closeAction" @click="$emit('close')">
                Cerrar
            </button>
        </div>
        <div class="table-responsive">
            <table :id="tableId" class="table">
                <thead>
                    <tr>
                        <th v-for="(column,c) in columns" :key="c">{{column.label}}</th>
                        <th v-if="actions!=undefined && actions!=null && actions.length"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, i) in data" :key="i" >
                        <td v-for="(column,c) in columns" :key="c">
                            <template v-if="column.type == undefined || column.type == 'text'">{{handleFormat(column.format,d[column.trackBy])}}</template>
                            <div 
                                v-else-if="column.type == 'badge'"
                                class="badge"
                                :class="getColorIfConditionalBadge(column,d[column.trackBy],d)"
                            >{{(d[column.trackBy]!=undefined) ? handleFormat(column.format,d[column.trackBy],d,column.percentageTotal) : 'Sin asignar'}}</div>
                            
                            <div 
                                v-else-if="column.type == 'actionBadge'"
                                @click.prevent="$emit(column.actionBadgeEmitter,{data:d,id:column.trackBy})"
                                class="badge pointer"
                                :class="getColorIfConditionalBadge(column,d[column.trackBy],d)"
                            >{{(d[column.trackBy]!=undefined) ? handleFormat(column.format,d[column.trackBy],d,column.percentageTotal) : 'Sin asignar'}}</div>

                            <div v-else-if="column.type == 'length'" class="badge badge-light">{{Object.keys(d[column.trackBy]).length}}</div>

                            <div v-else-if="column.type == 'function'" class="badge badge-light">{{column.formatter(d)}}</div>

                            <div v-else-if="column.type == 'dot'" class="dot" :class="'bg-'+d[column.trackBy]"></div>
                        </td>
                        <td v-if="actions">
                            <button v-for="(action,a) in actions" :key="a" class="btn" :class="'btn-'+action.color" @click="$emit(action.emitter,d)">
                                {{action.label}}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { capitalize,dateFormat } from "@/utils";
import TablePlaceholder from './TablePlaceholder.vue'
// import $ from 'jquery'

export default {
    name:'List',
    components:{TablePlaceholder},
    props: {
        data:Array,
        columns:Array, // format(c)=> label: title of col, trackBy: name of data prop, type: badge,date
        title:String,
        titleBadge:String,
        actions:Array,
        closeAction:Boolean,
        pageLength:Number
    },
    data() {
        return {
            tableId:this.getRandomId('dt'),
            trigger:{list:false},
        }
    },
    methods:{
        getRandomId(t){
            return t+(Math.random()).toString(16).substring(2);
        },
        handleFormat(format,data,row,other_prop){
            if (format==undefined) return data;
            
            if(format=='capitalize') return capitalize(data);
            if(format=='date') return dateFormat(data);

            if(format == 'percentage') {
                if((isNaN(data/row[other_prop])*100)) {
                    return '- %';
                } else {
                    return ((data/row[other_prop])*100).toFixed(2) + ' %';
                }
            }

            return data;
        },
        getColorIfConditionalBadge(column,data,row){
            if(column.conditionColors == undefined) return 'badge-light';

            let d = data;
            // console.log('d antes',d);
            if(column.format == 'percentage') {
                d = (data/row[column.percentageTotal])*100;
            }
            // console.log('d despues',d);

            if(d == undefined) return 'badge-light';

            let result = 'badge-light';
            // console.log('se pone badge con ', d);
            Object.keys(column.conditionColors).some((c)=>{
                let amount = column.conditionColors[c].amount;
                if(column.conditionColors[c].condition == 'equal'){
                    // console.log(d, '==', amount);
                    if(d == amount) {
                        result = `badge-${column.conditionColors[c].color}`;
                        return true; // Breaks the some loop
                    }
                }

                if(column.conditionColors[c].condition == 'less'){
                    if(d < amount) {
                        result = `badge-${column.conditionColors[c].color}`;
                        return true; // Breaks the some loop
                    }
                }

                if(column.conditionColors[c].condition == 'greather'){
                    if(d > amount) {
                        result = `badge-${column.conditionColors[c].color}`;
                        return true; // Breaks the some loop
                    }
                }
            });

            return result;
        }
    },
    mounted(){
        $('#'+this.tableId).dataTable().fnDestroy();

        let config = tableConfig;
        if(this.pageLength!=undefined) config["pageLength"] = parseInt(this.pageLength)
        // config.buttons = [];

        this.$nextTick(()=>{
            $('#'+this.tableId).DataTable(config);
            this.trigger.list = true;
            this.$forceUpdate();
        })
    }
}



var tableConfig = {
    "language": { 
        "decimal": ",", 
        "thousands": ".", 
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros", 
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "", 
        "infoFiltered": "(filtrado de un total de _MAX_ registros)", 
        "loadingRecords": "Cargando...", 
        "lengthMenu": "Mostrar _MENU_ registros", 
        "paginate": { 
            "first": "Primero", 
            "last": "Último", 
            "next": "Siguiente", 
            "previous": "Anterior" 
        },
        "processing": "Procesando...", 
        "search": "Buscar:", 
        "searchPlaceholder": "Término de búsqueda", 
        "zeroRecords": "No se encontraron resultados", 
        "emptyTable": "Ningún dato disponible en esta tabla", 
        "aria": { 
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente", 
            "sortDescending": ": Activar para ordenar la columna de manera descendente" 
        }, 
    }, 
    "scrollX": false, 
    "search": {regex:true}, 
    dom: 'Bfrtip', 
    responsive: true, 
    nowrap: false, 
}

</script>

<style>
.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.pointer {
    cursor:pointer
}
</style>
