<template>
    <div class="page-content">

<!-- TEMPLATE RECIBO CATERING  style="position:absolute;top:-200%;"-->


    <div id="catering_template" >
        <div style="width:960px;height: 80px;border: 1px solid grey;border-radius:10px;">
            <div style="width:960px;height: 40px;">
                <div style="width:300px;height: 30px;float:left;padding-top:10px;padding-left: 10px;">
                   <b id="compania"></b>
                </div>
                <div style="width:250px;height: 46px;float:right;border: 1px solid grey;border-radius:10px;text-align: center;font-size:13px;padding-top:5px;">
                    CUIT<br>
                   <span id="comp_cuit"></span>
                </div>
            </div>
        
            <div style="width:960px;height: 40px;">
                <div style="width:330px;height: 30px;float:left;padding-top:10px;padding-left: 10px;">
                    Gregorio de Laferrere 5835
                </div>
                <div style="width:330px;height: 30px;float:left;padding-top:10px;padding-left: 10px;">
                    Capital Federal - Capital Federal
                </div>
                <div style="width:250px;height: 30px;float:right;padding-top:10px;padding-left: 10px;text-align: center;">
                    <b>RECIBO N&deg; 1</b>
                </div>
            </div>
        </div>
        


        <div style="width:960px;height: 150px;border: 1px solid grey;border-radius:10px;font-size: 12px;    margin-top: 5px;">
            <div style="width:960px;height: 50px;">
                <div style="float:left; width: 510px;height: 50px;">
                    <div style="text-align: center;width: 510px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px; ">APELLIDO Y NOMBRE</div>
                    <div style="width: 500px;padding-left: 10px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_nombre"></div>
                </div>
        
                <div style="float:left; width: 150px;text-align: center;height: 50px;">
                    <div style="width: 150px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;">FECHA DE INGRESO</div>
                    <div style="width: 150px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_ingreso"></div>
                </div>
        
                <div style="float:left; width: 150px;text-align: center;height: 50px;">
                    <div style="width: 150px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;">CUIL</div>
                    <div style="width: 150px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_cuil"></div>
                </div>
        
                <div style="float:left; width: 150px;text-align: center;height: 50px;">
                    <div style="width: 150px;border-bottom:1px solid grey; height: 17px;padding-top:2px;">LEGAJO</div>
                    <div class="legajos" style="width: 150px;border-bottom:1px solid grey; height: 23px;padding-top:7px;" id="recibo_legajo"></div>
                </div>
            </div>
        
            <div style="width:960px;height: 50px;">
                <div style="float:left; width: 200px;height: 50px;">
                    <div style="text-align: center;width: 200px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px; ">PERIODO DE PAGO</div>
                    <div style="width: 190px;padding-left: 10px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_periodo"></div>
                </div>
        
                <div style="float:left; width: 280px;height: 50px;">
                    <div style="width: 280px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;text-align: center;">CATEGORIA</div>
                    <div style="width: 270px;padding-left: 10px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_categoria"></div>
                </div>
        
                <div style="float:left; width: 280px;height: 50px;">
                    <div style="width: 280px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;text-align: center;">CALIF PROFESIONAL</div>
                    <div style="width: 270px;padding-left: 10px;border-bottom:1px solid grey; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_calificacion"></div>
                </div>
        
                <div style="float:left; width: 200px;height: 50px;">
                    <div style="width: 200px;border-bottom:1px solid grey; height: 17px;padding-top:2px;text-align: center;">SECCION</div>
                    <div style="width: 190px;padding-left: 10px;border-bottom:1px solid grey; height: 23px;padding-top:7px;" id="recibo_seccion"></div>
                </div>
            </div>
        
        
            <div style="width:960px;height: 50px;">
                <div style="float:left; width: 480px;height: 50px;">
                    <div style="text-align: center;width: 480px;border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px; ">MODALIDAD DE CONTRATO</div>
                    <div style="width: 470px;padding-left: 10px; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_modalidad"></div>
                </div>
        
                <div style="float:left; width: 280px;height: 50px;">
                    <div style="width: 280px; border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;text-align: center;">ANTIGUEDAD</div>
                    <div style="width: 270px;padding-left: 10px; border-right:1px solid grey;height: 23px;padding-top:7px;" id="recibo_antiguedad"></div>
                </div>
        
                <div style="float:left; width: 200px;height: 50px;">
                    <div style="width: 200px; border-bottom:1px solid grey; border-right:1px solid grey;height: 17px;padding-top:2px;text-align: center;">REM. ASIGNADA</div>
                    <div style="width: 190px;padding-left: 10px; height: 23px;padding-top:7px;" id="recibo_remuneracion"></div>
                </div>
        
            </div>
        
        </div>
        
        <div style="width:960px;height: auto;border: 1px solid grey;border-radius:10px;margin-top:5px;overflow: hidden">
            <div style="width:960px;height: 33px; font-size:10px; ">
                <div style="width:400px;height: 33px;float:left;padding-top: 8px; text-align: center;border-right: 1px solid grey">CONCEPTO</div>
                <div style="width:100px;height: 33px;float:left;padding-top: 8px; text-align: center;border-right: 1px solid grey">UNIDADES</div>
                <div style="width:180px;height: 33px;float:left;padding-top: 5px; text-align: center;font-size:9px;border-right: 1px solid grey;background-color: #cecece; ">REMUNERACIONES<br>SUJETAS A RETENCION</div>
                <div style="width:100px;height: 33px;float:left;padding-top: 5px; text-align: center;font-size:9px;border-right: 1px solid grey;background-color: #cecece;">REMUNERACIONES<br>EXENTAS</div>
                <div style="width:176px;height: 33px;float:left;padding-top: 8px; text-align: center;background-color: #cecece;">DESCUENTOS</div>
            </div>
        
             
            <div id="conceptos">

                
                
            </div>
                      
        
            <div style="width:960px;height: 30px; font-size:10px; border-top: 1px solid grey">
                <div style="width:401px;height: 22px;float:left;"></div>
                <div style="width:100px;height: 30px;float:left;border-right: 1px solid grey;"></div>
                <div style="width:180px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;border-right: 1px solid grey;background-color: #cecece; border-bottom: 1px solid grey;" id="total_a"></div>
                <div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;border-right: 1px solid grey;background-color: #cecece; border-bottom: 1px solid grey;" id="total_b"></div>
                <div style="width:176px;height: 22px;float:left;padding-top: 8px; text-align: center;background-color: #cecece;border-bottom: 1px solid grey;font-size:11px;" id="total_c"></div>
            </div>
        
            <div style="width:960px;height: 30px; font-size:10px; ">
                <div style="width:403px;height: 22px;float:left;"></div>
                <div style="width:100px;height: 30px;float:left;"></div>
                <div style="width:180px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:9px; "></div>
                <div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:9px;border-right: 1px solid grey; ">TOTAL NETO</div>
                <div style="width:176px;height: 22px;float:left;padding-top: 8px; text-align: center;background-color: #cecece;border-bottom: 1px solid grey;font-size:11px;" id="total_neto"></div>
            </div>
        
            <div style="width:960px;height: 30px; font-size:11px; margin-top: 10px;">
                <div style="width:300px;height: 22px;float:left;"></div>
                <div style="width:650px;height: 22px;float:right;background-color: #cecece;padding-left: 10px;          padding-top: 8px;">
                    <b>SON PESOS: <span style="text-transform: capitalize;" id="numeroletra"></span>
                    </b>
                </div>            
            </div>
        
             <div style="width:960px;height: 30px; font-size:12px; margin-top: 10px;">
                <div style="width:470px;height: 22px;float:left;padding-left: 10px; padding-top: 8px;">
                    LUGAR Y FECHA DE PAGO: <span id="lugar_y_fecha"></span> 
                </div>
                <div style="width:480px;height: 22px;float:right; padding-top: 8px;">
                    OBRA SOCIAL: <span id="obra_social"></span>                </div>            
            </div>
        
                                <div style="width:960px;height: 30px; font-size:12px;text-align: center;margin-top:10px; ">Los haberes se depositarán en la cuenta Nro. <span id="cuenta_nro"></span> del <span id="banco_nombre"></span></div>
                        
        
            <div style="width:960px;height: auto;margin-top:5px;line-height: 18px;">
                <div style="width: 280px; float:left;padding-left: 10px; font-size: 11px;border-top: 1px solid grey;padding-top: 15px;padding-bottom: 15px;border-right: 1px solid grey;">
                    <div>MES: <span id="recibo_mes"></span> </div>
                    <div>BANCO: <span id="recibo_banco"></span></div>
                    <div>FECHA DEPOSITO: <span id="recibo_fecha_deposito"></span></div>
                    <div style="font-size:9px;">ART. 12 LEY 17250</div>
                </div>
                <div style="width: 600px; float:right;font-size:11px;">
                    <div style="width:600px;height: auto;float:left;    margin-top: 25px;">
                        RECIBI EL IMPORTE NETO DE ESTA LIQUIDACION EN
                        PAGO DE MI REMUNERACION CORRESPONDIENTE <br>
                        AL PERIODO INDICADO Y DUPLICADO DE LA MISMA
                        CONFORME A LA LEY VIGETE.
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- CIERRA TEMPLATE CATERING -->


                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description" style="color: #495057;">
                           {{descripcion}}
                            </p>

                        </div>  
                      </div>
                    </div>
                  </div>    

                   <div class="row" id="iniciado">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card" style="background-color: #6d7c9e8a;">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i>STATUS DE GENERACIÓN DE RECIBOS | NO CIERRE ESTA PAGINA Y ESPERE A QUE TODOS LOS RECIBOS SEAN GENERADOS</h6>
                          <p class="card-description" style="color: #ffffff;">
                           TOTAL ENCONTRADOS: <span class="encontrados">0</span><br>
                           GENERADOS: <span id="generados">0</span> DE <span class="encontrados">0</span> <br>
                           TIEMPO ESTIMADO: <span id="hms">00:00:00</span> 
                            </p>

                        </div>  
                      </div>
                    </div>
                  </div>    


                  <div class="row" id="finalizado">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card" style="background-color: #6d9e978a;">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i>TODOS LOS RECIBOS FUERON GENERADOS CORRECTAMENTE</h6>
                          <p class="card-description" style="color: #ffffff;">
                                YA TIENES LOS PDF LISTOS PARA SER FIRMADOS DIGITALMENTE EN EL DIRECTORIO CORRESPONDIENTE AL NUMERO DE LIQUIDACIÓN
                            </p>

                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">

                            <div class="row mb-3">
                                
                                <div class="col">
                                    <div class="form-check form-check-inline form-control mb-2 mr-sm-2" style="padding: 15px;">
                                        <input type="radio" class="form-check-input" name="radioInline" id="america" style="padding: 15px;">
                                            <label class="form-check-label" for="radioInline1">
                                                <h6 style="padding-top: 6px;">Food Service America S.A</h6>
                                            </label>
                                        </div> 
                                </div>

                                   <div class="col">
                                    <div class="form-check form-check-inline form-control mb-2 mr-sm-2" style="padding: 15px;">
                                        <input type="radio" class="form-check-input" name="radioInline" id="catering" style="padding: 15px;">
                                            <label class="form-check-label" for="radioInline1">
                                                <h6 style="padding-top: 6px;">Food Service Catering S.A</h6>
                                            </label>
                                        </div> 
                                </div>

                               
                               
                                
                            </div>



                         <div class="row mb-3">
                                
                                <div class="col">
                                    <label class="mr-sm-2">Numero de liquidación</label>
                                    <input type="text" class="form-control mb-2 mr-sm-2" id='liquidacion_b' placeholder="Ej: 343"/>
                                </div>

                                <div class="col">
                                    <label class="mr-sm-2">Numero de legajo</label>
                                    <input type="text" class="form-control mb-2 mr-sm-2" id='legajo' placeholder="0 Genera todos los recibos"/>
                                </div>
                               
                               
                                
                            </div>


                             <div class="row mb-3">
                                
                                <div class="col">
                                    <label class="mr-sm-2">Mes Periodo de pago</label>
                                     <select name="mes_periodo_pago" class="form-control mb-2 mr-sm-2" id="mes_periodo_pago">
                                        <option value="1">Enero</option>
                                        <option value="2">Febrero</option>
                                        <option value="3">Marzo</option>
                                        <option value="4">Abril</option>
                                        <option value="5">Mayo</option>
                                        <option value="6">Junio</option>
                                        <option value="7">Julio</option>
                                        <option value="8">Agosto</option>
                                        <option value="9">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                        <option value="13">S.A.C. 1 SEMESTRE</option>
                                        <option value="14">S.A.C. 2 SEMESTRE</option>
                                    </select>
                                </div>

                                <div class="col">
                                    <label class="mr-sm-2">Año Periodo de pago</label>
                                    <select name="anio_periodo_pago" class="form-control mb-2 mr-sm-2"  id="anio_periodo_pago">
                                        <option value="2010">2010</option>
                                        <option value="2011">2011</option>
                                        <option value="2012">2012</option>
                                        <option value="2013">2013</option>
                                        <option value="2014">2014</option>
                                        <option value="2015">2015</option>
                                        <option value="2016">2016</option>
                                        <option value="2017">2017</option>
                                        <option value="2018">2018</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                    </select>
                                </div>

                            </div>


                             <div class="row mb-3">
                                 <div class="col">
                                     <div class="alert alert-danger"  role="alert" id="alerta">
                                  <h6>CARGAS SOCIALES</h6>
                                </div>
                                    
                                 </div>
                             </div>

                                 <div class="row mb-3">


                                
                                        <div class="col">
                                            <label class="mr-sm-2">Lugar y Fecha de pago</label>
                                            <input type="text" class="form-control mb-2 mr-sm-2"  name="fecha_pago_a" id="fecha_pago" placeholder="Lugar/Fecha de Pago" value="">
                                        </div>

                                        <div class="col">
                                            <label class="mr-sm-2">Mes</label>
                                            <select name=mes id="mes">
                                                <option value="1">Enero</option>
                                                <option value="2">Febrero</option>
                                                <option value="3">Marzo</option>
                                                <option value="4">Abril</option>
                                                <option value="5">Mayo</option>
                                                <option value="6">Junio</option>
                                                <option value="7">Julio</option>
                                                <option value="8">Agosto</option>
                                                <option value="9">Septiembre</option>
                                                <option value="10">Octubre</option>
                                                <option value="11">Noviembre</option>
                                                <option value="12">Diciembre</option>
                                                <option value="13">S.A.C. 1 SEMESTRE</option>
                                                <option value="14">S.A.C. 2 SEMESTRE</option>
                                            </select>
                                        </div>
                               
                               
                                
                                
                               
                               
                                
                            </div>

                             <div class="row mb-3">
                                
                                <div class="col">
                                    <label class="mr-sm-2">Banco</label>
                                    <input type="text" class="form-control mb-2 mr-sm-2" id='banco' placeholder="Ej. BBVA"/>
                                </div>

                                <div class="col">
                                    <label class="mr-sm-2">Fecha del Deposito</label>
                                    <input type="text" class="form-control mb-2 mr-sm-2"  name="fecha_pago_b" id="fecha_deposito" placeholder="Fecha del deposito" value="">
                                </div>
                               
                               
                                
                            </div>

                            <div class="row mb-3">

                                <div class="col">
                                    <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                                    <button  class="btn btn-md btn-success w-100" @click="generar()">GENERAR RECIBOS</button>
                                </div>

                            </div>

                          <div class="table-responsive">

                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  
                                  <th>Legajo</th>
                                  <th>Nombre y Apellido</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="personal in personal" v-bind:key="personal">
                                  
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.nombre}}</td>
                                  
                                   
                                </tr>
                              </tbody>
                            </table>
                          


                            
                          </div>
<!--  style="position:absolute;top:1200%;" -->
 <div id="pre_html" ></div>
 <div id="previewImage"></div>


                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

import html2pdf from 'html2pdf.js';


import firebase from "firebase/app";
import "firebase/database";



//$("title").html("Liquidacion");
export default {
    name: 'Liquidacion',
    components: {ListaCarga, ModalAdministrar, ModalLegales},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Recibos',
            descripcion: 'En este modulo usted podra generar los recibos de sueldo y dejarlos a disponibilidad del empleado',
            triggers: {load:true},
            personal: [],
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion){
            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async generar(){

                var empresa = 0;
                if( $('#america').is(':checked') ){
                    empresa = "AME";
                    $("#compania").html('Food Service America SA');
                    $("#comp_cuit").html('30-71656228-6');
                }

                if( $('#catering').is(':checked') ){
                     empresa = "CAT";
                     $("#compania").html('Food Service Catering SA');
                     $("#comp_cuit").html('30-70801465-2');
                }

              




                let liquidacion_b = $("#liquidacion_b").val();
                let legajo = $("#legajo").val();

                let mes_pago = $( "#mes_periodo_pago option:selected" ).val();
                let anio_pago = $( "#anio_periodo_pago option:selected" ).val();
                let mes = $( "#mes option:selected" ).val();
                let lugar_pago = $("#fecha_pago").val();
                let banco = $("#banco").val();
                let fecha_deposito = $("#fecha_deposito").val();


                let mes_pago_text = $( "#mes_periodo_pago option:selected" ).text();
                let anio_pago_text = $( "#anio_periodo_pago option:selected" ).text();
                        

                 

                    $("#recibo_mes").html(mes+'/'+anio_pago);
                    $("#recibo_banco").html(banco);
                    $("#recibo_fecha_deposito").html(fecha_deposito);


            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=recibos&empresa='+empresa+'&liquidacion='+liquidacion_b+'&legajo='+legajo+'&mes_pago='+mes_pago+'&anio_pago='+anio_pago+'&lugar_pago='+lugar_pago+'&mes='+mes+'&banco='+banco+'&fecha='+fecha_deposito+'&access_token=1234567');
            
            
          
        
            

            await axios.all([axiosrequest1]).then(
            axios.spread((personal) => {
                var lista = [];

              
                



                    const formatMoney = (number, decPlaces, decSep, thouSep) => {
                        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                        decSep = typeof decSep === "undefined" ? "," : decSep;
                        thouSep = typeof thouSep === "undefined" ? "." : thouSep;
                        var sign = number < 0 ? "-" : "";
                        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
                        var j = (j = i.length) > 3 ? j % 3 : 0;

                        return sign +
                            (j ? i.substr(0, j) + thouSep : "") +
                            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
                    }


                 
              

                    const secondsToString=(seconds) => {
                      var hour = Math.floor(seconds / 3600);
                      hour = (hour < 10)? '0' + hour : hour;
                      var minute = Math.floor((seconds / 60) % 60);
                      minute = (minute < 10)? '0' + minute : minute;
                      var second = seconds % 60;
                      second = (second < 10)? '0' + second : second;
                      return hour + ':' + minute + ':' + second;
                    }





                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }


                let cantidad = 0;
                let total_registros = Object.keys(personal.data).length;

                let blober=[];
               

                $.each(personal.data, function(clave, valor) {

                  
                

                        
                       

                        lista.push({
                            
                            "legajo": clave,
                            "nombre": valor['encabezado'].apellido+" "+valor['encabezado'].nombre
                           
                        })


 setTimeout(function(){
                        
                   
 

     $("#conceptos").html("");

                        var columna_a = 0;
                        var columna_b = 0;
                        var columna_c = 0;
                        /*  ARMADO DE RECIBOS */

                        
                        $("#recibo_nombre").html(valor['encabezado'].apellido+" "+valor['encabezado'].nombre);
                        $("#recibo_ingreso").html(valor['encabezado'].leg_fecingr);
                        $("#recibo_cuil").html(valor['encabezado'].leg_cuil);
                        $("#recibo_legajo").html(clave);
                        $("#recibo_periodo").html(mes_pago_text+" "+anio_pago_text);
                        $("#recibo_categoria").html(valor['encabezado'].cat_descrip);
                        $("#recibo_calificacion").html(valor['encabezado'].car_descrip);
                        $("#recibo_seccion").html(valor['encabezado'].sec_descrip);
                        $("#recibo_modalidad").html(valor['encabezado'].mpr_descrip);
                        $("#recibo_antiguedad").html("1 año");
                        $("#recibo_remuneracion").html(valor['encabezado'].leg_basico);



                         $("#lugar_y_fecha").html(valor['encabezado'].lpg_descrip);
                         $("#obra_social").html(valor['encabezado'].oso_descrip);
                         $("#cuenta_nro").html(valor['encabezado'].leg_ctaban);
                         $("#banco_nombre").html(valor['encabezado'].bco_descrip);



                         var documento = valor['encabezado'].numdoc;



                        $.each(valor['liquidacion'],function(key,value){

                            if (value.ndet_impcal!=0) {

                                        if (value.con_tipo==0) {

                                            var concepto = "";
                                            concepto += '<div style="width:960px;height: 30px; font-size:12px;">';
                                            concepto += '<div style="width:400px;height: 22px;float:left;padding-top: 8px; padding-left: 10px;border-right: 1px solid grey">'+key+' '+value.con_descrip+' </div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;border-right: 1px solid grey;font-size:9px;"></div>';
                                            concepto += '<div style="width:180px;padding-right: 60px;height: 22px;float:left;padding-top: 8px; text-align: right;font-size:11px;border-right: 1px solid grey;background-color: #fff;">'+value.ndet_impcal+'</div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;border-right: 1px solid grey;background-color: #fff;"></div>';
                                            concepto += '<div style="width:176px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;background-color: #fff;"></div>';
                                            concepto += '</div>';

                                            $("#conceptos").append(concepto);

                                            columna_a=columna_a+value.ndet_impcal;

                                        }


                                        if (value.con_tipo==2) {

                                            var concepto = "";
                                            concepto += '<div style="width:960px;height: 30px; font-size:12px;">';
                                            concepto += '<div style="width:400px;height: 22px;float:left;padding-top: 8px; padding-left: 10px;border-right: 1px solid grey">'+key+' '+value.con_descrip+' </div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;border-right: 1px solid grey;font-size:9px;"></div>';
                                            concepto += '<div style="width:180px;padding-right: 60px;height: 22px;float:left;padding-top: 8px; text-align: right;font-size:11px;border-right: 1px solid grey;background-color: #fff;"></div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;border-right: 1px solid grey;background-color: #fff;"></div>';
                                            concepto += '<div style="width:176px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;background-color: #fff;">'+value.ndet_impcal+'</div>';
                                            concepto += '</div>';

                                            $("#conceptos").append(concepto);
                                            columna_b=columna_b+value.ndet_impcal;

                                        }


                                        if (value.con_tipo==5) {

                                            var concepto = "";
                                            concepto += '<div style="width:960px;height: 30px; font-size:12px;">';
                                            concepto += '<div style="width:400px;height: 22px;float:left;padding-top: 8px; padding-left: 10px;border-right: 1px solid grey">'+key+' '+value.con_descrip+' </div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;border-right: 1px solid grey;font-size:9px;"></div>';
                                            concepto += '<div style="width:180px;padding-right: 60px;height: 22px;float:left;padding-top: 8px; text-align: right;font-size:11px;border-right: 1px solid grey;background-color: #fff;"></div>';
                                            concepto += '<div style="width:100px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;border-right: 1px solid grey;background-color: #fff;">'+value.ndet_impcal+'</div>';
                                            concepto += '<div style="width:176px;height: 22px;float:left;padding-top: 8px; text-align: center;font-size:11px;background-color: #fff;"></div>';
                                            concepto += '</div>';

                                            $("#conceptos").append(concepto);
                                            columna_c=columna_c+value.ndet_impcal;

                                        }

                                }




                               

                        });

                        $("#total_a").html(columna_a.toFixed(2));
                        $("#total_b").html(columna_c.toFixed(2));
                        $("#total_c").html(columna_b.toFixed(2));

                        var neto = (columna_a+columna_c)-columna_b;
                        $("#total_neto").html(neto.toFixed(2));

                        var numero_a_letras = numeroALetras(neto.toFixed(2), {plural: "PESOS",singular: "PESO",centPlural: "CENTAVOS",centSingular: "CENTAVO"});
                         $("#numeroletra").html(numero_a_letras);



                         


                        /* CIERRE DE ARMADO DE RECIBOS */
                                
                                cantidad++;
                                
                                var html = $("#catering_template").html();
                                $("#pre_html").append("<div data-empresa='"+empresa+"' data-liquidacion='"+liquidacion_b+"' data-documento='"+documento+"' class='recibo' id='pre_"+cantidad+"'>"+html+"</div>")

                                    
                   /* firebase.database().ref('recibos/' +empresa+'/'+ liquidacion_b+'/'+documento).update({
                        status: 0,
                        recibo: html

                    })
                    .then(() => {



                     
                    })
                    .catch(e => {
                        console.log(e);
                    });*/



/*
    var fd = new FormData();

    fd.append('data', html);
    fd.append('empresa', empresa);
    fd.append('liquidacion', liquidacion_b);
    fd.append('documento', documento);

    console.log(fd)
    $.ajax({
    type: 'POST',
    url: 'http://localhost/TCPDF/examples/example_061.php',
    data: fd,
    processData: false,
    contentType: false
    }).done(function(data) {
    // print the output from the upload.php script
    console.log(data);
    });
*/


                                           
                                            
                                    


                                 }, cantidad * 500);
                       



                    
                });


         /*       firebase.database().ref('liquidacion_recibos/' + empresa).push({
                        liquidacion: liquidacion_b,
                        mes: mes,
                        anio:anio_pago,
                        option:mes_pago_text+" "+anio_pago

                    })
                    .then(() => {
                     
                    })
                    .catch(e => {
                        console.log(e);
                    });

                    */




                var startTime;
                function getCookie(name) {
                  var value = "; " + document.cookie;
                  var parts = value.split("; " + name + "=");
                  if (parts.length == 2) return parts.pop().split(";").shift();
                } 

                const count=() =>{
                     var reloj=setInterval(function(){
                         if(typeof getCookie('remaining')!= 'undefined')
                         {
                           startTime = document.getElementById('hms').innerHTML;
                         }
                         else if(document.getElementById('hms').innerHTML.trim()!='')
                         {
                           startTime = document.getElementById('hms').innerHTML;
                         }
                         else
                         {
                          var d = new Date(); 
                          var h=d.getHours(); 
                          var m=d.getMinutes();
                          var s=d.getSeconds();
                          startTime = h+':'+m+':'+s;
                          //OR
                          startTime  = d.toTimeString().split(" ")[0]
                         }

                         var pieces = startTime.split(":");
                         var time = new Date();
                         time.setHours(pieces[0]);
                         time.setMinutes(pieces[1]);
                         time.setSeconds(pieces[2]);
                         var timediff = new Date(time.valueOf()-1000)
                         var newtime = timediff.toTimeString().split(" ")[0];

                         if (newtime=='00:00:00') {
                            clearInterval(reloj)
                            document.cookie = "remaining=";

                         }
                         document.getElementById('hms').innerHTML=newtime ;
                         document.cookie = "remaining="+newtime;
                    },1000)
                 
                }
              
               


                
                                        

                $("#finalizado").slideUp()
                $("#iniciado").slideDown()
                $(".encontrados").html(lista.length);
                var tiempo = secondsToString(lista.length*3);
                $("#hms").html(tiempo)
                localStorage.setItem("total_recibos",lista.length);
                this.personal = lista;
                this.triggers.load = false;


                 setTimeout(function(){
                    count();
                    console.log("inicia countdown")
                },5000)

                })).then(() => {


                var total_r = 0;
                $("#alerta").hide();
                                    $('#empresas').DataTable({
                                        "language": {
                                            "decimal": ",",
                                            "thousands": ".",
                                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                            "infoPostFix": "",
                                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                            "loadingRecords": "Cargando...",
                                            "lengthMenu": "Mostrar _MENU_ registros",
                                            "paginate": {
                                                "first": "Primero",
                                                "last": "Último",
                                                "next": "Siguiente",
                                                "previous": "Anterior"
                                            },
                                            "processing": "Procesando...",
                                            "search": "Buscar:",
                                            "searchPlaceholder": "Término de búsqueda",
                                            "zeroRecords": "No se encontraron resultados",
                                            "emptyTable": "Ningún dato disponible en esta tabla",
                                            "aria": {
                                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                            },
                                        },
                                        "scrollX": false, 
                                        "search": {regex:true},
                                        dom: 'Bfrtip',
                                        responsive: false,
                                        nowrap: false,
                                        buttons: [
                                            'copy',
                                            'excel',
                                            'csv',
                                            'pdf',
                                   ]
                                    });


                                   var generaPdf=setInterval(function(){
                                    total_r++;
                                    console.log(total_r)

                                    
                                    
               
                    var opt = {
                      margin:       20,
                      filename:     'myfile.pdf',
                      image:        { type: 'jpeg', quality: 0.98 },
                      html2canvas:  { scale: 1 },
                      jsPDF:        { unit: 'mm', format: 'a3', orientation: 'portrait' }
                    };

                    // New Promise-based usage:


                    
                     html2pdf().set(opt).from(document.getElementById('pre_'+total_r)).outputImg('datauristring').then(pdfResult => {

                      


                         var fd = new FormData();

                                    fd.append('data',  pdfResult);
                                    fd.append('empresa', $("#pre_"+total_r).data('empresa'));
                                    fd.append('liquidacion', $("#pre_"+total_r).data('liquidacion'));
                                    fd.append('documento', $("#pre_"+total_r).data('documento'));

                                    console.log(fd)
                                    $.ajax({
                                    type: 'POST',
                                    url: 'http://localhost/TCPDF/examples/example_061.php',
                                    data: fd,
                                    processData: false,
                                    contentType: false
                                    }).done(function(data) {
                                    // print the output from the upload.php script
                                    console.log('generado');
                                    $("#generados").html(total_r)
                                    });


                                   
                         




                    })
                    .catch(err => console.log(err)); 

                                  if (localStorage.getItem("total_recibos")==total_r) {
                                        clearInterval(generaPdf);
                                        console.log('finalizado');
                                        $("#iniciado").slideUp()
                                        $("#finalizado").slideDown()
                                    }
                                    
                                   },4000) 


                });

               

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

            // this.$nextTick(()=>{
            // })
        },
    },
    mounted() {

        //this.obtenerPersonal();
         this.triggers.load = false;

      

        

    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}

#catering_template{
    position: absolute;
    top: -200%;
}

#pre_html{
     position: absolute;
    left: -3000px;
}

#iniciado{
    display: none;
}


#finalizado{
    display: none;
}

</style>