<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">



<div id="evaluacion">
  <div id="header">
    <button id="cerrar_evaluacion" style="position:absolute;right: 0;margin-top: 3px;margin-right: 5px;">CERRAR</button>
  </div>
  <div id="resultado"></div>
</div>



            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">
              {{ descripcion }}
            </p>

            <h6 class="card-title mt-4">Selecciona las fechas  a consultar</h6>
             <div class="row">
                  <div class="col-12">
                    <form>
                        <div class="row mb-4">
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Desde</label>
                                <input type="date" class="form-control mb-2 mr-sm-2" id="desdeasist" v-model='fdesde' @change="chequearRangoFecha()"/>
                                <span class="text-danger" v-if="fdesde == '' || fdesde == null">· La fecha de inicio es obligatoria</span>
                            </div>
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                                <input type="date" class="form-control mb-2 mr-sm-2" id="hastaasist" v-model='fhasta' @change="chequearRangoFecha()"/>
                            </div>
                            <div class="col-12" v-if="alert.tiempo.length">
                              <div class="alert alert-warning">{{alert.tiempo}}</div>
                            </div>
                        </div>
                       
                        <div class="text-right mt-3">
                            <button @click.prevent="consulta('')" class="btn btn-md btn-success">Consultar Evaluaciones</button>
                        </div>
                    </form>
                  </div>
                  
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="errors.fdesdeInvalida">
      <div class="col">
        <div class="alert alert-danger">
          La fecha de inicio es obligatoria, introducila para poder hacer una búsqueda.
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <div class="table-responsive">
                <table id="asistencias_b" class="table">
                  <thead>
                    <tr>
                        <th><span class="text-muted">Fecha y Hora</span></th>
                        <!-- <th><span class="text-muted">Status</span></th> -->
                        <th><span class="text-muted">Operacion</span></th>
                        <th><span class="text-muted">Responsable</span></th>
                        <th><span class="text-muted">Ver</span></th>
                       
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in asistencias" v-bind:key="key">
                        <td class="text-wrap">{{value.fecha}}</td>
                        <!-- <td class="text-wrap"><div v-html="value.status"></div></td> -->
                        <td class="text-wrap">
                            <span class="item-amount d-none d-sm-block text-sm text-wrap">
                            {{value.operacion_nombre}}    
                            </span>
                        </td>  
                        <td class="text-wrap">{{value.responsable_nombre}}</td>
                        <td class="text-wrap"><button @click="evaluacion(value.id)">Ver</button></td>
                        
                    </tr>  
                  </tbody>
                </table>
              </div>
            <!-- <div class="flex float-right"></div>
              <button @click="mapa()" class="btn w-sm mb-1 btn-sm btn-primary">
                Ver Mapa en Tiempo real
              </button>
            </div> -->
        </div>
      </div>
    </div>
    
  </div>
  </div>
</template>

<script>

import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
import moment from 'moment';
import ListaCarga from '../ListaCarga';

import config from './config'
import DTConfig from '@/dataTableConfig.js'


export default {
  name: "Presentismo",
  components: { ListaCarga },
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Evaluacion de comedores",
    descripcion: "Podés seleccionar una fecha específica y visualizar las evaluaciones de los comedores.",
    datos: [],
    categorias: [],
    fdesde: null,
    fhasta: null,
    total1: 0,
    conveniono1: 0,
    convenio1: 0,
    zonas: [],
    personal: [],
    asistencias: [],
    load: true,
    zonaSeleccionada:"000001",
    mesActual: null,
    alert:{
      tiempo:'',
    },
    errors: {
      fdesdeInvalida: false,
    }
  }),
  methods: {
    pad: function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    llena: function(number, len) {
      return "0".repeat(len - number.toString().length) + number.toString();
    },
    obtenerPersonalConCategorias(){
        let vm = this;
        axios
        .get("https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty")
        .then((response) => {
            $.each(response.data, function(i, item) {
                if (item.leg_numdoc > 0){
                    vm.personal[item.leg_numdoc] = item.cat_codigo;
                }
            });    

            axios
            .get("https://aplicativos.firebaseio.com/categorias.json?print=pretty")
            .then((response) => {
                $.each(response.data, function(i, item) {
                    vm.categorias[item.codigo] = item.nombre;
                });
            });
        });
    },
    fechaActual(){
        let today = new Date();
          let m = today.getMonth() + 1;
          let mes = (m < 10) ? '0' + m : m;
          let dia = today.getDate();
          dia = (dia < 10) ? '0' + dia : dia;
          return today.getFullYear() + '-' + mes + '-' + dia;
    },
    chequearRangoFecha(){
      this.alert.tiempo = '';
      this.errors.fdesdeInvalida = false;
      let mesHasta;

      if (this.fhasta) {
        mesHasta = this.fhasta.split('-')[1];
      } else {
        mesHasta = this.mesActual;
      }

      let mesDesde = this.fdesde.split('-')[1];

      if ((mesHasta - mesDesde) > 0 && (mesHasta - mesDesde) <= 2) {
        this.alert.tiempo = 'Atención la consulta puede demorar algunos segundos';
      }else if((mesHasta - mesDesde) > 2){
        this.alert.tiempo = 'Atención la consulta puede demorar más de 10 segundos'
      }
    },
    reemplazarIndefinido(string) {
        return (string==undefined || string == null || string == '') ? '-' : string;
    },

evaluacion:function(ide){

$("#resultado").html()

let url= `https://aplicativos.firebaseio.com/validar_comedor.json?orderBy=%22$key%22&equalTo=%22${ide}%22&print=pretty`;
     

      axios
      .get(url)
      .then(response => {
        let evalu = response.data;
        console.log(evalu)
     $.each(evalu,function(key,value){

     


          var mensaje = '<h3>Evaluaciòn de comedor</h3>';
              mensaje+="<b>Fecha y hora:</b>"+value.timestamp+"<br>";
              mensaje+="<b>Nombre de Responsable:</b>"+value.responsable_nombre+"<br>";
              mensaje+="<b>Operaciòn:</b>"+value.operacion_nombre+"<br><hr>";
              
              mensaje+="<b>Estaciones de Lavado de Mano</b><br>Verificar:Cartel identificatorio, Instructivo de lavado de manos, Jabón bactericida<br>";
              mensaje+="Opciòn seleccionada: "+value.check_2+"<br>";
              mensaje+="Comentario: "+value.area_2+"<br><br>";

              mensaje+="<b>La operación posee termómetro operativo?</b><br>Verificar:Que funcione correctamente. Que tenga adherido el sticker identificatorio correspondiente<br>";
              mensaje+="Opciòn seleccionada: "+value.check_3+"<br>";
              mensaje+="Comentario: "+value.area_3+"<br><br>";

              mensaje+="<b>Temperaturas de EQUIPOS DE FRIO (Heladera y Freezer)</b><br>Verificar:Freezer/Cámara de Congelado, presencia de: HELADO e HIELO TESTIGO (Rotulada). Heladera/Cámara, presencia de: BOTELLA DE AGUA o MARGARINA (Rotulada).<br>";
               mensaje+="Opciòn seleccionada: "+value.check_4+"<br>";
              mensaje+="Comentario: "+value.area_4+"<br><br>";

              mensaje+="<b>Presencia de Muestras de 3 días</b><br>Verificar:Que las muestras deben estar - De todos los turnos (medio día y noche) - Fechadas y Rotuladas. Cantidad suficiente De 3 días de servicio atrás.<br>";
               mensaje+="Opciòn seleccionada: "+value.check_5+"<br>";
              mensaje+="Comentario: "+value.area_5+"<br><br>";


              mensaje+="<b>Desinfección de Manos cada 60 minutos</b><br>Verificar:Que se realiza correctamente a todo el personal que se encuentre dentro del comedor, incluyendo: zonal, regional, cliente, bromatóloga, etc.<br>";
              mensaje+="Opciòn seleccionada: "+value.check_6+"<br>";
              mensaje+="Comentario: "+value.area_6+"<br><br>";

              mensaje+="<b>Soluciones Desinfectantes</b><br>Verificar:Que las mismas estén preparadas tanto en pulver como en baldes (Sc. Clorada) Que los baldes tengan agua traslucida Que los trapos rejillas no anden sueltos, sino dentro de los baldes - Que los pulver y los baldes estén limpios y sectorizados. Que dichas Sc se utilicen.<br>";
              mensaje+="Opciòn seleccionada: "+value.check_7+"<br>";
              mensaje+="Comentario: "+value.area_7+"<br><br>";

              mensaje+="<b>Sanitizado: Frutas y verduras – Huevos</b><br>Verificar:Que se prepare correctamente la Sc Sanitizante - Que se realice el de deshojado de las verduras de hoja Que se deje reposar 5 minutos las verduras. Que no se adelante el sanitizado, ni de un día al otro, ni de un turno al siguiente. Que se saniticen tanto sea: verduras, frutas y huevos<br>";
              mensaje+="Opciòn seleccionada: "+value.check_8+"<br>";
              mensaje+="Comentario: "+value.area_8+"<br><br>";

              mensaje+="<b>Orden de alimentos en Equipos de Frio.</b><br>Verificar:Que se guarden correctamente los alimentos dentro de heladeras, cámara y Frezeers. Utilizando de requerirse bandejas para realizar divisiones. Que todos los alimentos contaminados estén en la parte inferior y los listos para consumo en la parte inferior<br>";
               mensaje+="Opciòn seleccionada: "+value.check_9+"<br>";
              mensaje+="Comentario: "+value.area_9+"<br><br>";

              mensaje+="<b>Tabla de Colores</b><br>Verificar:Que la operación cuente con TODAS las tablas previstas por el departamento de BROMATOLOGIA: blanca, azul, roja, amarilla, verde y marrón<br>";
               mensaje+="Opciòn seleccionada: "+value.check_10+"<br>";
              mensaje+="Comentario: "+value.area_10+"<br><br>";

              mensaje+="<b>Carnet de manipulador</b><br>Verificar:Todo el personal debe tener por lo menos el certificado intermedio de gestión de carnet de manipulación VIGENTE<br>";
               mensaje+="Opciòn seleccionada: "+value.check_11+"<br>";
              mensaje+="Comentario: "+value.area_11+"<br><br>";

              mensaje+="<b>Fecha de Alimentos</b><br>Verificar:Todos los alimentos elaborados o en proceso de elaboración que se encuentran almacenados deben estar fechados. Ya sea con fecha de recepción, de congelado, de descongelado, de elaboración, etc.<br>";
               mensaje+="Opciòn seleccionada: "+value.check_12+"<br>";
              mensaje+="Comentario: "+value.area_12+"<br><br>";


              mensaje+="<b>Alimentos Vencidos -Método PEPS</b><br>Verificar:La ausencia de alimentos fuera de su vida útil, como ser: Lácteos, helados, postres, fiambres, cárnicos, pescados, pastas, aceitunas, dulces, etc.<br>";
              mensaje+="Opciòn seleccionada: "+value.check_13+"<br>";
              mensaje+="Comentario: "+value.area_13+"<br><br>";


                mensaje+="<b>Adelantos No permitidos</b><br>Verificar:Que los alimentos que se hayan adelantado, de un turno o un día para el otro, sean los permitido, como por ejemplo: pelado de tubérculos, cocción de legumbres, rellenos, etc.<br>";
               mensaje+="Opciòn seleccionada: "+value.check_14+"<br>";
              mensaje+="Comentario: "+value.area_14+"<br><br>";

               $("#evaluacion").show();
               $("#resultado").html(mensaje)

          })
          



         });

        $("#cerrar_evaluacion").click(function(){

          $("#evaluacion").hide();
          $("#resultado").html("")


        })

},

    consulta: function( origin ) {
      this.fdesdeVista = this.fdesde;
      this.fhastaVista = this.fhasta;

      if (this.fdesde == '' || this.fdesde == null) {
        this.errors.fdesdeInvalida = true;
        return null
      }

      // Se activa trigger para mostrar loader placeholder
      this.load = true;

      console.log(`desde: ${this.fdesde} hasta: ${this.fhasta}`);

      // Si no se selecciono fecha de hasta, se setea la fecha actual
      if (this.fhasta == null) {
        this.fhasta = new Date().toISOString().split('T')[0];
      }

      this.asistencias = [];
      this.total1 = 0;
      this.convenio1 = 0;
      this.conveniono1 = 0;
      var evaluacion=[];
      
      //let url = `https://auth.foodservice.com.ar/?type=presentismo&desde=${this.fdesde}&hasta=${this.fhasta}&access_token=1234567`;
      let url= `https://aplicativos.firebaseio.com/validar_comedor.json?orderBy=%22fecha%22&startAt=%22${this.fdesde}%22&endAt=%22${this.fhasta}%22&print=pretty`;
      console.log(url);

      axios
      .get(url)
      .then(response => {
        let presentes = response.data;

       $.each(presentes,function(key,value){

          evaluacion.push( {
            'fecha': value.fecha,
            'operacion_nombre': value.operacion_nombre,
            'responsable_nombre': value.responsable_nombre,
            'id':key
          })
         

        })

        this.asistencias = evaluacion;
        this.load = false;







        // $('#asistencias').dataTable().fnDestroy();

        // setTimeout(() => {
        //   }, 5000);

        this.$nextTick(() => {
         // let config = DTConfig;
          config.bSort = true;
          config.buttons = [ 'copy', 'excel', 'csv', 'pdf' ];
          config.emptyTable = "No se registraron presentes en las fechas seleccionadas para la operación seleccionada";
          $('#asistencias_b').dataTable({

            "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            },
            dom: 'Bfrtip',
            "scrollX": false, 
            "search": {regex:true},
           
            responsive: false,
            nowrap: false,
            
          buttons: [
              'copy', 'excel', 'pdf', 'print'
          ],
            })
        })
        // setTimeout(() => {
        //     this.load = false;
        //     this.$nextTick(()=>{
        //         this.actualizarTabla();
        //     });
        // }, 500);





      })
      .catch(error => {
        console.log(error);
        alert("Lo sentimos, se produjo un error. Actualice la página (tecla F5) y reintente o comuníquese con sistemas");
      })

      
    },
  },
  mounted() {
    // this.zonaSeleccionada = ;
    this.fdesde = this.fechaActual();
    this.fdesdeVista = this.fdesde;
    this.mesActual = new Date().toISOString().split('T')[0].split('-')[1];

    this.zonas = config.zonas;

    this.consulta();
  },

};
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select {
    font-size: .8rem;
}


#evaluacion {
    width: 78vw;
    height: 80vh;
    background-color: #f7f7f7;
    position: absolute;
    z-index: 99999;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    border: 2px solid #333341;
    display: none;
}

#header{
  width: 78%;
    height: 36px;
    background-color: #333341;
    position: fixed;
}


#resultado{
  padding: 10px;
}


</style>