<template>
	<div class="page-content">
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				<table width="100%"><td align="right"><button style="" class="btn btn-primary mr-2" id="cerrar_estados" data-toggle="modal" data-target="#irregulares_modal">ENVIAR OPERACIONES DESAPROBADAS</button></td></table>
				<p class="card-title" id="fecha"></p>
				<p class="card-title" id="total"></p>
				</div>  



				<div style="padding: 20px;" class="w-50 d-flex align-items-center">
                  <!-- Select mes -->
                  <select class="custom-select mr-2" id="meses">
                  </select>
                
                  <button
                    class="btn btn-success"
                    id="consultarbaja"
                    v-on:click="obtenerHistoricos()"
                  >
                    Consultar
                  </button>
              </div>

			</div>
			</div>
		</div> 
		<lista-carga v-if="loading"></lista-carga>
		<!-- Template content  -->
		<div class="row" v-else>
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table id="empresas" class="table" width="100%">
							<thead>
								<tr>
									<th>Status</th>
									<th>Nombre</th>
									<th>Razon social</th>
									<th>Tipo</th>
									<th>Zonal</th>
									<th>Al</th>
									<th>Ce</th>
									<th>Vi</th>
									<th>ACV/5 ACT</th>
									<th>Stat</th>
									<th>Per</th>
									<th>M.O</th>
									<th>Auditada</th>
									<th>Observación</th>
									<th>Acción</th>
									
									
								</tr>
							</thead>
							<tbody>
								<tr v-for="operacion in operaciones" v-bind:key="operacion.id">


			                       <td>
                            <span v-if="operacion.status=='0'" class="badge text-uppercase badge-success">Aprobado</span>
                            <span v-else class="badge text-uppercase badge-danger">Desaprobado</span>
                        </td>
									
									<td>{{operacion.name}}</td>
									
									<td v-if="operacion.razonsocial=='Food Service America'"><span class="badge badge-success">{{operacion.razonsocial}}</span></td>
									<td v-else><span class="badge badge-info">{{operacion.razonsocial}}</span></td>
									
									<td>{{operacion.tipo_empresa}}</td>
									
									<td>{{operacion.zonal}}</td>
									
									<td>{{operacion.almuerzo}}</td>
									<td>{{operacion.cena}}</td>
									<td>{{operacion.vianda}}</td>
									
									<td>{{operacion.acv}}</td>
									
									<td>
										<span v-if="operacion.chart=='mayor'"  class="arraw up badge badge-success" style="color:#fff;">↑</span>
										<span v-if="operacion.chart=='menor'" class="arraw down  badge badge-danger" style="color:#fff;">↓</span>
										<span v-if="operacion.chart=='igual'" class="arraw igual badge badge-light" style="color:#000;">↔</span>
									</td>

									<td>{{operacion.personal.toString().replace('.',',')}}</td>
									
									<td v-if="operacion.manoobra === 'Correcto'"><span class="badge badge-success">{{operacion.manoobra}}</span></td>
									<td v-if="operacion.manoobra === 'Irregular'"><span class="badge badge-danger">{{operacion.manoobra}}</span></td>
									<td v-if="operacion.manoobra === 'Observar'"><span class="badge badge-warning">{{operacion.manoobra}}</span></td>

									<td v-if="operacion.auditada === 'JUSTIFICADA'"><span class="badge badge-success">{{operacion.auditada}}</span></td>
									<td v-if="operacion.auditada === 'INJUSTIFICADA'"><span class="badge badge-danger">{{operacion.auditada}}</span></td>
									
									
									<td :id="'ide-'+operacion.ubicacion" v-html="operacion.observacion"></td>
									<td>
										
										<button v-if="operacion.status=='0'" style="width:91.4px" type="button" class="btn btn-danger btn-xs bloqueado" @click="cambiarStatus(operacion)">DESAPROBAR</button>
										<button v-else type="button" style="width:91.4px" class="btn btn-success btn-xs bloqueado" @click="cambiarStatus(operacion)">APROBAR</button>
										
										
										<button type="button" class="btn btn-secondary btn-xs ml-2 bloqueado" @click="observarOperacionId(operacion.fecha, operacion.fireId, operacion.ubicacion)">
											Observar
											
										</button>
									</td>
									
									
									
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			</div>
		</div>
		



<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="irregulares_modal" tabindex="-1" aria-labelledby="irregulares_modalLabel" aria-hidden="true">
   <div class="modal-dialog modal-lg  modal-dialog-scrollable">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title" id="irregulares_modalLabel"></h5>
        
      </div>
      <div class="modal-body "  id="paraenviar">
       <table id="contenido_irregulares" border="1" cellpadding="3" width="100%"></table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">CERRAR</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" id="guardar_estados">GUARDAR Y ENVIAR A APROBACIÓN</button>
      </div>
    </div>
  </div>
</div>



        <modal-administrar
            v-if="modalAdministrar" 
            :empresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-asignar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalAdministrar=false"
        >
        </modal-administrar>

		<modal-observar
			v-if="modalObservar" 
            :modalOperacion_fecha="modalOperacion_fecha"
			:modalOperacion_fireid="modalOperacion_fireid"
			:modalOperacion_ubicacion="modalOperacion_ubicacion"
            @cerrar-modal-observar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalObservar=false"
		></modal-observar>

		<modal-zonal
		v-if="modalZonal"
		:empresa="modalOperacionId"
		:nombreEmpresa="modalOperacionNombre"
		@cerrar-modal-zonal="refrescarEmpresas($event)"
		></modal-zonal>
	</div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservarHistorico.vue';
import ListaCarga from "../ListaCarga";
import ModalZonal from './ModalZonal.vue';
import firebase from "firebase/app";
import "firebase/database";
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import Swal from "sweetalert2";

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: { 
        ModalAdministrar, 
        ListaCarga ,
		ModalObservar,
        ModalZonal
    },    
	name: 'Empresas',
   
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Mano de obra irregular',
            descripcion: 'En este modulo usted podra visualizar y aprobar/desaprobar personal irregular en  operaciones',
			loading: true,
            operaciones: [],
			modalOperacion_fecha: null,
			modalOperacion_fireid: null,
			modalOperacion_ubicacion: null,
			modalAdministrar: false,
			modalObservar: false,
			modalZonal: false,
        }
    },

    methods: {
    	isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      	},
		 cambiarStatus(caso){
		        let status = (caso.status == "0") ? "1" : "0"; 
		        console.log(status)
		        firebase.database().ref('operaciones_irregulares/' + caso.fecha + '/' + caso.fireId+'/'+caso.ubicacion).update({
		            status: status,
		        })
		        .then(()=>{
		            caso.status = !caso.status;
		            caso.status = status;
		        })
		        .catch(e => {
		            console.log(e);
		        });
		    },
		observarOperacionId(fecha, fireid,ubicacion){
			this.modalOperacion_fecha = fecha;
			this.modalOperacion_fireid = fireid;
			this.modalOperacion_ubicacion = ubicacion;
			setTimeout(() => {
				this.modalObservar = true;
			}, 200);
		},
		async obtenerInformacionEmpresas(){
			
			this.loading = true;


			var lista=[];
			$("#meses").html("");
			var count=0;

		const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&limitToLast=1');
		const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&limitToLast=1000');
	


		await axios.all([axiosrequest1,axiosrequest2]).then(axios.spread((operaciones,historicos) => {	
			
			var cantidad = Object.keys(historicos.data).length;
			console.log(cantidad)

			$.each(historicos.data, function(clave, val) {
				count++;
				console.log(cantidad+"=="+count)
				if (cantidad==count) {
					$("#meses").prepend("<option selected='true' value='"+clave+"'>"+clave+"</option>");
				}else{
					$("#meses").prepend("<option value='"+clave+"'>"+clave+"</option>");
				}
				
			});
				
				var i=0;
		// console.log(operaciones.data);
		$.each(operaciones.data, function(clave, val) {
			localStorage.setItem("fecha",clave);
			$("#fecha").html("REPORTE DE OPERACIONES IRREGULARES A LA FECHA: "+clave.split("-")[2]+"-"+clave.split("-")[1]+"-"+clave.split("-")[0]);
			$.each(val, function(cl, dato) {
				localStorage.setItem("firebaseId",cl);
				$("#total").html("TOTAL DE OPERACIONES IRREGULARES: "+dato.length);
				$.each(dato, function(cla, valor) {
				
					 var status = "0";

                            if (valor.status!=null) {
                                status=valor.status;
                            }




					lista.push({
						"status": status,
						"acv": valor.acv,
						"acvb": valor.acvb,
						"almuerzo": valor.almuerzo,
						"ayer": valor.ayer,
						"cena": valor.cena,
						"centroapropiacion_id": valor.centroapropiacion_id,
						"chart": valor.chart,
						"codigo_costos": valor.codigo_costos,
						"desayuno": valor.desayuno,
						"id": valor.id,
						"id_consumos": valor.id_consumos,
						"manoobra": valor.manoobra,
						"merienda": valor.merienda,
						"auditada":valor.auditada,
						"name": valor.name,
						"observacion":valor.observacion,
						"personal": valor.personal,
						"razonsocial": valor.razonsocial,
						"tipo_empresa": valor.tipo_empresa,
						"vianda": valor.vianda,
						"zonal": valor.zonal,
						"fecha":clave,
						"fireId":cl,
						"ubicacion":cla
	                });


if (valor.enviado==1) {
	localStorage.setItem("bloqueado",1);
	$("#cerrar_estados").attr("disabled",true);
	$("#cerrar_estados").html("LA FECHA SELECCIONADA YA FUE CERRADA Y ENVIADA");
	

}


				});

			});
		});



 


            
			console.log(lista);
            this.operaciones = lista;

       
$("#cerrar_estados").click(function(){
  $("#contenido_irregulares").empty();  
  var irregulares = [];        	
var hoy_dato = "";
$.get( "https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&limitToLast=1", function( data ) {


	$.each(data, function(cl, dato) {
					hoy_dato = cl;
		$.each(dato, function(cl, val) {	
			$.each(val, function(cla, valor) {	
			
			if (valor.status=="1") {
							

							irregulares.push({
								"id_consumos": valor.id_consumos,
								"razonsocial": valor.razonsocial,
								"id": valor.id,
								"centroapropiacion_id": valor.centroapropiacion_id,
								"codigo_costos": valor.codigo_costos,
								"name": valor.name,
								"tipo_empresa": valor.tipo_empresa,
								"zonal": valor.zonal,
								"almuerzo": valor.almuerzo,
								"auditada":valor.auditada,
								"cena": valor.cena,
								"vianda": valor.vianda,
								"desayuno": valor.desayuno,
								"merienda": valor.merienda,
								"acv": valor.acv,
								"acvb": valor.acvb,
								"ayer": valor.ayer,
								"chart":valor.chart,
								"personal": valor.personal,
								"manoobra":valor.manoobra,
								"observacion":"HRBP: "+valor.observacion
							});


					}
				});


		});

	});



	
			            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td colspan="6">TOTAL DE OPERACIONES IRREGULARES: '+irregulares.length+'</td></tr>');
			            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td>OPERACIÓN</td><td>TIPO</td><td>ACV/5</td><td>PERSONAL</td><td>STATUS</td></tr>');
			            	console.log("IRREGULARES")
		            	
		            		$("#irregulares_modalLabel").html("Operaciones IRREGULARES: "+hoy_dato)

		            		$.each(irregulares,function(clave,valor){
			            		console.log(valor.name)
			            		$("#contenido_irregulares").append("<tr><td align='left'>"+valor.name+"</td><td align='left'>"+valor.tipo_empresa+"</td><td align='left'>"+valor.acv+"</td><td align='left'>"+valor.personal+"</td><td align='left'>"+valor.manoobra+"</td></tr>")
			            	})



							});



            })


$("#guardar_estados").click(function(){
            	
            	Swal.showLoading()


            	firebase.database().ref('operaciones_irregulares/' + localStorage.getItem("fecha")+'/'+localStorage.getItem("firebaseId")+'/0').update({
		            enviado: 1,
		        })
		        .then(()=>{
		           
		        })
		        .catch(e => {
		            console.log(e);
		        });



            	 axios.post("https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "regionales@foodservice.com.ar",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           				 Swal.close();
                                    Swal.fire(
                                      "Datos guardados y enviados correctamente!",
                                      "Si el responsable no encuentra en su bandeja de entrada informa que verifique en su casilla de spam",
                                      "success"
                                    );
		                            })




axios.post("https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "mnoelvassallo@gmail.com",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           			
		                            })
            	
           

axios.post("https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "jfernandez@foodservice.com.ar",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           			
		                            })
            	
            	

axios.post("https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "mceruti@foodservice.com.ar ",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           			
		                            })



axios.post("https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "lvallejo@foodservice.com.ar",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           			
		                            })



		                        
            	
 });




			        


		})).then(() => {
			this.loading = false;
			this.$nextTick(()=>{
				


				
				$('#empresas').DataTable({
					"language": {
						"decimal": ",",
						"thousands": ".",
						"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
						"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
						"infoPostFix": "",
						"infoFiltered": "(filtrado de un total de _MAX_ registros)",
						"loadingRecords": "Cargando...",
						"lengthMenu": "Mostrar _MENU_ registros",
						"paginate": {
							"first": "Primero",
							"last": "Último",
							"next": "Siguiente",
							"previous": "Anterior"
						},
						"processing": "Procesando...",
						"search": "Buscar:",
						"searchPlaceholder": "Término de búsqueda",
						"zeroRecords": "No se encontraron resultados",
						"emptyTable": "Ningún dato disponible en esta tabla",
						"aria": {
							"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
							"sortDescending": ": Activar para ordenar la columna de manera descendente"
						},
					},

 columnDefs: [{ targets: 12,className: 'text-nowrap',width: '200px' }],



					
					"search": {regex:true},
					dom: 'Bfrtip',
					responsive: true,
					nowrap: true,
					buttons: [
						'copy',
						'excel',
						'csv',
						'pdf'
					]
				})
			})
		});


		if (localStorage.getItem("bloqueado")==1) {
			$(".bloqueado").attr("disabled",true);
		}

		},



async obtenerHistoricos(){
			
			this.loading = true;


			var lista=[];
			
			var count=0;

			let meses = $("#meses option:selected").val();

		const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&equalTo=%22'+meses+'%22&limitToLast=1');
		
	
console.log('https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&equalTo=%22'+meses+'%22&limitToLast=1')

		await axios.all([axiosrequest1]).then(axios.spread((operaciones) => {	
			
			
			
				var i=0;
		// console.log(operaciones.data);
		$.each(operaciones.data, function(clave, val) {
				localStorage.setItem("fecha",clave);
			$("#fecha").html("REPORTE DE OPERACIONES IRREGULARES A LA FECHA: "+clave.split("-")[2]+"-"+clave.split("-")[1]+"-"+clave.split("-")[0]);
			$.each(val, function(cl, dato) {
				localStorage.setItem("firebaseId",cl);
				$("#total").html("TOTAL DE OPERACIONES IRREGULARES: "+dato.length);
				$.each(dato, function(cla, valor) {
				
					 var status = "0";

                            if (valor.status!=null) {
                                status=valor.status;
                            }


					lista.push({
						"status": status,
						"acv": valor.acv,
						"acvb": valor.acvb,
						"almuerzo": valor.almuerzo,
						"ayer": valor.ayer,
						"cena": valor.cena,
						"centroapropiacion_id": valor.centroapropiacion_id,
						"chart": valor.chart,
						"codigo_costos": valor.codigo_costos,
						"desayuno": valor.desayuno,
						"id": valor.id,
						"id_consumos": valor.id_consumos,
						"manoobra": valor.manoobra,
						"merienda": valor.merienda,
						"name": valor.name,
						"observacion":valor.observacion,
						"personal": valor.personal,
						"auditada":valor.auditada,
						"razonsocial": valor.razonsocial,
						"tipo_empresa": valor.tipo_empresa,
						"vianda": valor.vianda,
						"zonal": valor.zonal,
						"fecha":clave,
						"fireId":cl,
						"ubicacion":cla
	                });


	                if (dato[0].enviado==1) {
	localStorage.setItem("bloqueado",1);
	$("#cerrar_estados").attr("disabled",true);
	$("#cerrar_estados").html("LA FECHA SELECCIONADA YA FUE CERRADA Y ENVIADA");
	

}else{
	localStorage.setItem("bloqueado",0);
	$("#cerrar_estados").attr("disabled",false);
	$("#cerrar_estados").html("ENVIAR OPERACIONES DESAPROBADAS");
}

				});

			});
		});



 


            
			// console.log(lista);
            this.operaciones = lista;

       
$("#cerrar_estados").click(function(){
  $("#contenido_irregulares").empty();  
  var irregulares = [];        	
var hoy_dato = "";
$.get( "https://aplicativos.firebaseio.com/operaciones_irregulares.json?orderBy=%22$key%22&limitToLast=1", function( data ) {


	$.each(data, function(cl, dato) {
					hoy_dato = cl;
		$.each(dato, function(cl, val) {	
			$.each(val, function(cla, valor) {	
			
			if (valor.status=="1") {
							

							irregulares.push({
								"id_consumos": valor.id_consumos,
								"razonsocial": valor.razonsocial,
								"id": valor.id,
								"centroapropiacion_id": valor.centroapropiacion_id,
								"codigo_costos": valor.codigo_costos,
								"name": valor.name,
								"tipo_empresa": valor.tipo_empresa,
								"zonal": valor.zonal,
								"almuerzo": valor.almuerzo,
								"cena": valor.cena,
								"vianda": valor.vianda,
								"desayuno": valor.desayuno,
								"merienda": valor.merienda,
								"auditada":valor.auditada,
								"acv": valor.acv,
								"acvb": valor.acvb,
								"ayer": valor.ayer,
								"chart":valor.chart,
								"personal": valor.personal,
								"manoobra":valor.manoobra,
								"observacion":valor.observacion

			                });
					}
		});


		});

	});



	
            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td colspan="6">TOTAL DE OPERACIONES IRREGULARES: '+irregulares.length+'</td></tr>');
            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td>OPERACIÓN</td><td>TIPO</td><td>ACV/5</td><td>PERSONAL</td><td>STATUS</td><td>OBSERVACIÓN</td></tr>');
            	console.log("IRREGULARES")
            	
            	$("#irregulares_modalLabel").html("Operaciones IRREGULARES: "+hoy_dato)

            	

            	$.each(irregulares,function(clave,valor){
            		console.log(valor.name)
            		$("#contenido_irregulares").append("<tr><td align='left'>"+valor.name+"</td><td align='left'>"+valor.tipo_empresa+"</td><td align='left'>"+valor.acv+"</td><td align='left'>"+valor.personal+"</td><td align='left'>"+valor.manoobra+"</td><td align='left'>"+valor.observacion+"</td></tr>")
            	})



});




            

            	


            })


$("#guardar_estados").click(function(){
            	Swal.showLoading()

firebase.database().ref('operaciones_irregulares/' + localStorage.getItem("fecha")+'/'+localStorage.getItem("firebaseId")+'/0').update({
		            enviado: 1,
		        })
		        .then(()=>{
		           
		        })
		        .catch(e => {
		            console.log(e);
		        });




            	 axios
		                          .post(
		                            " https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "pragno@foodservice.com.ar",
		                              subject: "OPERACIÓNES IRREGULARES DESAPROBADAS!",
		                              body: "<br><center><h1>Operaciones irregulares desaprobadas</h1><br><h3>"+$("#irregulares_modalLabel").html()+"</h3><br><br>"+$("#paraenviar").html()+"</center>",
		                            }
		                          )
		                          .then((response) => {
		                           				 Swal.close();
                                    Swal.fire(
                                      "Datos guardados y enviados correctamente!",
                                      "Si el responsable no encuentra en su bandeja de entrada informa que verifique en su casilla de spam",
                                      "success"
                                    );
		                            })
            	
            	});
			        


		})).then(() => {
			this.loading = false;
			this.$nextTick(()=>{
				


				
				$('#empresas').DataTable({
					"language": {
						"decimal": ",",
						"thousands": ".",
						"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
						"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
						"infoPostFix": "",
						"infoFiltered": "(filtrado de un total de _MAX_ registros)",
						"loadingRecords": "Cargando...",
						"lengthMenu": "Mostrar _MENU_ registros",
						"paginate": {
							"first": "Primero",
							"last": "Último",
							"next": "Siguiente",
							"previous": "Anterior"
						},
						"processing": "Procesando...",
						"search": "Buscar:",
						"searchPlaceholder": "Término de búsqueda",
						"zeroRecords": "No se encontraron resultados",
						"emptyTable": "Ningún dato disponible en esta tabla",
						"aria": {
							"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
							"sortDescending": ": Activar para ordenar la columna de manera descendente"
						},
					},

 columnDefs: [{ targets: 12,className: 'text-nowrap',width: '200px' }],



					
					"search": {regex:true},
					dom: 'Bfrtip',
					responsive: true,
					nowrap: true,
					buttons: [
						'copy',
						'excel',
						'csv',
						'pdf'
					]
				})
			})
		});

		if (localStorage.getItem("bloqueado")==1) {
			$(".bloqueado").attr("disabled",true);
		}else{
			$(".bloqueado").prop("disabled",false);
		}

		},



		refrescarEmpresas(seReasigno){
            this.modalAdministrar = false;
            this.modalObservar = false;
			this.modalZonal = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerInformacionEmpresas();
            }
        },
    },
	async mounted() {
		this.obtenerInformacionEmpresas();		
    },
}

</script>

<style scoped>
.observacion-existente {
	background: #10b759;
	border-radius: 50%;
	padding: 3px 4px;
	padding-top: 4px;
	width: 16px;
	height: 16px;
	position: absolute;
	transform: translate(52px, -20px);
	font-size: 12px;
}

.modal-lg, .modal-xl {
    max-width: 90%;
}


#empresas td.text-nowrap{
  white-space: inherit!important;
}

</style>