<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>


                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Selecciona el dia a consultar</label>
                       <input type="date" id="desde" class="form-control" value="">
                    </div>
                    <div class="col" style="display: none;">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                          <input type="date" id="hasta" class="form-control" value="">
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="refrescarPersonal()">Consultar Ausentes</button>
                    </div>
                </div>
          


                        </div>  
                      </div>
                    </div>
                  </div>    

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title" id="mestext"></h6>
                           <div id="alerta" class="alert alert-danger" role="alert">Esta acción puede tardar unos segundos!, estamos recopilando datos</div>


<!--
<div id="loading">
  
 <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title w-25"><div class="load-box"></div></div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
-->

<h1>Informe de Ausentes del <span id="fecha"></span>- (<span id="total"></span>)</h1>
<div id="accordionPresentismo" v-html="accordionHTML" class="accordion"></div>




</div>
                      </div>
                    </div>
                  </div>


             

            </div>


            <!-- AGREGAR NOVEDAD A PERSONAL PLANTA-->

            <div class="modal fade nuevo" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">


                <div class="modal-dialog modal-lg">
                  <div class="modal-content" style="padding-bottom: 130px">
                    <div id="espera"><br><br><br>
                        <center>ESPERE UN INSTANTE! <br> ESTAMOS GUARDANDO Y PROCESANDO LA NOVEDAD DENTRO DEL PRESENTISMO<br>NO CIERRE EL NAVEGADOR POR FAVOR</center>
                    </div>
                    <div class="modal-header">
                      <h5 class="modal-title h4" id="myLargeModalLabel">Asignar novedad a <span id="nombre_novedad"></span></h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="cerrar_novedad">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      



<table width="100%">
    

<td colspan="3" width="70%">
   <div class="form-group formulario" data-legajo="" data-empresa="">
      <label for="exampleFormControlInput1">*Novedad *</label><br>
      <select data-empresa="" data-legajos="" id="novedades" class="js-example-basic novedadsel" style="width:100%;width: 100%;border: 1px solid #9b9b9b;color: #2e2929;">
         <option value="0">-- BUSCA Y SELECCIONA NOVEDAD --</option>
         <option data-condicion="y" value="21">Accidente</option>
         <option data-condicion="Y"  value="21">Accidente primero 10 dias</option>
         <option data-condicion="y" value="100">Ausencia Injustificada</option>

         <option data-condicion="y" value="232">Adicional por cargo</option>
         <option data-condicion="Y"  value="37">ART dias 11 y siguiente</option>
         <option data-condicion="y" value="105">Descuento dias de ingreso</option>
         <option data-condicion="y" value="106">Descuento dias de egreso</option>

         <option data-condicion="y" value="20">Enfermedad</option>
         
         <option data-condicion="y" value="F">Franco</option>


          <option data-condicion="m" value="6">Feriado</option>
           <option data-condicion="m" value="267">Feriado Supervisor</option>
            <option data-condicion="m" value="7">Feriado 2/8 ART31 CCT</option>


         <option data-condicion="y" value="79">HS Representación gremial</option>
         
         <option data-condicion="y"  value="102">Lic. sin goce/sueldo</option>
         <option data-condicion="y" value="38">Lic. vacuna Covid</option>
         <option data-condicion="y" value="129">Lic. Maternidad</option>
         <option data-condicion="y" value="25">Lic. Examen</option>
         <option data-condicion="y" value="116">Lic. Excedencia</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Conyuge e Hijos)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Padre/Madre)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Hermanos)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Abuelos o Suegros)</option>
         <option data-condicion="y" value="22">Lic. Matrimonio</option>
         <option data-condicion="y" value="26">Lic. Mudanza</option>
         <option data-condicion="y" value="23">Lic. Nacimiento</option>
         <option data-condicion="y" value="28">Lic. Vacaciones</option>
         <option data-condicion="y" value="27">Lic. donac. sangre</option>
         <option data-condicion="y" value="31">Lic. tram. jud</option>
         <option data-condicion="Y"  value="638">Lic. enfermedad prolongada</option>

         <option data-condicion="y" value="PM">Presente Manual</option>

         <option data-condicion="y" value="101">Suspension Disciplinaria</option>
         <option data-condicion="y" value="122">Suspension Operartiva</option>

         <option data-condicion="m"  value="15">Hs. extras 50%</option>
         <option data-condicion="m"  value="173">Hs. extras 100%</option>
         <option data-condicion="m"  value="19">Adic. horas nocturnas</option>


         
         
         
        
         
         
       
      </select>
      
   </div>
   
   <br>
   
   <div class="form-group" id="extras" style="display:none;"><label for="exampleFormControlInput1">Cantidad de horas</label><br><input id="horasm" type="time" style="width:90%;"><br></div>
    
    <br>
   <table width="100%">
      <tbody>
         <tr>
            <td>Desde *</td>
            <td>Hasta *</td>
         </tr>
         <tr>
            <td><input style="width: 50%;" value="" type="date" id="inix"  min=""></td>
            <td><input style="width: 50%;" value="" type="date" id="finix" min=""></td>
         </tr>
      </tbody>
   </table>


    




 

   <div class="form-group">
      <label for="exampleFormControlInput1">Certificado/Documentación</label><br><br>
      <div class="custom-file" style="height:auto;">
         <center><input type="file" id="imagen" data-legajo=""  style="display: block: important!;">
         </center>
      </div>
   </div>
   <div class="form-group"><label for="exampleFormControlTextarea1">Detalle</label><br><textarea style="width: 100%;border: 1px solid #9b9b9b;color: #2e2929;" class="form-control" id="detalle" rows="3" width="100%"></textarea></div>
</td>
<td style="background-color: #e1e1e1;border: 43px solid white;">
    <label for="exampleFormControlInput1">Vista previa del certificado</label><br>
    <img width="100%" id="preview" src="">
</td>

</table>


<center> <button class="btn btn-success w-25" style="width: 100%" @click="enviarNovedad()">GUARDAR NOVEDAD</button></center>





                    </div>
                  </div>
                </div>
              </div>


     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
import 'datatables.net-fixedcolumns';
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
let storageRef = firebase.storage().ref();
export default {
    name: 'Personal',
   
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal Ausentes',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal Ausente',
            triggers: {load:true},
            personal: [],
            legales: [],
            accordionHTML: '',
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {


      


        enviarNovedad() {


if ($("#novedades option:selected").val()!=0||$("#inix").val()!=""||$("#finix").val()!="") {

       localStorage.setItem("enviado_novedad",0)
       $("#espera").show();


              const addZero= (i) => {
                  if (i < 10) {
                    i = "0" + i;
                  }
                  return i;
                }




   
   
               var legajo = localStorage.getItem("novedad_legajo");
               var empresa =localStorage.getItem("novedad_sector");


               var nombre = localStorage.getItem("novedad_nombre");
               var novedad = $("#novedades option:selected").text();
               var condicion = "y";
               var novedad_id = $("#novedades option:selected").val();
               var horas = "-";
              
                if ($("#horasm").val()!="") {
                    var horas = $("#horasm").val();
                }
               



               
               

               var desde = $("#inix").val();
               var hasta = $("#finix").val();
               var certificado = $("#preview").attr("src");
               var detalle = $("#detalle").val();
               var responsable = JSON.parse(sessionStorage.getItem('userData')).leg_apellido+" "+JSON.parse(sessionStorage.getItem('userData')).leg_nombre;
               var operacion_raiz = "Food Service Planta";
   
   

              var h = new Date();
              var hora = addZero(h.getHours());
              var minutos = ("0" + h.getMinutes()).substr(-2);
              var segundos = ("0" + h.getSeconds()).substr(-2);
              var horaActual = hora + ':' + minutos + ':' + segundos;

              var today = new Date();
              var m = today.getMonth() + 1;
              var mes = (m < 10) ? '0' + m : m;
              var dia = today.getDate();
              dia = (dia < 10) ? '0' + dia : dia;
              var fechaActual = today.getFullYear() + '-' + mes + '-' + dia;
              var pedido = fechaActual + ' ' + horaActual;

               var estatus = "Resuelto";

              
                            if (certificado=="") {
                                certificado="-";

                                firebase.database().ref('novedades_usuarios_test').push({

                                                      fecha: pedido,
                                                      fecha_novedad: fechaActual,
                                                      responsable: responsable,
                                                      operacion_raiz:operacion_raiz,
                                                      legajo: legajo,
                                                      nombre: nombre,
                                                      id_novedad: 'rojo',
                                                      novedad: novedad,
                                                      bejerman_id: novedad_id,
                                                      detalle: detalle,
                                                      status: estatus,
                                                      desde:desde,
                                                      hasta:hasta,
                                                      checking:1,
                                                      responsable_dni:JSON.parse(sessionStorage.getItem('userData')).leg_numdoc,
                                                      certificado:certificado,
                                                      horas:horas,
                                                      obs:1

                                                    }, function (error) {

                                                      if (error) {
                                                        alert("Ocurrio un error! verifique su conexión a internet!")
                                                      } else {



                                                        localStorage.setItem("enviado_novedad",1)
                                                          
                                                        
                                                      }

                                                    });

                                }else{

                                              var mountainsRef = storageRef.child('novedades/'+new Date().getTime()+'-'+Math.ceil(Math.random()*1000));
                                              mountainsRef.putString(certificado, 'data_url').then(function(snapshot) {
                                                
                                                snapshot.ref.getDownloadURL().then(function(downloadURL) {
                                                   firebase.database().ref('novedades_usuarios_test').push({

                                                              fecha: pedido,
                                                              fecha_novedad: fechaActual,
                                                              responsable: responsable,
                                                              operacion_raiz:operacion_raiz,
                                                              legajo: legajo,
                                                              nombre: nombre,
                                                              id_novedad: 'rojo',
                                                              novedad: novedad,
                                                              bejerman_id: novedad_id,
                                                              detalle: detalle,
                                                              status: estatus,
                                                              desde:desde,
                                                              hasta:hasta,
                                                              checking:1,
                                                              responsable_dni:JSON.parse(sessionStorage.getItem('userData')).leg_numdoc,
                                                              certificado:downloadURL,
                                                              horas:horas,
                                                              obs:1

                                                            }, function (error) {

                                                              if (error) {
                                                                alert("Ocurrio un error! verifique su conexión a internet!")
                                                              } else {

                                                                localStorage.setItem("enviado_novedad",1)
                                                                
                                                                
                                                                  
                                                                
                                                              }

                                                            });
                                                   
                                                })
                                        });

                                }

                  
                   
            var enviado = setInterval(function(){
                 if (localStorage.getItem("enviado_novedad")==1) {
                    
                    $("#cerrar_novedad").click();
                    $("#espera").hide()
                    clearInterval(enviado)
                    localStorage.setItem("enviado_novedad",0)
                 }
                 
             }.bind(this), 2000) 


          /* CIERRA SUBIR DATOS A FIREBASE */


}else{

    alert("DEBE COMPLETAR LOS CAMPOS OBLIGATORIOS!")
}


},
       

      
        async obtenerPersonal(dias,mes,anio,desde,hasta){
          

console.log("CONSULTAR DESDE:"+desde)
console.log("CONSULTAR HASTA:"+hasta)

             $('#alerta').show()

           var data = []; // Aquí se cargarán los datos desde la API
   

    



    
    $('#desde').val(desde);
    $('#hasta').val(hasta);

    fetchAndRenderData(desde, hasta); // Carga inicial con la fecha de hoy

    $('#submitDates').click(function() {
        $('#accordionPresentismo').html("");
        $('#total').html("")

        var desde = $('#desde').val();
        var hasta = $('#desde').val();
        fetchAndRenderData(desde, hasta);
    });



function fetchAndRenderData(desde, hasta) {

console.log("INICIADO")
      console.log(hasta)

// Función para agregar un cero si el número es menor que 10
const agregarCero=(i)=> {
  return (i < 10) ? "0" + i : i;
}


/*
// Convertir la cadena a un objeto Date
var desde = new Date(desde);
// Extraer el año, mes y día
var desde_anio = desde.getFullYear();
var desde_mes = agregarCero(desde.getMonth() + 1); // getMonth() devuelve un índice basado en cero, por lo tanto sumamos 1
var desde_dia = agregarCero(desde.getDate());

// Formatear la fecha en el formato deseado
var desde = desde_anio + '-' + desde_mes + '-' + desde_dia;



// Convertir la cadena a un objeto Date
var hasta = new Date(hasta);
// Extraer el año, mes y día
var hasta_anio = hasta.getFullYear();
var hasta_mes = agregarCero(hasta.getMonth() + 1); // getMonth() devuelve un índice basado en cero, por lo tanto sumamos 1
var hasta_dia = agregarCero(hasta.getDate());

// Formatear la fecha en el formato deseado
var hasta = hasta_anio + '-' + hasta_mes + '-' + hasta_dia;

*/


        var desdeMes = desde.split("-")[1];
        var hastaMes = hasta.split("-")[1];

        var desdeAnio = desde.split("-")[0];
        var hastaAnio = hasta.split("-")[0];

        var mes = desdeMes;
        var anio = desdeAnio;

        if (desdeMes !== hastaMes || desdeAnio !== hastaAnio) {
            alert("Las fechas deben estar dentro del mismo mes y año.");
            return; // No continuar con la solicitud API si las fechas no cumplen con la condición
        }

       

        // Asegurarse de que el mes tenga dos dígitos
     
        $.getJSON(`https://auth.foodservice.com.ar/?type=presentismo_reporte_pro&desde=${desde}&hasta=${hasta}&mes=${mes}&anio=${anio}&access_token=1234567`, function(response) {
            data = response; // Asegúrate de que esto es un array
            renderAccordion(data, desde, hasta);
        }).fail(function() {
            console.error('Error fetching data');
        });
    }

   


    

    function parseDate(input) {
        var parts = input.split('-'); // formato esperado "AAAA-MM-DD"
        return new Date(parts[0], parts[1] - 1, parts[2]); // Mes es 0-indexado
    }



async function novedad(nombre,dni,legajo,sector,index){
            $("#nombre_novedad").html(nombre)
            $('#preview').attr('src', '');
            localStorage.removeItem("novedad_imagen")
            localStorage.removeItem("novedad_nombre")
            localStorage.removeItem("novedad_dni")
            localStorage.removeItem("novedad_legajo")
            localStorage.removeItem("novedad_sector")



            localStorage.setItem("novedad_nombre",nombre)
            localStorage.setItem("novedad_dni",dni)
            localStorage.setItem("novedad_legajo",legajo)
            localStorage.setItem("novedad_sector",sector)

            var day = index+1;
            if (day<10) {
              day = "0"+day;
            }


            var mesx = localStorage.getItem("mes_x",mes);
            if (mesx<10) {
              mesx = "0"+mesx;
            }
           

            setTimeout(function(){
                $(".modal-backdrop.show").prop("style","opacity: 0.9 !important;")
                $(".modal-lg, .modal-xl").prop("style","max-width: 90% !important;")
                
               
                var aniox = localStorage.getItem("anio_x",anio);

                $("#inix,#finix").prop("min",aniox+"-"+mesx+"-"+day);

                $("#imagen").change(function(){

                    var fileInput = document.getElementById('imagen');

                    var reader = new FileReader();
                    reader.readAsDataURL(fileInput.files[0]);

                    reader.onload = function () {
                    var datas = reader.result;
                    

                     new Promise( async function(resolve,reject){
                        var newWidth = 300;

                        // We create an image to receive the Data URI
                        var img = document.createElement('img');

                        // When the event "onload" is triggered we can resize the image.
                        img.onload = function()
                        {        
                            // We create a canvas and get its context.
                            var canvas = document.createElement('canvas');
                            var ctx = canvas.getContext('2d');

                          


                        var imgwidth = img.width;
                        var imgheight = img.height;
                        canvas.width = newWidth;
                        canvas.height = (imgheight / imgwidth) * newWidth;
                        ctx.drawImage(
                        this, 0, 0, imgwidth, imgheight, 0, 0, newWidth, (imgheight / imgwidth) * newWidth
                        );

                            // We resize the image with the canvas method drawImage();
                            //ctx.drawImage(this, 0, 0, width, height);


                            var dataURI = canvas.toDataURL();

                            // This is the return of the Promise
                            resolve(dataURI);
                        };

                        // We put the Data URI in the image's src attribute
                        img.src = datas;

                        }).then(function(result) {

                       console.log(result)

                        $('#preview').attr('src', result);
                        $('#preview').attr('style', 'width: 100%');
                        localStorage.setItem("novedad_imagen",result)
                        
                                
                    })





                    };
                    reader.onerror = function (error) {
                    console.log('Error: ', error);
                    };
                });





            },200)


             $("#novedades").change(function(){

                    var identificador = $(this).val();

                    console.log(identificador)

                    if (identificador==15||identificador==173||identificador==19) {

                        $("#extras").slideDown();

                    }else{
                        $("#extras").slideUp();
                    }



                })




        }


function renderCalendar(asistencia, desde, hasta,nombre,dni,legajo,sector) {

 

    var startDate = parseDate(desde);
    var endDate = parseDate(hasta);
    var calendarHTML = '<div style="display: flex;">';
    var daysToShow = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

    for (let i = 0; i < daysToShow; i++) {
        var currentDay = new Date(startDate.getTime());  // Clonar la fecha de inicio
        currentDay.setDate(startDate.getDate() + i);
        
        // Buscar registro de asistencia para el día actual
        var dayAsistencia = asistencia.find(a => a.dia === currentDay.getDate());

        // Aplicar color basado en la asistencia
        var color = 'transparent';  // Color por defecto si no hay datos específicos
        if (dayAsistencia && dayAsistencia.asistencia === 'A') {
            color = 'red';  // Rojo si ausente
        
        // Añadir la celda del día al HTML del calendario
        calendarHTML += `<div style="width: 2em; height: 2em; border: 1px solid #ccc; background-color: ${color}; text-align: center;">${currentDay.getDate()}<img data-novedad="${nombre},${dni},${legajo},${sector}" data-toggle="modal" data-target=".nuevo" id="user-${dni}" class="editar" v-if="legales.asistencia[index].asistencia=='A'" src="https://findicons.com/files/icons/2813/flat_jewels/256/edit.png" width="20px"></div>`;


        }


 

        


setTimeout(function(){


        // Depuración: Mostrar en la consola los días y el color aplicado
        $("#user-"+dni).on("click",function(){
        var datos = $(this).data("novedad").split(",");
        var nombre = datos[0];
        var dni = datos[1];
        var legajo = datos[2];
        var sector = datos[3];

       

        /*const novedad=(nombre, dni, legajo, sector, index) => {
            alert(`Nombre: ${nombre}, DNI: ${dni}, Legajo: ${legajo}, Sector: ${sector}`);
          }
        */
        novedad(nombre, dni, legajo, sector)
        


        });


        },100)

       
       
    }




    calendarHTML += '</div>';
    return calendarHTML;
}


    function renderAccordion(data, desde, hasta) {
        var accordionHTML = '';
        var sectors = {};

        // Agrupar empleados por sector
        data.forEach(function(employee) {
            var sector = employee.sector;
            if (!sectors[sector]) {
                sectors[sector] = [];
            }
            sectors[sector].push(employee);
        });

        var contador = 0;
        // Crear un panel de acordeón para cada sector
        Object.keys(sectors).forEach(function(sector, index) {

if (sector!="ASIGNACIÓN TEMPORAL"&&
    sector!="JEFES REGIONALES"&&
    sector!="GERENTES"&&
    sector!="SECTOR ZONALES"&&
    sector!="PRE-LEGALES") {




            var sectorId = 'sector' + index;
            var empleados = sectors[sector];
            var ausentesCount = empleados.filter(emp => emp.asistencia.some(asist => asist.asistencia === 'A')).length;
            


            contador = contador + ausentesCount;
           
           

            if (ausentesCount!=0) {
                        accordionHTML += `
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading${sectorId}">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse${sectorId}" aria-expanded="true" aria-controls="collapse${sectorId}">
                                        ${sector} (${ausentesCount} ausentes)
                                    </button>
                                </h2>
                                <div id="collapse${sectorId}" class="accordion-collapse collapse" aria-labelledby="heading${sectorId}" data-bs-parent="#accordionPresentismo">
                                    <div class="accordion-body">
                                        <table width="100%" class="table">
                                            <tr><th>Nombre</th><th>Categoría</th><th>Legajo</th><th>Calendario de Asistencia</th></tr>
                                        `;

                        empleados.forEach(function(emp) {
                            
                            console.log("presente")
                            if(renderCalendar(emp.asistencia, desde, hasta,emp.nombre+' '+emp.apellido,emp.dni,emp.legajo,emp.sector)!='<div style="display: flex;"></div>'){
                                accordionHTML += `<tr id="user-${emp.legajo}"><td>${emp.nombre} ${emp.apellido}</td><td>${emp.categoria}</td><td>${emp.legajo}</td><td>${renderCalendar(emp.asistencia, desde, hasta,emp.nombre+' '+emp.apellido,emp.dni,emp.legajo,emp.sector)}</td></tr>`;
                            }
                            
                       
                        });

                        accordionHTML += `</table></div></div></div>`;


            }



}


        });




        $('#fecha').html(hasta)
        $('#accordionPresentismo').html(accordionHTML);
        $('#total').html(contador)
        $('#alerta').hide()
        


        
            
           
            $(".accordion-button").click(function() {
                var iden = $(this).attr("aria-controls");
                console.log(iden)
                $("#"+iden).toggle();
            });

        
    

    }


           
        },
        refrescarPersonal(){

// Función para agregar un cero si el número es menor que 10
const agregarCero=(i)=> {
  return (i < 10) ? "0" + i : i;
}

                $(".table-responsive").css("visibility","hidden");
                     $("#loading").show();
                     $("#alerta").slideDown();
                     $("#espera").hide()
          
                $('#empresas').dataTable().fnDestroy();
                $('#legales').dataTable().fnDestroy();
                var mesActual  = $('#mes option:selected').val();
                var anioActual = $('#anio option:selected').val();
                var diasMes = new Date(anioActual, mesActual, 0).getDate();

                  if (mesActual<10) {
                    mesActual = mesActual.replace("0","");
                  }

var desde = $('#desde').val();
var hasta = $('#desde').val();

console.log("CONSULTAR: "+desde)




                  
                 this.obtenerPersonal(diasMes,mesActual,anioActual,desde,hasta);
            
        },
    },
    mounted() {
        
        var today = new Date();
        var tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        
        var dayToday = ("0" + today.getDate()).slice(-2);
        var monthToday = ("0" + (today.getMonth() + 1)).slice(-2);
        var todayFormatted = today.getFullYear() + "-" + monthToday + "-" + dayToday;

        var dayTomorrow = ("0" + tomorrow.getDate()).slice(-2);
        var monthTomorrow = ("0" + (tomorrow.getMonth() + 1)).slice(-2);
        var tomorrowFormatted = tomorrow.getFullYear() + "-" + monthTomorrow + "-" + dayTomorrow;

        document.getElementById('desde').value = tomorrowFormatted;
        document.getElementById('hasta').value = tomorrowFormatted;


// Función para agregar un cero si el número es menor que 10
const agregarCero=(i)=> {
  return (i < 10) ? "0" + i : i;
}


const fecha = new Date();

const mesActual = fecha.getMonth() + 1; // Recuerda que getMonth() devuelve el mes comenzando desde 0
const anioActual = fecha.getFullYear();
var diasMes = new Date(anioActual, mesActual, 0).getDate(); // Días del mes actual



        var desde = $('#desde').val();
        var hasta = $('#desde').val();
        
           
// Convertir la cadena a un objeto Date
var desde = new Date(desde);
// Extraer el año, mes y día
var desde_anio = desde.getFullYear();
var desde_mes = agregarCero(desde.getMonth() + 1); // getMonth() devuelve un índice basado en cero, por lo tanto sumamos 1
var desde_dia = agregarCero(desde.getDate());

// Formatear la fecha en el formato deseado
var desde = desde_anio + '-' + desde_mes + '-' + desde_dia;



// Convertir la cadena a un objeto Date
var hasta = new Date(hasta);
// Extraer el año, mes y día
var hasta_anio = hasta.getFullYear();
var hasta_mes = agregarCero(hasta.getMonth() + 1); // getMonth() devuelve un índice basado en cero, por lo tanto sumamos 1
var hasta_dia = agregarCero(hasta.getDate());

// Formatear la fecha en el formato deseado
var hasta = hasta_anio + '-' + hasta_mes + '-' + hasta_dia;

      
        this.obtenerPersonal(diasMes,mesActual,anioActual,desde,hasta);


      
   

        

    },
};
</script>

<style scoped>

#espera {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    position: absolute;
    z-index: 999;
    font-size: 200%;
    font-weight: bold;
    display: none;
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}
@media (min-width: 992px)
.modal-lg, .modal-xl {
    max-width: 1059px !important;
    /* height: 360px; */
    padding-bottom: 116px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

table.dataTable thead>tr>td.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc {
    padding-right: 30px;
    background-color: #ffffff;
}

table.dataTable {
    clear: both;
    margin-top: 52px!important;
    margin-bottom: 6px!important;
    max-width: none!important;
    border-collapse: separate!important;
    border-spacing: 0;
}


table.dataTable tbody th, table.dataTable tbody td {
    padding: 8px 10px;
    background-color: #fff;
}

 th, td { white-space: nowrap; }
    div.dataTables_wrapper {
        width: 800px;
        margin: 0 auto;
    }

.td {
    
    border: 1px solid #e6e6e6;
}

.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#loading{
 
  width: 100%;
}

.table-responsive{
  visibility: hidden;
}

.editar{
    cursor: pointer;
}

input[type="file"] {
    display: block;
}
</style>

