<template>
    <div>
      <div class="row">
        <div class="col-5">
          <Placeholder/>
        </div>
        <div class="col-4">
          
        </div>
        <div class="col-3">
          <Placeholder/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr>
        </div>
      </div>
      <div class="row mb-2" v-for="i in 8" :key="i">
        <div class="col-2"><Placeholder/></div>
        <div class="col-3"><Placeholder/></div>
        <div class="col-3"><Placeholder/></div>
        <div class="col-4"><Placeholder/></div>
      </div>
    </div>
</template>

<script>
import Placeholder from './Placeholder.vue';
export default {
    name: "TablePlaceholder",
    components:{Placeholder},
}
</script>
