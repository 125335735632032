<template>
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          <span>FS</span> GENESIS
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">



          
          <li class="nav-item" v-if="isLector('Empresas')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#operaciones" role="button" aria-expanded="false" aria-controls="operaciones">
              <i class="link-icon" data-feather="layers"></i>
              <span class="link-title">Operaciones</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="operaciones">
              <ul class="nav sub-menu">
                <li class="nav-item" v-if="isLector('Empresas')==true">
            <router-link :to="{ name: 'Empresas'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Empresas</span>
            </router-link>
          </li>



          <li class="nav-item" v-if="isLector('Empresas')==true">
            <router-link :to="{ name: 'Sectores'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Sectores</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Empresas')==true">
            <router-link :to="{ name: 'Volantes'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Volantes</span>
            </router-link>
          </li>
              </ul>
            </div>
       </li>

          <li class="nav-item" v-if="isLector('Irregular')==true">
            <router-link :to="{ name: 'Irregulares'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">MO Irregular</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('evaluacionComedor')==true">
            <router-link :to="{ name: 'evaluacionComedor'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Evaluacion Comedor</span>
            </router-link>
          </li>



        <li class="nav-item" v-if="isLector('Personal')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#personal" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Personal</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="personal">
              <ul class="nav sub-menu">


              <li class="nav-item" v-if="isLector('Personal')==true">
                <router-link :to="{ name: 'Personal'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Personal</span>
                </router-link>
              </li>

             <li class="nav-item" v-if="isLector('Personal')==true">
                <router-link :to="{ name: 'Historico'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Ingresos - Egresos</span>
                </router-link>
              </li> 

              <li class="nav-item" v-if="isLector('Lockers')==true">
                <router-link :to="{ name: 'Lockers'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Lockers</span>
                </router-link>
              </li>


              <li class="nav-item" v-if="isLector('Bajas')==true">
                <router-link :to="{ name: 'Bajas'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Bajas de personal</span>
                </router-link>
              </li>

                <li class="nav-item" v-if="isLector('Orientacion')==true">
                <router-link :to="{ name: 'Orientacion'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Orientación</span>
                </router-link>
              </li>

               

              <li class="nav-item" v-if="isLector('GenesisReporteCapacitacion')==true">
                <router-link :to="{ name: 'TrainedByZonal'}" class="nav-link" >
                  <i class="link-icon" data-feather="award"></i>
                  <span class="link-title">Capacitaciones</span>
                </router-link>
              </li>

               <li class="nav-item" v-if="isLector('GenesisReporteCapacitacion')==true">
                <router-link :to="{ name: 'reporteCalidad'}" class="nav-link" >
                  <i class="link-icon" data-feather="award"></i>
                  <span class="link-title">Reporte Calidad</span>
                </router-link>
              </li>

               <li class="nav-item" v-if="isLector('Vacaciones')==true">
                <router-link :to="{ name: 'Vacaciones'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Vacaciones</span>
                </router-link>
              </li>
          
           </ul>
            </div>
       </li>


       <li class="nav-item" v-if="isLector('RequiHRBP')==true">
                      <router-link :to="{ name: 'asignacionTemporal'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Asignacion Temporal</span>
                      </router-link>
                    </li>


   <li class="nav-item" v-if="isLector('Novedades')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#novedad_down" role="button" aria-expanded="false" aria-controls="novedad_down">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Novedades</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="novedad_down">
              <ul class="nav sub-menu">

      

                    <li class="nav-item" v-if="isLector('Novedades')==true">
                      <router-link :to="{ name: 'Novedades'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Operaciones</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Novedades')==true">
                      <router-link :to="{ name: 'NovedadesHrbp'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">HRBP</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Novedades')==true">
                      <router-link :to="{ name: 'NovedadesBejerman'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bejerman</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Novedades')==true">
                      <router-link :to="{ name: 'HorasNocturnasPlanta'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Horas Noc. Planta</span>
                      </router-link>
                    </li>
                </ul>
            </div>
       </li>



       <li class="nav-item" v-if="isLector('Gastos')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#gastos_down" role="button" aria-expanded="false" aria-controls="novedad_down">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Gastos a pagar</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="gastos_down">
              <ul class="nav sub-menu">

      

                    
                     <li class="nav-item" v-if="isLector('GastosFacturacion')==true">
                      <router-link :to="{ name: 'NovedadesGastos'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Facturación</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('GastosHrbp')==true">
                      <router-link :to="{ name: 'NovedadesGastosHrbp'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">HRBP</span>
                      </router-link>
                    </li>

                    
                </ul>
            </div>
       </li>
    



   <li class="nav-item" v-if="isLector('Requisitoria')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#requi_down" role="button" aria-expanded="false" aria-controls="requi_down">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Requisitoria</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="requi_down">
              <ul class="nav sub-menu">

      

                    <li class="nav-item" v-if="isLector('RequiHRBP')==true">
                      <router-link :to="{ name: 'RequiHrbp'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja HRBP</span>
                      </router-link>
                    </li>

                    
                    <li class="nav-item" v-if="isLector('RequiLEGALES')==true">
                      <router-link :to="{ name: 'RequiLegales'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja Legales</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('RequiCOSTOS')==true">
                      <router-link :to="{ name: 'RequiCostos'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja Costos</span>
                      </router-link>
                    </li>


                    <li class="nav-item" v-if="isLector('RequiGERENCIA')==true">
                      <router-link :to="{ name: 'RequiGerencia'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja Gerencia</span>
                      </router-link>
                    </li>



                      <li class="nav-item" v-if="isLector('RequiRRHH')==true">
                      <router-link :to="{ name: 'RequiRRHH'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja RRHH</span>
                      </router-link>
                    </li>

                    <li class="nav-item" v-if="isLector('RequiSELECCION')==true">
                      <router-link :to="{ name: 'RequiSeleccion'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja Selección</span>
                      </router-link>
                    </li> 

                    <li class="nav-item" v-if="isLector('RequiDIRECTORIO')==true">
                      <router-link :to="{ name: 'RequiDirectorio'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bandeja Directorio</span>
                      </router-link>
                    </li> 




<!--
                    

                    
-->
                  

                   

                    


                </ul>
            </div>
       </li>






        <li class="nav-item" v-if="isLector('Presentismo')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#presentismo" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Presentismo</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="presentismo">
              <ul class="nav sub-menu">
                       <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'Presentismo'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Planta</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'MesPresente'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Pres. Mes</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'Ausentismo'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Ausentismo</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'MesPresenteZonal'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Pres. Zonales</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'MesPresenteTemporal'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Personal Temp.</span>
            </router-link>
          </li>

            <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'PresentismoArmado'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Pres. Armado</span>
            </router-link>
          </li>


          <li class="nav-item" v-if="isLector('Novedades')==true">
                      <router-link :to="{ name: 'semaforoSectores'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Semaforo Sectores</span>
                      </router-link>
                    </li>
              </ul>
            </div>
       </li>



         <li class="nav-item" v-if="isLector('PresentismoArmado')==true">
            <router-link :to="{ name: 'PresentismoArmado'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Pres. Armado</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('PresentismoGerentePlanta')==true">
            <router-link :to="{ name: 'PresentismoGerentePlanta'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Pres. Gerente Planta</span>
            </router-link>
          </li>
  

        




            <li class="nav-item" v-if="isLector('Liquidacion')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#liquidacion" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Liquidacion</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="liquidacion">
              <ul class="nav sub-menu">

                <!-- <li class="nav-item" v-if="isLector('Recibos')==true">
                      <router-link :to="{ name: 'Recibos'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Recibos</span>
                      </router-link>
                    </li>-->

                 <li class="nav-item" v-if="isLector('Liquidacion')==true">
                      <router-link :to="{ name: 'Liquidacion'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS America</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Liquidacion')==true">
                      <router-link :to="{ name: 'LiquidacionCat'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS Catering</span>
                      </router-link>
                    </li>



                  <li class="nav-item" v-if="isLector('Liquidacion')==true">
                      <router-link :to="{ name: 'AdelantoLiquidacion'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Adelantos FS Am.</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Liquidacion')==true">
                      <router-link :to="{ name: 'AdelantoLiquidacionCat'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Adelantos FS Cat.</span>
                      </router-link>
                    </li>


             </ul>
            </div>
             </li>


                 <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#liquidacion_z" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Liquidacion Zonales</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="liquidacion_z">
              <ul class="nav sub-menu">

               

                 <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
                      <router-link :to="{ name: 'Liquidacion_z'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS America</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
                      <router-link :to="{ name: 'LiquidacionCat_z'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS Catering</span>
                      </router-link>
                    </li>




             </ul>
            </div>
             </li>



                  <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#liquidacion_op" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Liquid. Operaciones</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="liquidacion_op">
              <ul class="nav sub-menu">

               

                 <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
                      <router-link :to="{ name: 'Liquidacion_op'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS America</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Liquidacion_z')==true">
                      <router-link :to="{ name: 'LiquidacionCat_op'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">FS Catering</span>
                      </router-link>
                    </li>




             </ul>
            </div>
             </li>



           <li class="nav-item" v-if="isLector('Hidalgo')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#hidalgo" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Capital Humano</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="hidalgo">
              <ul class="nav sub-menu">

                 <li class="nav-item" v-if="isLector('Hidalgo')==true">
                      <router-link :to="{ name: 'Hpersonal'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Personal</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('Hidalgo')==true">
                      <router-link :to="{ name: 'Hempresas'}" class="nav-link" >
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Operaciones</span>
                      </router-link>
                    </li>

              </ul>
            </div>
             </li>



               <li class="nav-item" v-if="isLector('Cartelera')==true">
            
            <a class="nav-link" data-toggle="collapse" href="#mifood" role="button" aria-expanded="false" aria-controls="personal">
              <i class="link-icon" data-feather="clock"></i>
              <span class="link-title">Mi Foodservice</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>

            <div class="collapse" id="mifood">
              <ul class="nav sub-menu">

                <li class="nav-item" v-if="isLector('Cartelera')==true">
                <router-link :to="{ name: 'Blog'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Cartelera</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="isLector('Cartelera')==true">
                <router-link :to="{ name: 'Preguntas'}" class="nav-link" >
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Preguntas Frecuentes</span>
                </router-link>
              </li>

              </ul>
            </div>
             </li>



          <li class="nav-item" v-if="isLector('Legales')==true">
            <router-link :to="{ name: 'Legales'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Legales</span>
            </router-link>
          </li>
    

           <li class="nav-item" v-if="isLector('Permisos')==true">
            <router-link :to="{ name: 'Permisos'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Permisos</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('GenesisClaves')==true">
            <router-link :to="{ name: 'Blanquear'}" class="nav-link" >
              <i class="link-icon" data-feather="key"></i>
              <span class="link-title">Claves</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('GenesisFamilia')==true">
            <router-link :to="{ name: 'Hijos'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Hijos</span>
            </router-link>
          </li>

           

           

           <li class="nav-item" v-if="isLector('Novedades')==true">
            <router-link :to="{ name: 'Solicitudbjm'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Solicitud de cambio | MF</span>
            </router-link>
          </li>

        <!--  <li class="nav-item" v-if="isLector('Mantenimiento')==true">
            <router-link :to="{ name: 'Mantenimiento'}" class="nav-link" >
              <i class="link-icon" data-feather="settings"></i>
              <span class="link-title">Mantenimiento</span>
            </router-link>
          </li> -->

          <li class="nav-item" v-if="isLector('Nomina')==true">
            <router-link :to="{ name: 'Nomina'}" class="nav-link" >
              <i class="link-icon" data-feather="users"></i>
              <span class="link-title">Nomina</span>
            </router-link>
          </li>



<!--
            <li class="nav-item">
            <router-link :to="{ name: 'Prospecto'}" class="nav-link">
              <i class="link-icon" data-feather="help-circle"></i>
              <span class="link-title">Prospectos</span>
            </router-link>
          </li> -->


          

          


           
        
        </ul>
      </div>
    </nav>
</template>

<script>


const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
    methods:{
     isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            // console.log(modulo+' : '+false)
            return false;
          }
      },
       mounted() {
       //this.isLector('Empresas');
    },
  }
};



</script>