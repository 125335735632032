<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!triggers.success">
                            <div class="col-10">Agregá una nueva asignacion</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>

                        <div class="modal-body m-0" v-if="triggers.success">
                            <h5 class="text-success text-center mt-1">✓ Asignacion creada</h5>
                        </div>

                        <div class="modal-body" v-if="!triggers.success">
                            
<div class="row mt-3">
    <div class="col">
                            <label for="selectEmpleado" class="form-label">Seleccioná un empleado</label>
                            <Select2 class="w-100 input-error" v-model="operacionSeleccionada" :options="listadoPersonalActivo" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @select="guardarSeleccion($event)"/>
                            <span class="text-danger" v-if="errors.dni">· Seleccioná un empleado</span>
    </div>

      <div class="col">
                                    <label for="sector">Seleccioná sector</label>
                                    <select class="custom-select mr-3 resolucionSelect" v-model="ticket.sector">
                                        <option value="-">-</option>
                                        <option value="OPERACION">OPERACION</option>
                                        <option value="PLANTA">PLANTA</option>
                                    </select>
                                </div>

   </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="novedad">¿Cual es la licencia reportada?</label>
                                    <select class="custom-select mb-1 resolucionSelect" v-model="ticket.novedad" @change="errors.novedad = false">
                                        <option value="null" selected disabled>Selecciona una licencia</option>
                                        <option v-for="(novedad,i) in novedades" :key="i" :value="novedad">{{novedad}}</option>
                                    </select>
                                    <span class="text-danger" v-if="errors.novedad">· Seleccioná una novedad</span>
                                </div>
                                <div class="col">
                                    <label for="novedad">Seleccioná el estado actual</label>
                                    <select class="custom-select mr-3 resolucionSelect" v-model="ticket.status">
                                        <option value="ABIERTO" selected>ABIERTO</option>
                                        <option value="CERRADO">CERRADO</option>
                                    </select>
                                </div>
                            </div>


<div class="row mt-3">

     <div class="col">
                            <!-- Fecha de inicio de la licencia -->
                            <label class="form-label mt-3">Fecha de inicio de la licencia</label>
                            <input type="date" class="form-control" v-model="ticket.fechaInicioLicencia" @change="errors.fechaInicioLicencia = false">
                            <span class="text-danger" v-if="errors.fechaInicioLicencia">· Seleccioná una fecha</span>
    </div>

     <div class="col">

                             <!-- Fecha de fin de la licencia -->
                            <label class="form-label mt-3">Fecha de fin de la licencia</label>
                            <input type="date" class="form-control" v-model="ticket.fechaFinLicencia" @change="errors.fechaFinLicencia = false">
                            <span class="text-danger" v-if="errors.fechaFinLicencia">· Seleccioná una fecha</span>
    </div>

</div>



<div class="row mt-3">

<div class="col">

                            <!-- Diagnóstico -->
                            <label class="form-label mt-3">Diagnóstico</label>
                             <select class="custom-select" v-model="ticket.diagnostico">
                                <option value="null" selected disabled>Selecciona un tipo</option>
                                <option value="Algia">Algia</option>
                                <option value="Traumatismo">Traumatismo</option>
                                <option value="Lesiòn">Lesiòn</option>
                                <option value="Quemadura">Quemadura</option>
                                <option value="Herida Cortante">Herida Cortante</option>
                                <option value="Sustancia Quìmica">Sustancia Quìmica</option>
                                <option value="Otros">Otros</option>
                            </select>
</div>

<div class="col">

                            <!-- Detalle -->
                            <label class="form-label mt-3">Detalle</label>
                            <input type="text" class="form-control" v-model="ticket.detalle" @change="errors.detalle = false" placeholder="Ingrese detalle ...">
                            <span class="text-danger" v-if="errors.detalle">· Ingresa el detalle</span>
</div>

<div class="col">
                            <!-- Tipo de accidente -->
                            <label for="tipoAccidente" class="form-label mt-3">Tipo de accidente</label>
                            <select class="custom-select" v-model="ticket.tipoAccidente">
                                <option value="null" selected disabled>Selecciona un tipo</option>
                                <option v-for="(tipo,i) in tipo" :key="i" :value="tipo">{{tipo}}</option>
                            </select>
</div>

</div>


<div class="row mt-3">

    <div class="col">
                             <!-- Responsable -->
                            <label for="responsable" class="form-label mt-3">Responsable</label>
                            <select class="custom-select" v-model="ticket.responsable">
                                <option value="null" selected disabled>Selecciona un responsable</option>
                                <option v-for="(responsable,i) in responsable" :key="i" :value="responsable">{{responsable}}</option>
                            </select>
                        </div>

                        <div class="col">

                            <!-- Adjuntar múltiples archivos -->
                            <label for="archivosAdjuntos" class="form-label mt-3">Adjuntar archivos</label>
                            <center><input type="file" class="form-control" multiple ref="fileInput"></center>
                            
                        </div>


</div>


                            <label class="form-label mt-2">Agregar una observacion</label>
                            <!-- Input -->
                            <textarea class="w-100 form-control mb-1" v-model="ticket.observacion" @change="errors.observacion = false" rows="3"></textarea>
                            <span class="text-danger" v-if="errors.observacion">· Es necesario detallar el ticket</span>

                            
                            



                            
                                               
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!triggers.success">
                            <button
                                type="button"
                                @click.prevent="crearTicket()"
                                class="btn btn-success"
                            >
                                Crear asignacion
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";

import 'firebase/storage';  // Importa Firebase Storage

import Select2 from 'vue3-select2-component';

export default {
    components: {Select2},
    name: "ModalLegales",
    props: ['personal'],
    data(){
        return {
            listadoPersonalActivo: [],
            novedades: [
                "ART",
                "ENFERMEDAD",
                "RESERVA DE PUESTO",
                "LICENCIA SIN SUELDO",
                "MATERNIDAD",
                "EXEDENCIA"
            ],

            tipo: [
                "-",
                "ACCIDENTE DE TRABAJO",
                "IN-ITINERE",
                "REAGRAVACION REINGRESO",
                "ENFERMEDAD PROFESIONAL"
               
            ],

            responsable: [
                "GONZALO",
                "MARIANO",
                "EDGARDO",
                "SOL",
                "LEGALES"
               
            ],
            triggers: {
                showResolucion: false,
                success: false,
            },
            ticket: {
                dni: null,
                distribucion:null,
                distribucionName:null,
                nombre: null,
                fecha: null,
                hora: null,
                zonal:null,
                responsable:null,
                novedad: null,
                observacion: null,
                resolucion: null,
                status: null,
                sector:null,
                fechaInicioLicencia: null, // Nuevo campo
                fechaFinLicencia: null, // Nuevo campo
                diagnostico: null, // Nuevo campo
                 detalle: null, // Nuevo campo
                tipoAccidente: null, // Nuevo campo
                archivosAdjuntos: [] // Nuevo campo,

            },
            errors: {
                fechaInicioLicencia: false,
                diagnostico: false,
                tipoAccidente: false,
                archivosAdjuntos: false,
            },
            seReasigno: false,
            
        }
    },
    mounted(){
        axios
        .get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
        .then(legalResponse=>{
            const legales = legalResponse.data;
            /**
             *  Select2 requiere un array [{id:1,text:nombre}]
             * 	Se mapea el response a traves de las claves del objeto JSON
             */			
            this.listadoPersonalActivo = Object.keys(this.personal).flatMap((key)=>{
                if(legales[this.personal[key].leg_numdoc] != null) {
                    return []
                } else {
                    return {
                        id: this.personal[key].leg_numero,
                        text: this.personal[key].leg_apellido + " " + this.personal[key].leg_nombre,
                        nombre: this.personal[key].leg_apellido + " " + this.personal[key].leg_nombre,
                        dni: this.personal[key].leg_numdoc
                    }
                }
            });						
        })
    },
    methods: {
        async guardarSeleccion({id, text, dni,nombre}){



            const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest2 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');

            await axios.all([axiosrequest1, axiosrequest2,axiosrequest3,axiosrequest4]).then(
            axios.spread((distribucion,operaciones,sectores,zonal) => {


                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }





                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        
                            if ( clave == id) {
                                zonales = {
                                    nombre: value.nombre,
                                    legajo: value.legajo
                                }                               
                            }
                        

                    });
                    return zonales;
                }


                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }


                
                    var legajo = JSON.parse(sessionStorage.getItem("personal"))[dni].leg_numero
                    
                    this.errors.dni = false;
                    this.ticket.dni = dni;
                    this.ticket.nombre = nombre;
                    this.ticket.distribucion = result_distribucion(legajo).operacion;
                    this.ticket.distribucionName=result_operacion(result_distribucion(legajo).operacion);
                    this.ticket.zonal=result_zonal(result_distribucion(legajo).operacion).nombre;


           
                   

            })).then(() => {
            console.log("distribucion ok")
            })
            .catch((e)=>{
                console.log(e);
            })
        


        },
        close(){
            this.$emit('cerrar-modal-nuevo',this.seReasigno);
        },
        obtenerFecha(){
            return new Date().toISOString().slice(0, 10);
        },
        obtenerHora(){
            var time = new Date();
            let current = ("0" + time.getHours()).slice(-2) + ":";
            current +=  ("0" + time.getMinutes()).slice(-2);
            return current
        },
        crearTicket(){
            if (!this.formularioValido()) {
                return null;
            }



    

    let files = this.$refs.fileInput.files;
    

    let uploadPromises = [];

    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let storageRef = firebase.storage().ref('asignaciones/' + file.name);  // Ruta en Firebase Storage
        let uploadTask = storageRef.put(file);

        uploadPromises.push(uploadTask.then(() => storageRef.getDownloadURL()));  // Guarda la promesa de obtener la URL
    }
    


            let nuevaAsignacion = firebase.database().ref('/asignaciontemporal').push({
                dni: this.ticket.dni,
                nombre: this.ticket.nombre,
                fecha: this.obtenerFecha(),
                hora: this.obtenerHora(),
                novedad: this.ticket.novedad,
                observacion: this.ticket.observacion,
                resolucion: this.ticket.resolucion,
                status: this.ticket.status,
                fechaInicioLicencia: this.ticket.fechaInicioLicencia,
                fechaFinLicencia: this.ticket.fechaFinLicencia,
                diagnostico: this.ticket.diagnostico,
                 detalle: this.ticket.detalle,
                tipoAccidente: this.ticket.tipoAccidente,
                distribucion: this.ticket.distribucion,
                distribucionName: this.ticket.distribucionName,
                zonal:this.ticket.zonal,
                responsable: this.ticket.responsable,
                plan:"-",
                sector:this.ticket.sector

                // Recuerda gestionar los archivos adjuntos si es necesario
            });

            nuevaAsignacion.then((data) => {
                let firebaseId = data.key;  // Aquí obtienes el ID único de Firebase
                console.log("ticket creado con ID:", firebaseId);

                // subir imagenes
                Promise.all(uploadPromises).then(urls => {

                     firebase.database().ref('/asignaciontemporal/'+firebaseId).update({
                        archivosAdjuntos: urls 
                    });

                }).catch(error => {
                console.log("Error al subir archivos: " + error.message);
                });
                //cierra subir imagenes

                firebase.database().ref('/asignaciontemporal/'+firebaseId).update({
                    fireId: firebaseId
                });


                this.triggers.success = true;
                this.seReasigno = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            }).catch(error => {
                if(error != null){
                    alert("Hubo un error: " + error.message);
                }
            });



        },
        formularioValido(){
            let valido = true;
            
            if (this.ticket.novedad == null || this.ticket.novedad == "") {
                this.errors.novedad = true;
                valido = false;
            }
            if (this.ticket.dni == null) {
                this.errors.dni = true;

                valido = false;
            }
            return valido;
        }
        
    }
}
</script>

<style scoped>

input[type="file"] {
    display: block;
}

h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 50%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>