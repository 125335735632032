<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
       

              <h6 class="card-title">Zonales que en los ultimos 4 dias no cargaron novedades</h6>
            <p class="card-description">
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-success">CARGADO</span>
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-danger">SIN CARGAR</span>
            </p>
            <HR/>

           <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-1"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-1'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-2"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-2'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-3"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-3'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-4"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-4'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

          



          </div>
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">



                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" id="buscar">Consultar Novedades</button>
                    </div>
                </div>
          


            <div class="table-responsive">
              <table
                id="novedades_hrbp"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Operación</span></th>

                    <th><span class="text-muted">Bejerman id</span></th>
                    <th><span class="text-muted">Novedad</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Horas</span></th>
                    
                    <th><span class="text-muted">Creado</span></th>
                    <th><span class="text-muted">Fecha Novedad</span></th>
                    <th><span class="text-muted">Desde</span></th>
                    <th><span class="text-muted">Hasta</span></th>
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Categoria</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Doc</span></th>
                    <th><span class="text-muted">Obs</span></th>
                    <th><span class="text-muted">Accion</span></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                        <td v-if="caso.status=='En espera'">
                            <span  class="badge text-uppercase badge-warning">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Resuelto'">
                            <span  class="badge text-uppercase badge-success">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Observado'">
                            <span  class="badge text-uppercase badge-info">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Rechazado'">
                            <span  class="badge text-uppercase badge-danger">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td :id="'key'+caso.fireId">{{ caso.legajo }}</td>
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_raiz }}</td>
                        <td class="text-sm text-wrap">{{ caso.bejerman_id }}</td>
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.detalle }}</td>
                        <td class="text-sm text-wrap">{{ caso.horas }}</td>
                        
                        <td class="text-sm text-wrap">{{ caso.fecha }}</td>
                        <td class="text-sm text-wrap">{{ caso.fecha_novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.desde }}</td>
                        <td class="text-sm text-wrap">{{ caso.hasta }}</td>
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.categoria }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                        <td class="text-sm text-wrap" v-if="caso.certificado!='-'"><img v-bind:src="caso.certificado" width="30px" v-on:click.stop.prevent="openWindow(caso.certificado)"></td>
                        <td class="text-sm text-wrap" v-else>-</td>

                        <td class="text-sm text-wrap" v-if="caso.observacion=='1'"> 

                          <i :data-fire="caso.fireId" onclick="localStorage.setItem('status_id',$(this).data('fire'))" class="feather icon-mail" @click="mensajes()" style="font-size: 20px;color: green;"></i>

                        </td>
                        
                        <td class="text-sm text-wrap" v-else> - </td>
                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="En espera" :selected="caso.status == 'En espera'">En espera</option>
                                <option value="Resuelto"  :selected="caso.status == 'Resuelto'">Resuelto</option>
                                <option value="Observado" :selected="caso.status == 'Observado'">Observado</option>
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                 <option value="Resuelto" :selected="caso.status == 'Resuelto'">Resuelto</option>
                                <option value="Observado" :selected="caso.status == 'Observado'">Observado</option>
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



 <!-- Extra large modal -->


<div class="modal fade observado_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
              

              <div class="form-group">
                    <label for="exampleFormControlTextarea1">Escribe la observación/detalle que explique el motivo del estado</label>
                    <textarea class="form-control" id="observacion" rows="5"></textarea>
                  </div>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guardaObservacion()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        <div id="historial"></div>


    </div>
  </div>
</div>

</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
const userData = JSON.parse(sessionStorage.getItem('userData'));

import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from '../ListaCarga';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
  name: "novedades",
  components: {ListaCarga},
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Novedades de Operaciones a HRBP",
    descripcion: "Listado de novedades en el personal ",
    novedades: [],
    zonales: [],
    load: true,

  }),
  methods: {

    b64toBlob(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(onsuccess);
    };

    img.src = b64;
},

openWindow(link) {


  this.b64toBlob(link,
    function(blob) {
        var url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
    }, function(error) {
        // handle error
    });

 
},

    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
           
            return status;
          }else{
            
            return false;
          }
        },


  getNovedad(){

  let hoy = new Date();
  let dieciseisDias = 1000 * 60 * 60 * 24 * 60;

  let resta = hoy.getTime() - dieciseisDias;
  let date = new Date(resta);

  let day = `${(date.getDate())}`.padStart(2,'0');
  let month = `${(date.getMonth()+1)}`.padStart(2,'0');
  let year = date.getFullYear();

     axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test_hrbp.json?orderBy=%22$key%22&limitToLast=3000&print=pretty")
      .then((response) => {
            var vue = this;
            var obs = 0;
            $.each(response.data, (id, item) => {
                let caso = item;
                var cert_c = '-';
                var certif_u = '-';
                if (caso != null) {

                  if (caso.observacion!=undefined) {
                   
                    obs = 1;
                  }else{
                    obs = 0;
                  }
                  

                    if (caso.certificado!=undefined) {
                
             
                       cert_c = caso.certificado;

                     }

                     if (item.certificado_update!=undefined) {
                        
                        $.each(item.certificado_update, function (clab, itb) {

                          certif_u = itb.certificado;
                          


                       });

                       cert_c = certif_u;
                      }
            
                  
                      if (caso.novedad!="Sin novedad") {





                              var fechaInicio = new Date(caso.desde).getTime();
                              var fechaFin    = new Date(caso.hasta).getTime();

                              var diff = fechaFin - fechaInicio;
                                  var bejerman = "-";
                                if (caso.bejerman_id) {
                                  bejerman = caso.bejerman_id;
                                }

                            if (bejerman!="B1982") {
                                vue.novedades.push({
                                    fireId: id,
                                    desde: caso.desde,
                                    categoria:localStorage.getItem("cat-"+localStorage.getItem(caso.legajo)),
                                    detalle: caso.detalle,
                                    fecha: caso.fecha,
                                    fecha_novedad: caso.fecha_novedad,
                                    hasta: caso.hasta,
                                    certificado:cert_c,
                                    legajo: caso.legajo,
                                    horas:caso.horas,
                                    nombre: caso.nombre,
                                    novedad: caso.novedad,
                                    bejerman_id: bejerman,
                                    operacion_raiz: caso.operacion_raiz,
                                    responsable: caso.responsable,
                                    responsable_dni: caso.responsable_dni,
                                    status: caso.status,
                                    observacion:obs,
                                    statusMensaje: caso.status,
                                    dias:(diff/(1000*60*60*24))+1
                                });


                                if (caso.desde==undefined) {
                                  console.log(id)
                                }

                              }




                  
                  }
                }
            });



            this.load = false;
      })
      .then(()=>{



            $('#novedades_hrbp').dataTable({
            "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            },
            "scrollX": false, 
            "search": {regex:true},
            dom: 'Bfrtip',
            responsive: false,
            nowrap: false,
            buttons: [
            'copy',
            'excel',
            'csv',
            'pdf',
            {
                text: 'En espera',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                  console.log("En espera")
                  var table = $('#novedades_hrbp').DataTable();
                     table.columns( 0 ).search( "En espera" ).draw();
                }
            },
             {
                text: 'Observado',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                   console.log("Observado")
                  var table = $('#novedades_hrbp').DataTable();
                    table.columns( 0 ).search( "Observado" ).draw();
                }
            },
             {
                text: 'Rechazado',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                   console.log("Rechazado")
                  var table = $('#novedades_hrbp').DataTable();
                    table.columns( 0 ).search( "Rechazado" ).draw();
                }
            }, {
                text: 'Resuelto',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                  console.log("Rechazado")
                  var table = $('#novedades_hrbp').DataTable();
                     table.columns( 0 ).search( "Resuelto" ).draw();
                }
            },
             {
                text: 'Todos',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                   console.log("Todos")
                  var table = $('#novedades_hrbp').DataTable();
                    table.columns( 0 ).search( "" ).draw();
                    table.columns( 6 ).search( "" ).draw();
                }
            }
            ]
            });


 const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades_hrbp').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })


      })

      },      
  

  /*  cambiarStatus(caso){
        let statusMensaje = (caso.statusMensaje == "Resuelto") ? "En espera" : "Resuelto"; 
        console.log('novedades_usuarios_test_hrbp/' + caso.fireId);
        firebase.database().ref('novedades_usuarios_test_hrbp/' + caso.fireId).update({
            status: statusMensaje,
        })
        .then(()=>{
            caso.status = !caso.status;
            caso.statusMensaje = statusMensaje;
        })
        .catch(e => {
            console.log(e);
        });
    }
  },*/

  cambiarStatus(event, caso){

            if (event.target.value=="Observado"||event.target.value=="Rechazado"||event.target.value=="En espera") {

            window.$('.modal').modal();
            localStorage.setItem("status_id",caso.fireId);
            localStorage.setItem("status_sms",event.target.value);

             caso.status = event.target.value;
             caso.statusMensaje = event.target.value;
            

            $("#historial").empty();
            }else{
         
                      firebase.database().ref('novedades_usuarios_test_hrbp/' + caso.fireId).update({
                          status: event.target.value,

                      })
                      .then(()=>{
                          caso.status = event.target.value;
                          caso.statusMensaje = event.target.value;
                      })
                      .catch(e => {
                          console.log(e);
                      });







                     firebase.database().ref('novedades_usuarios_test').push({
                        fecha: caso.fecha,
                        fecha_novedad:caso.fecha_novedad,
                        responsable: caso.responsable,
                        operacion_raiz:caso.operacion_raiz,
                        legajo: caso.legajo,
                        nombre: caso.nombre,
                        id_novedad: caso.bejerman_id,
                        novedad: caso.novedad,
                        bejerman_id: caso.bejerman_id,
                        detalle: caso.detalle,
                        status: event.target.value,
                        desde:caso.desde,
                        hasta:caso.hasta,
                        checking:1,
                        responsable_dni:caso.responsable_dni,
                        certificado:caso.certificado,
                        horas:caso.horas
                       }, function (error) {

                        if (error) {
                          console.log("Ocurrio un error! verifique su conexión a internet!")
                        } else {
                          console.log("datos cargados en novedades rrhh")
                        }

                      });


            }    
      },

      mensajes(){


            window.$('.modal').modal();
            $("#historial").empty();

                 axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test_hrbp.json?orderBy=%22$key%22&equalTo=%22"+localStorage.getItem('status_id')+"%22&print=pretty")
                .then((response) => {

                    $.each(response.data,function(key,value){
                        $.each(value.observacion,function(k,v){
                            $("#historial").prepend("<center><div style='width:90%;padding:5px;border:1px solid #d2cbcb;margin-top:5px;margin-bottom:5px;margin:5px;text-align: left;padding-left: 15px;'><span style='color:#716868;'><i>"+v.timestamp+"</i></span><br><b>"+v.nombre+":</b> "+v.detalle+"<br></div></center>");
                        })
                    })


                });
                     


          
      },

      guardaObservacion(){



        const addZero =(i) => {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

                var h = new Date();
                var hora = addZero(h.getHours());
                var minutos = ("0" + h.getMinutes()).substr(-2);
                var segundos = ("0" + h.getSeconds()).substr(-2);
                var horaActual = hora +':'+ minutos +':'+ segundos;

                var today = new Date();
                var m = today.getMonth() + 1;
                var mes = m < 10 ? "0" + m : m;
                var dia = today.getDate();
                dia = dia < 10 ? "0" + dia : dia;
                var fechaActual = today.getFullYear() + "-" + mes + "-" + dia;
                var timestamp = fechaActual + " " + horaActual;


           
                firebase.database().ref('novedades_usuarios_test_hrbp/' + localStorage.getItem('status_id')+'/observacion').push({
                    detalle: $("#observacion").val(),
                    timestamp:timestamp,
                    nombre:userData.leg_nombre+' '+userData.leg_apellido

                })
                .then(()=>{


                        firebase.database().ref('novedades_usuarios_test_hrbp/' + localStorage.getItem('status_id')).update({
                          status: localStorage.getItem('status_sms')

                        })
                        .then(()=>{
                         
                        })
                        .catch(e => {
                          console.log(e);
                        });

                  localStorage.removeItem('status_id');
                  $("#observacion").val("");

                    Swal.fire(
                                      "Observación creada correctamente!",
                                      "Su observación fue enviada al zonal que corresponda",
                                      "success"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                          //window.location.reload();
                                            
                                      },1000);

                })
                .catch(e => {
                    Swal.fire(
                                      "Ups!, Ocurrio un error",
                                      "Revise su conexión a internet y vuelva a intentar",
                                      "error"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                      },1000);
                });


              
      },

      

    },
  async mounted() {
     

            this.getNovedad();

           

          

          
              
              const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?orderBy=%22$key%22&equalTo=%22999991%22&print=pretty');

              const axiosrequest2 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');

          await axios.all([axiosrequest1, axiosrequest2]).then(axios.spread((zonales, personal) => { 
          
                  var zonal = [];
                  var personal_data = personal.data;



                  const fecha = (dias) =>{


                      var date_min = new Date();
                      date_min.setDate(date_min.getDate() - dias);
                      var mea_min = date_min.getMonth() + 1;
                      var mesea_min = mea_min < 10 ? "0" + mea_min : mea_min;
                      var diaea_min = date_min.getDate();
                      diaea_min = diaea_min < 10 ? "0" + diaea_min : diaea_min;
                      var fecha_min = date_min.getFullYear() + "-" + mesea_min + "-" + diaea_min;

                      $("#fecha-"+dias).html(fecha_min);

                      return fecha_min;

                  }


                  const result_personal = (legajo) => {

                      var personal = "";
                      $.each(personal_data, function(clave, value) {
                            
                          if (value.leg_numero == legajo) {

                              personal = value.leg_numdoc;
                          }
                      })
                      return personal;
                  }


                   const verificar = (dni,dias) => {
                      
                      axios.get("https://auth.foodservice.com.ar/?type=novedades_check&fecha="+fecha(dias)+"&responsable_dni="+dni+"&access_token=1234567")
                        .then((response) => {
                            
                            if (response.data==null) {
                              $("#dni-"+dni+"-"+dias).removeClass("badge-success")
                              $("#dni-"+dni+"-"+dias).addClass("badge-danger")
                            }
                           


                        });

                 
                     
                        
                  }

                   $.each(zonales.data,function(key,value){
                       $.each(value,function(k,v){
                          
                          zonal.push({"legajo":v.legajo,"nombre":v.nombre,"dni":result_personal(v.legajo)});
                          verificar(result_personal(v.legajo),1)
                          verificar(result_personal(v.legajo),2)
                          verificar(result_personal(v.legajo),3)
                          verificar(result_personal(v.legajo),4)

                      })
                    })
                    


            

                
                this.zonales = zonal;

   })).then(() => {});


  }
}
</script>
