<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="!trigger.list"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="childs" class="table">
                              <thead>
                                <tr>
                                  <th>Empleado</th>
                                  <th>Legajo</th>
                                  <th>Hijo</th>
                                  <th>Edad</th>
                                  <th>CUIL</th>
                                  <th>Nivel educativo</th>
                                  <th>Escuela</th>
                                  <th>Grado</th>
                                  <th>Última actualización</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(child,c) in data" v-bind:key="c">
                                    <td>{{capitalizeEacheR(child.parent.name)}}</td>
                                    <td><div class="badge badge-light">{{child.parent.legajo}}</div></td>
                                    <td>{{child.surname}} <br> {{child.name}}</td>
                                    <td>{{get_age(child.birthdate)}}</td>
                                    <td>{{child.cuil}}</td>
                                    <td>{{(child.school_level) ? child.school_level : 'Sin asignar'}}</td>
                                    <td>{{child.school}}</td>
                                    <td>{{child.grade}}</td>
                                    <td>
                                      <div class="badge badge-light">{{(child.last_update) ? dateFormateR(child.last_update) : 'Sin fecha'}}</div>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

import {capitalizeEach, dateFormat} from '@/utils'

// import firebase from "firebase/app";
// import "firebase/database";
// let fireAplicativos = firebase.apps[1];

export default {
    name: 'Hijos',
    components: {ListaCarga},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Familia',
            descripcion: 'Lista de hijos del personal',
            triggers: {load:true},

            data:null,

            personal:null,
            childs:null,

            trigger:{list:false}
        }
    },
    methods: {
        capitalizeEacheR(s){return capitalizeEach(s)},
        get_age(birthdate) {
            const birth = new Date(birthdate)
            const now = new Date()
            const diff = new Date(now.valueOf() - birth.valueOf())
            return Math.abs(diff.getFullYear() - 1970)
        },
        dateFormateR(d){return dateFormat(d)}
    },
    mounted() {
        let promises = [];
        
        promises.push(
            axios.get('https://aplicativos.firebaseio.com/hijos_personal.json').then((response)=>{
              this.childs = response.data;
            })
            // getDataFrom('https://aplicativos.firebaseio.com/hijos_personal.json').then((data)=>{
            //     // console.log(response.data);
            // })
        );

        promises.push(
          axios.get('https://apprrhh-707b9.firebaseio.com/personal.json').then((r)=>{
            if(this.personal==null) this.personal = {}
            Object.keys(r.data).forEach((d)=>{
                this.personal[r.data[d].leg_numdoc] = {
                    name: r.data[d].leg_apellido + " " + r.data[d].leg_nombre,
                    dni: d,
                    legajo:  r.data[d].leg_numero
                }
            })
          })
        );

        promises.push(
          axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json').then((r)=>{
            if(this.personal==null) this.personal = {}
            Object.keys(r.data).forEach((d)=>{
                this.personal[r.data[d].leg_numdoc] = {
                    name: r.data[d].leg_apellido + " " + r.data[d].leg_nombre,
                    dni: d,
                    legajo:  r.data[d].leg_numero
                }
            })
          })
        );

        Promise.all(promises).then(()=>{
            // console.log(this.childs);
            // console.log(this.personal);
            this.data = {}
            Object.keys(this.childs).forEach((p_dni)=>{
                let parent = this.personal[p_dni];
                
                Object.keys(this.childs[p_dni]).forEach((c)=>{
                    this.data[c] = this.childs[p_dni][c];
                    this.data[c]['parent'] = parent;
                });


            });

            this.trigger.list = true;

            this.$nextTick(()=>{
                $("#childs").dataTable().fnDestroy();
                $("#childs").DataTable({
                    language: {
                    decimal: ",",
                    thousands: ".",
                    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
                    infoPostFix: "",
                    infoFiltered: "(filtrado de un total de _MAX_ registros)",
                    loadingRecords: "Cargando...",
                    lengthMenu: "Mostrar _MENU_ registros",
                    paginate: {
                        first: "Primero",
                        last: "Último",
                        next: "Siguiente",
                        previous: "Anterior",
                    },
                    processing: "Procesando...",
                    search: "Buscar:",
                    searchPlaceholder: "Término de búsqueda",
                    zeroRecords: "No se encontraron resultados",
                    emptyTable: "Ningún dato disponible en esta tabla",
                    aria: {
                        sortAscending:
                        ": Activar para ordenar la columna de manera ascendente",
                        sortDescending:
                        ": Activar para ordenar la columna de manera descendente",
                    },
                    },
                    scrollX: false,
                    search: { regex: true },
                    dom: "Bfrtip",
                    responsive: false,
                    nowrap: false,
                    buttons: ["copy", "excel", "csv", "pdf"],
                });
            })

        })
    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;
}
</style>