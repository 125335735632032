export function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeEach(string){
    if(string==undefined) return ""
    let r = "";
    string.split(' ').flatMap(w => {
        if(w!="" && w.length){
            r+=capitalize(w.trim()) + " ";
        }
    });
    return r;
}

/**
 * Convierte la fecha dada al formato utilizado en Argentina DD-MM-AAAA
 * @param {String} initDate fecha para transformar, puede ser un Date o un string con fecha valida
 * @returns String fecha formateada
 */
 export function dateFormat(initDate,includeYear){
    let date = new Date(initDate)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year;
    if (includeYear==undefined) year = date.getFullYear()

    if(month < 10){
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
    }else{
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
    }
}


import {sources} from './env'
import $ from "jquery";
import Swal from "sweetalert2";
export function getDataFrom(query,api){
    return new Promise((resolve,reject)=>{
        let old_data = sessionStorage.getItem('__'+query);
        if(old_data!=null){
            let time = sessionStorage.getItem('__time__'+query);
            // console.log('tiem',getHoursDiff(Date.now(),parseInt(time)));
            if(time!=null && getHoursDiff(Date.now(),parseInt(time)) <= 1){
                console.info(`%c${query} obtenido desde sessionStorage`,'color:#0f0')
                resolve(JSON.parse(old_data))
            }
        }

        console.log("dato")
        console.log(api)
        resolve(setDataFrom(query,api))

        // let url = (api!=undefined) ? `${sources[api]}/${query}.json` : query;

        // fetch(url).then((r)=>r.json())
        // .then((data)=>{
        //     sessionStorage.setItem('__'+query, JSON.stringify(data))
        //     sessionStorage.setItem('__time__'+query, Date.now())
        //     resolve(data)
        // }).catch((e)=>{
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: `Ocurrio un error al obtener información!`,
        //         footer: '<a href="mailto:desarrollo@foodservice.com.ar" target="_blank">Contactar con sistemas</a>'
        //     })
        //     console.error(e)
        //     reject(e);
        // })
    })
}

export function setDataFrom(query,api){
    return new Promise((resolve,reject)=>{

        // Clean params
        query = (query.at(0)=="/")?query.substr(1):query

        let url = (api!=undefined) ? `${sources[api]}/${query}.json` : query;

        fetch(url).then((r)=>r.json())
        .then((data)=>{
            sessionStorage.setItem('__'+query, JSON.stringify(data))
            sessionStorage.setItem('__time__'+query, Date.now())
            resolve(data)
        }).catch((e)=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Ocurrio un error al obtener información!`,
                footer: '<a href="mailto:desarrollo@foodservice.com.ar" target="_blank">Contactar con sistemas</a>'
            })
            console.error(e)
            reject(e);
        })
    })
}

function getHoursDiff(startDate, endDate) {
    let _start = new Date(startDate)
    let _end = new Date(endDate)

    // console.log('_start',_start);
    // console.log('_end',_end);

    const msInHour = 1000 * 60 * 60

    return Math.round(
        Math.abs(_end.getTime() - _start.getTime()) / msInHour,
    )
}

