<template>
	<div class="page-content">
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<lista-carga v-if="loading"></lista-carga>
		<!-- Template content  -->
		<div class="row" v-else>
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table id="empresas" class="table" width="100%">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Tipo</th>
									<th>Razon social</th>
									<th>Centro Apropiación</th>
									<th>Cod. Costos</th>
									<th>Cod. Cuenta Corriente</th>
									<th>Domicilio</th>
									<th>Localidad</th>
									<th>Provincia</th>
									<th>Coordenadas</th>
									
									
								</tr>
							</thead>
							<tbody>
								<tr v-for="operacion in operaciones" v-bind:key="operacion.id">
									<td>{{operacion.name}}</td>
									<td>{{operacion.tipo_empresa}}</td>
									<td v-if="operacion.razonsocial=='Food Service America'"><span class="badge badge-success">{{operacion.razonsocial}}</span></td>
									<td v-else><span class="badge badge-info">{{operacion.razonsocial}}</span></td>
									<td>{{operacion.centroapropiacion_id}}</td>
									<td>{{operacion.codigo_costos}}</td>
									<td>{{operacion.codctacte}}</td>
									<td>{{operacion.domicilio}}</td>
									<td>{{operacion.localidad}}</td>
									<td>{{operacion.provincia}}</td>
									<td>{{operacion.coordenadas}}</td>
									
									
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			</div>
		</div>
		
        <modal-administrar
            v-if="modalAdministrar" 
            :empresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-asignar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalAdministrar=false"
        >
        </modal-administrar>

		<modal-observar
			v-if="modalObservar" 
            :idEmpresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-observar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalObservar=false"
		></modal-observar>

		<modal-zonal
		v-if="modalZonal"
		:empresa="modalOperacionId"
		:nombreEmpresa="modalOperacionNombre"
		@cerrar-modal-zonal="refrescarEmpresas($event)"
		></modal-zonal>
	</div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import ListaCarga from "../ListaCarga";
import ModalZonal from './ModalZonal.vue';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: { 
        ModalAdministrar, 
        ListaCarga ,
		ModalObservar,
        ModalZonal
    },    
	name: 'Empresas',
   
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Empresas',
            descripcion: 'En este modulo usted podra visualizar y administrar personal en todas nuestras operaciones',
			loading: true,
            operaciones: [],
			modalOperacionId: null,
			modalOperacionNombre: null,
			modalAdministrar: false,
			modalObservar: false,
			modalZonal: false,
        }
    },

    methods: {
    	isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      	},
		administrarOperacionId(id, nombre, modal){
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				if (modal == 'personal') {
					this.modalAdministrar = true;
				} else if (modal=='zonal') {
					this.modalZonal = true;
				}
			}, 200);
		},
		observarOperacionId(id, nombre){
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				this.modalObservar = true;
			}, 200);
		},
		async obtenerInformacionEmpresas(){
			
			this.loading = true;

			var lunes = new Date();
			lunes.setDate(lunes.getDate() - 5);
			var mma = lunes.getMonth() + 1;
			var mesma = mma < 10 ? "0" + mma : mma;
			var diama = lunes.getDate();
			diama = diama < 10 ? "0" + diama : diama;
			var pasado = lunes.getFullYear() + "-" + mesma + "-" + diama;
			

			var fecha1 = new Date(pasado);
			var dias1 = (fecha1.getDay() - 1) * -1;

			var dias1b = (fecha1.getDay() + 1) * -1-1;

			fecha1.setDate(fecha1.getDate() + dias1);
			var mex = fecha1.getMonth() + 1;
			var mesex = mex < 10 ? "0" + mex : mex;
			var diaex = fecha1.getDate();
			diaex = diaex < 10 ? "0" + diaex : diaex;
			var lunespasadob = fecha1.getFullYear() + "-" + mesex + "-" + diaex;
			var dia1 = fecha1.getFullYear() + "-" + mesex + "-" + diaex;

			var fecha2 = new Date(lunespasadob);
			fecha2.setDate(fecha2.getDate() + 5);
			var mmax = fecha2.getMonth() + 1;
			var mesmax = mmax < 10 ? "0" + mmax : mmax;
			var diamax = fecha2.getDate();
			diamax = diamax < 10 ? "0" + diamax : diamax;
			var dia2 = fecha2.getFullYear() + "-" + mesmax + "-" + diamax;



fecha1.setDate(fecha1.getDate() + dias1b);
var mexb = fecha1.getMonth() + 1;
var mesexb = mexb < 10 ? "0" + mexb : mexb;
var diaexb = fecha1.getDate();
diaexb = diaexb < 10 ? "0" + diaexb : diaexb;
var lunespasadobb = fecha1.getFullYear() + "-" + mesexb + "-" + diaexb;
var dia1b = fecha1.getFullYear() + "-" + mesexb + "-" + diaexb;


var fecha2 = new Date(lunespasadobb);
fecha2.setDate(fecha2.getDate() + 5);
var mmaxb = fecha2.getMonth() + 1;
var mesmaxb = mmaxb < 10 ? "0" + mmaxb : mmaxb;
var diamaxb = fecha2.getDate();
diamaxb = diamaxb < 10 ? "0" + diamaxb : diamaxb;
var dia2b = fecha2.getFullYear() + "-" + mesmaxb + "-" + diamaxb;




	var ayerx = new Date();
	ayerx.setDate(ayerx.getDate() - 1);
	var mmax = ayerx.getMonth() + 1;
	var mesmax = mmax < 10 ? "0" + mmax : mmax;
	var diamax= ayerx.getDate();
	diamax = diamax < 10 ? "0" + diamax : diamax;
	var ayer = ayerx.getFullYear() + "-" + mesmax + "-" + diamax;

console.log(dia1)
console.log(dia2)
console.log("------------------------------")
console.log(dia1b)
console.log(dia2b)

		const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
		const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
		const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
        const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/observacion.json?print=pretty');
        const axiosrequest6 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+dia1+'/'+dia2);
        const axiosrequest7 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+dia1b+'/'+dia2b);
        const axiosrequest8 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+ayer+'/'+ayer);
		const axiosPersonal = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
        


		await axios.all([axiosrequest1, axiosrequest2, axiosrequest4, axiosrequest5,axiosrequest6,axiosrequest7,axiosPersonal,axiosrequest8]).then(axios.spread((operaciones, zonales, distribucion, observaciones,servicios,serviciosb,personal,serviciosc) => {	
				
				
				/**
				 * Se recorre el personal y comprueba si el cat_codigo es uno de part time
				 * Si lo es, en un array guarda true a partir de la clave legajo.
				 * Por ejemplo ["4658"] = true. Así se obtiene una complejidad O(n)
				 */
				var empleadosPartTime = [];
				$.each(personal.data,function(clave,item){ 
					
					let cat = item.cat_codigo;
				
					// Busqueda de legajos con las categorias: 65,77, 78, 79, 80, 81, 82
					if (cat == "65" || cat == "77" || cat == "78" || cat == "79" || cat=="80" || cat == "81" || cat == "82") {
	
						empleadosPartTime[item.leg_numero.toString()] = true
					}
				})
				
				
				var services = [];
				var servicesb = [];
				var servicesc = [];

				let jsons = Object.assign(servicios.data[0].CAT, servicios.data[1].SAL)

				// console.log(jsons)
			
				$.each(jsons,function(clave,valor){				
					if(valor.Servicios.VIANDAS!=undefined){
						services.push({
							"cantidad": valor.Servicios.VIANDAS,
							"cantidad_categoria": valor.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.ALMUERZO!=undefined){
						services.push({
							"cantidad": valor.Servicios.ALMUERZO,
							"cantidad_categoria": valor.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.CENA!=undefined){
						services.push({
							"cantidad": valor.Servicios.CENA,
							"cantidad_categoria": valor.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.DESAYUNOS!=undefined){

						services.push({
							"cantidad": valor.Servicios.DESAYUNOS,
							"cantidad_categoria": valor.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valor.Servicios.DESAYUNOS!=undefined){

						services.push({
							"cantidad": valor.Servicios.DESAYUNOS,
							"cantidad_categoria": valor.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valor.Servicios.MERIENDA!=undefined){

						services.push({
							"cantidad": valor.Servicios.MERIENDA,
							"cantidad_categoria": valor.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});



/* ------ 2 SEMANAS ATRAS  ------- */


	let jsonsb = Object.assign(serviciosb.data[0].CAT, serviciosb.data[1].SAL)

				// console.log(jsons)
			
				$.each(jsonsb,function(claveb,valorb){				
					

					if(valorb.Servicios.VIANDAS!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.VIANDAS,
							"cantidad_categoria": valorb.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.ALMUERZO!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.ALMUERZO,
							"cantidad_categoria": valorb.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.CENA!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.CENA,
							"cantidad_categoria": valorb.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.DESAYUNOS!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.DESAYUNOS,
							"cantidad_categoria": valorb.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorb.Servicios.DESAYUNOS!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.DESAYUNOS,
							"cantidad_categoria": valorb.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorb.Servicios.MERIENDA!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.MERIENDA,
							"cantidad_categoria": valorb.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});

			
		/* ------ CIERRA 2 SEMANAS ATRAS  ------- */		



/* ------ AYER   ------- */


	let jsonsc = Object.assign(serviciosc.data[0].CAT, serviciosc.data[1].SAL)

				
			
				$.each(jsonsc,function(clavec,valorc){				
					

					if(valorc.Servicios.VIANDAS!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.VIANDAS,
							"cantidad_categoria": valorc.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}

					if(valorc.Servicios.ALMUERZO!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.ALMUERZO,
							"cantidad_categoria": valorc.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valorc.Servicios.CENA!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.CENA,
							"cantidad_categoria": valorc.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valorc.Servicios.DESAYUNOS!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.DESAYUNOS,
							"cantidad_categoria": valorc.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorc.Servicios.DESAYUNOS!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.DESAYUNOS,
							"cantidad_categoria": valorc.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorc.Servicios.MERIENDA!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.MERIENDA,
							"cantidad_categoria": valorc.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});

			
		/* ------ CIERRA AYER  ------- */		






			var lista = [];

			var zonal = "-";
			var almuerzo = 0;
			var cena = 0;
			var vianda = 0;
			var desayuno = 0;
			var merienda = 0;
			var personal = 0;
			var num = 0;
			var status = "-";
			var observacion = false;
			var legales = false;


			var almuerzob = 0;
			var cenab = 0;
			var viandab = 0;



			var almuerzo_a = 0;
			var cena_a = 0;
			var vianda_a = 0;
			var desayuno_a = 0;
			var merienda_a = 0;

			var almuerzo_ab = 0;
			var cena_ab = 0;
			var vianda_ab = 0;


			var almuerzoc = 0;
			var cenac = 0;
			var viandac = 0;
			var almuerzo_ac = 0;
			var cena_ac = 0;
			var vianda_ac = 0;

		// console.log(operaciones.data);
		$.each(operaciones.data, function(clave, valor) {

			

			var acv = 0;
			var acvb = 0;
			var acvc = 0;

			var key_zonal = Object.keys(zonales.data).filter(key => key == valor.id);

			var result_zonal = key_zonal.reduce((r, k) => r.concat(zonales.data[k]), []);
			if (key_zonal.length!=0) {
				zonal = result_zonal[0].nombre;
			}else{
				zonal = '-';
			}




			var key_distribucion = Object.keys(distribucion.data).filter(key => key == valor.id);

			if (distribucion.data[key_distribucion[0]] != null) {
				// Si la operación tiene personal distribuido. Se cuentan los part-time
				let cantPersonal = 0;
				Object.keys(distribucion.data[key_distribucion[0]]).forEach((i) => {
					let legajo = distribucion.data[key_distribucion[0]][i].legajo;
					// Si el legajo esta en el array EmpleadosPArtTime se suma 0.5
					if (empleadosPartTime[legajo] != undefined){
						cantPersonal += 0.5;
					}else{
						cantPersonal += 1;
					}
					//cantPersonal += (empleadosPartTime[legajo] != undefined) ? 0.5 : 1;
				});
				personal = cantPersonal;
			}else{
				personal = 0;
			}

			var consumo = services.filter((el) => el.empresa_id == valor.id);
			var consumob = servicesb.filter((el) => el.empresa_id == valor.id);
			var consumoc = servicesc.filter((el) => el.empresa_id == valor.id);


			/* --- CONSUMOS --- */
			

			// console.log(consumo);
			if (valor.estado=='ACTIVA') {

				/* --- CONSUMOS --- */
				var result_almuerzo = consumo.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzo.length != 0) {
					almuerzo = result_almuerzo[0].cantidad_categoria;
					almuerzo_a = Math.round(result_almuerzo[0].cantidad_categoria/5);
				}else{
					almuerzo = 0;
					almuerzo_a = 0;
				}

				var result_cena = consumo.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cena.length != 0) {
					cena = result_cena[0].cantidad_categoria;
					cena_a = Math.round(result_cena[0].cantidad_categoria/5);
				}else{
					cena = 0;
					cena_a = 0;
				}

				var result_viandas = consumo.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandas.length != 0) {
					vianda = result_viandas[0].cantidad_categoria;
					vianda_a = Math.round(result_viandas[0].cantidad_categoria/5);
				}else{
					vianda = 0;
					vianda_a = 0;
				}


				/*----  2 SEMANAS ANTERIOR ----- */

				/* --- CONSUMOS --- */
				var result_almuerzob = consumob.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzob.length != 0) {
					almuerzob = result_almuerzob[0].cantidad_categoria;
					almuerzo_ab= Math.round(result_almuerzob[0].cantidad_categoria/5);
				}else{
					almuerzob = 0;
					almuerzo_ab = 0;
				}

				var result_cenab = consumob.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cenab.length != 0) {
					cenab = result_cenab[0].cantidad_categoria;
					cena_ab = Math.round(result_cenab[0].cantidad_categoria/5);
				}else{
					cenab = 0;
					cena_ab = 0;
				}

				var result_viandasb = consumob.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandasb.length != 0) {
					viandab = result_viandasb[0].cantidad_categoria;
					vianda_ab = Math.round(result_viandasb[0].cantidad_categoria/5);
				}else{
					viandab = 0;
					vianda_ab = 0;
				}

				/*----  2 SEMANAS ANTERIOR ----- */



					/*----  AYER ----- */

				/* --- CONSUMOS --- */
				var result_almuerzoc = consumoc.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzoc.length != 0) {
					almuerzoc = result_almuerzoc[0].cantidad_categoria;
					almuerzo_ac= Math.round(result_almuerzoc[0].cantidad_categoria);
				}else{
					almuerzoc = 0;
					almuerzo_ac = 0;
				}

				var result_cenac = consumoc.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cenac.length != 0) {
					cenac = result_cenac[0].cantidad_categoria;
					cena_ac = Math.round(result_cenac[0].cantidad_categoria);
				}else{
					cenac = 0;
					cena_ac = 0;
				}

				var result_viandasc = consumoc.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandasc.length != 0) {
					viandac = result_viandasc[0].cantidad_categoria;
					vianda_ac = Math.round(result_viandasc[0].cantidad_categoria);
				}else{
					viandac = 0;
					vianda_ac = 0;
				}

				/*----  2 AYER ----- */


				var result_desayuno = consumo.filter(function(c) {
					return c.categoria == "DESAYUNOS"
				});
				if (result_desayuno.length != 0) {
					desayuno = result_desayuno[0].cantidad_categoria;
					desayuno_a = Math.round(result_desayuno[0].cantidad_categoria/5);
				}else{
					desayuno = 0;
					desayuno_a = 0;
				}


				
				var result_merienda = consumo.filter(function(c) {
					return c.categoria == "MERIENDA"
				});
				if (result_merienda.length != 0) {
					merienda = result_merienda[0].cantidad_categoria;
					merienda_a = Math.round(result_merienda[0].cantidad_categoria/5);
				}else{
					merienda = 0;
					merienda_a = 0;
				}







				acv = parseInt(almuerzo_a) + parseInt(cena_a) + parseInt(vianda_a);
				acvb = parseInt(almuerzo_ab) + parseInt(cena_ab) + parseInt(vianda_ab);
				acvc = parseInt(almuerzo_ac) + parseInt(cena_ac) + parseInt(vianda_ac);
				/* --- CONSUMOS --- */
				acv = Math.round(acv);
				acvb = Math.round(acvb);
				acvc = Math.round(acvc);
				/* --- CONSUMOS --- */
				

				/* --  MANO DE OBRA  --*/
				

				if(valor.tipo_empresa=="COMEDOR"){



						if (acv < 300) {
							num = Math.round(acv / 40);
						}

						if (acv > 300) {
							num = Math.round(acv / 50);
						}



				}

				if(valor.tipo_empresa=="CATERING"){

						num = Math.round(acv / 70);

						

				}



				if (personal > num) {
					status = "Irregular";
				}

				if (personal < num) {

					status = "Correcto";
				}

				if (personal == num) {
					status = "Observar";
				}



				/* --  MANO DE OBRA  --*/

				// Observacion
				observacion = (observaciones.data[valor.id] != undefined) ? true : false;

				var chart = "igual";
				if (acv>acvb) {
					chart = "mayor"
				}

				if (acv<acvb) {
					chart = "menor"
				}

					lista.push({
						"id_consumos": valor.id,
						"razonsocial": valor.empresa_madre,
						"codctacte": valor.codctacte,
						"domicilio": valor.direccion,
						"localidad": valor.localidad,
						"provincia": valor.provincia,
						"id": valor.id,
						"centroapropiacion_id": valor.catering_id,
						"codigo_costos": valor.codigo_costos,
						"name": valor.name,
						"tipo_empresa": valor.tipo_empresa,
						"zonal": zonal,
						"almuerzo": almuerzo_a,
						"cena": cena_a,
						"vianda": vianda_a,
						"desayuno": desayuno_a,
						"merienda": merienda_a,
						"acv": acv,
						"acvb": acvb,
						"ayer": acvc,
						"chart":chart,
						"personal": personal,
						"manoobra":status,
						"observacion":observacion,
						"coordenadas":valor.coordenadas
	                });

                }


            });
			// console.log(lista);
            this.operaciones = lista;


		})).then(() => {
			this.loading = false;
			this.$nextTick(()=>{
				console.log("hola mundo")


				
				$('#empresas').DataTable({
					"language": {
						"decimal": ",",
						"thousands": ".",
						"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
						"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
						"infoPostFix": "",
						"infoFiltered": "(filtrado de un total de _MAX_ registros)",
						"loadingRecords": "Cargando...",
						"lengthMenu": "Mostrar _MENU_ registros",
						"paginate": {
							"first": "Primero",
							"last": "Último",
							"next": "Siguiente",
							"previous": "Anterior"
						},
						"processing": "Procesando...",
						"search": "Buscar:",
						"searchPlaceholder": "Término de búsqueda",
						"zeroRecords": "No se encontraron resultados",
						"emptyTable": "Ningún dato disponible en esta tabla",
						"aria": {
							"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
							"sortDescending": ": Activar para ordenar la columna de manera descendente"
						},
					},
					"scrollX": true, 
					"search": {regex:true},
					dom: 'Bfrtip',
					responsive: true,
					nowrap: false,
					 "order": [
                            [7, "desc"]
                        ],
					buttons: [
						'copy',
						'excel',
						'csv',
						'pdf'
					]
				}).columns.adjust().draw();
			})
		});

		},
		refrescarEmpresas(seReasigno){
            this.modalAdministrar = false;
            this.modalObservar = false;
			this.modalZonal = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerInformacionEmpresas();
            }
        },
    },
	async mounted() {
		this.obtenerInformacionEmpresas();		
    },
}

</script>

<style scoped>
.observacion-existente {
	background: #10b759;
	border-radius: 50%;
	padding: 3px 4px;
	padding-top: 4px;
	width: 16px;
	height: 16px;
	position: absolute;
	transform: translate(52px, -20px);
	font-size: 12px;
}
</style>