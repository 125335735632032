<template>
	<div class="page-content">
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>






				<table v-if="isLector('Irregular_empresas')==true" width="100%"><td align="right">

					<button style="" class="btn btn-primary mr-2" id="cerrar_estados" data-toggle="modal" data-target="#irregulares_modal">DATOS ACTUALIZADOS - PUEDES CERRAR ESTADOS IRREGULARES</button>
					<button style="display:none;" class="btn btn-danger mr-2" id="actualizar">MODIFICASTE DATOS - ACTUALIZAR Y CIERRA ESTADOS IRREGULARES</button>


				</td></table>




                  <div style="margin-top: -29px;" id="resumen">
                    <table border="1">
                           
                           <tr style="background-color:#3f5383;color:white;">
                                <td>Empresa</td>
                                <td>Clientes</td>
                                <td>Consumos Salida</td>
                                <td>Consumos Catering</td>
                                <td>Consumos SAP</td>
                                <td>Consumos GRANEL</td>
                                <td>Total</td>
                               
                            </tr>

                           <tr>
                                <td>Food Service America</td>
                                <td id="clientes_ame">0</td>
                                <td id="salida_ame">0</td>
                                <td id="catering_ame">0</td>
                                <td id="sap_ame">0</td>
                                <td id="granel_ame">0</td>
                                <td id="total_ame">0</td>
                            </tr>


                            <tr>
                                <td>Food Service Catering</td>
                                <td id="clientes_cat">0</td>
                                <td id="salida_cat">0</td>
                                <td id="catering_cat">0</td>
                                <td id="sap_cat">0</td>
                                <td id="granel_cat">0</td>
                                <td id="total_cat">0</td>
                            </tr>

                    </table>
                   </div>    

				</div>  

			</div>
			</div>
		</div> 
		<lista-carga v-if="loading"></lista-carga>
		<!-- Template content  -->
		<div class="row" v-else>
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table id="empresas" class="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Al</th>
									<th>Ce</th>
									<th>Vi</th>
									<th>Des</th>
									<th>Mer</th>
									<th>ACV/5 ANT<br><span id="feriado_act"></span></th>
									<th>ACV/5 ACT<br><span id="feriado_ant"></span></th>
									<th>ACV Ayer</th>
									<th>Stat</th>
									<th>Per</th>
									<th>M.O</th>
									<th>AUDITORIA</th>
									<th>Acción</th>
									<th>Razon social</th>
									<th>Cod</th>
									<th>Costos</th>
									<th>Tipo</th>
									<th>Zonal</th>
									<th>Regional</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="operacion in operaciones" v-bind:key="operacion.id">
									<td>{{operacion.name}}</td>
									<td>{{operacion.almuerzo}}</td>
									<td>{{operacion.cena}}</td>
									<td>{{operacion.vianda}}</td>
									<td>{{operacion.desayuno}}</td>
									<td>{{operacion.merienda}}</td>
									<td :class="operacion.feriados_ant">{{operacion.acvb}}</td>
									<td :class="operacion.feriados_act">{{operacion.acv}}</td>
									<td>{{operacion.ayer}}</td>
									<td>
										<span v-if="operacion.chart=='mayor'"  class="arraw up badge badge-success" style="color:#fff;">↑</span>
										<span v-if="operacion.chart=='menor'" class="arraw down  badge badge-danger" style="color:#fff;">↓</span>
										<span v-if="operacion.chart=='igual'" class="arraw igual badge badge-light" style="color:#000;">↔</span>
									</td>
									<td>{{operacion.personal.toString().replace('.',',')}}</td>
									<td v-if="operacion.manoobra === 'Correcto'"><span class="badge badge-success">{{operacion.manoobra}}</span></td>
									<td v-if="operacion.manoobra === 'Irregular'"><span class="badge badge-danger">{{operacion.manoobra}}</span></td>
									<td v-if="operacion.manoobra === 'Observar'"><span class="badge badge-warning">{{operacion.manoobra}}</span></td>



									<td v-if="operacion.manoobra === 'Irregular'">
										
										<button :class="{
                                  'badge-danger': (operacion.auditada == 'INJUSTIFICADA'),
                                  'badge-success': (operacion.auditada == 'JUSTIFICADA')
                              }" v-if="isEditor('Empresas')==true" type="button" class="btn btn-xs" :id="'operacion-'+operacion.id" :value="operacion.auditada"  @click="aprobar($event, operacion)">{{operacion.auditada}}<br>Actualizacion: {{operacion.auditada_fecha}}</button>
									
									</td>

									<td v-else> - </td>


									<td>
										
										<button v-if="isEditor('Empresas')==true" type="button" class="btn btn-primary btn-xs" @click="administrarOperacionId(operacion.id, operacion.name, 'personal')">Personal</button>
										<button v-else type="button" class="btn btn-secondary btn-xs" disabled>Personal</button>
										<button v-if="isEditor('Empresas')==true" class="btn btn-warning btn-xs ml-2" @click="administrarOperacionId(operacion.id, operacion.name, 'zonal')">Zonal</button>
										<button v-else class="btn btn-secondary btn-xs ml-2" disabled>Zonal</button>
											<button v-if="isEditor('Empresas')==true" class="btn btn-info btn-xs ml-2" @click="administrarOperacionId(operacion.id, operacion.name, 'regional')">Regional</button>
										<button v-else class="btn btn-secondary btn-xs ml-2" disabled>Regional</button>
										<button type="button" class="btn btn-secondary btn-xs ml-2" @click="observarOperacionId(operacion.id, operacion.name)">
											Observacion
											<div class="observacion-existente" v-if="operacion.observacion">✓</div>
										</button>
									</td>
									<td v-if="operacion.razonsocial=='Food Service America'"><span class="badge badge-success">{{operacion.razonsocial}}</span></td>
									<td v-else><span class="badge badge-info">{{operacion.razonsocial}}</span></td>
									<td>{{operacion.centroapropiacion_id}}</td>
									<td>{{operacion.codigo_costos}}</td>
									<td>{{operacion.tipo_empresa}}</td>
									<td>{{operacion.zonal}}</td>
									<td>{{operacion.regional}}</td>

								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			</div>
		</div>
		<!-- <div class="row">
			<div>
				<Select2 v-model="myValue" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
				<h4>Value: {{ myValue }}</h4>
			</div>
		</div> -->



<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="irregulares_modal" tabindex="-1" aria-labelledby="irregulares_modalLabel" aria-hidden="true">
   <div class="modal-dialog modal-lg  modal-dialog-scrollable">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title" id="irregulares_modalLabel"></h5>
        
      </div>
      <div class="modal-body " >
      	<table width="100%">

       <td><table id="contenido_irregulares" border="1" cellpadding="3" width="90%"></table></td>
       <td><table id="contenido_injustificadas" border="1" cellpadding="3" width="90%"></table></td>

    	 </table>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">CERRAR</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" id="guardar_estados">GUARDAR Y ENVIAR A APROBACIÓN</button>
      </div>
    </div>
  </div>
</div>



        <modal-administrar
            v-if="modalAdministrar" 
            :empresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-asignar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalAdministrar=false"
        >
        </modal-administrar>

		<modal-observar
			v-if="modalObservar" 
            :idEmpresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-observar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalObservar=false"
		></modal-observar>

		<modal-zonal
		v-if="modalZonal"
		:empresa="modalOperacionId"
		:nombreEmpresa="modalOperacionNombre"
		@cerrar-modal-zonal="refrescarEmpresas($event)"
		></modal-zonal>

		<modal-regional
		v-if="modalRegional"
		:empresa="modalOperacionId"
		:nombreEmpresa="modalOperacionNombre"
		@cerrar-modal-regional="refrescarEmpresas($event)"
		></modal-regional>
	</div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import ListaCarga from "../ListaCarga";
import ModalZonal from './ModalZonal.vue';
import ModalRegional from './ModalRegional.vue';
import firebase from "firebase/app";
import "firebase/database";
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import Swal from "sweetalert2";
import router from '../../router'

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: { 
        ModalAdministrar, 
        ListaCarga ,
		ModalObservar,
        ModalZonal,
         ModalRegional
    },    
	name: 'Empresas',
   
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Empresas',
            descripcion: 'En este modulo usted podra visualizar y administrar personal en todas nuestras operaciones',
			loading: true,
            operaciones: [],
			modalOperacionId: null,
			modalOperacionNombre: null,
			modalAdministrar: false,
			modalObservar: false,
			modalZonal: false,
			modalRegional: false,
			modalAprobar: false,
        }
    },

    methods: {
    	isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      	}, isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            // console.log(modulo+' : '+false)
            return false;
          }
      },
		administrarOperacionId(id, nombre, modal){
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				if (modal == 'personal') {
					this.modalAdministrar = true;
				} else if (modal=='zonal') {
					this.modalZonal = true;
				}else if (modal=='regional') {
					this.modalRegional = true;
				}
			}, 200);
		},
		aprobar(event, caso){
			var estado = "";
			if (event.target.value=="JUSTIFICADA") {
				estado = "INJUSTIFICADA";
			}

			if (event.target.value=="INJUSTIFICADA") {
				estado = "JUSTIFICADA";
			}


	      var hoy = new Date();

				var mesh = hoy.getMonth() + 1;
				var meshoy = mesh < 10 ? "0" + mesh : mesh;
				var diahoy= hoy.getDate();
				diahoy = diahoy < 10 ? "0" + diahoy : diahoy;
				var hoy_dato =  diahoy+ "-" + meshoy + "-" + hoy.getFullYear();

			console.log(event.target)
        firebase.database().ref('auditadas_data/' + caso.id).update({
            status: estado,
            fecha:hoy_dato
        })
        .then(()=>{
            
           	 caso.auditada = estado;
           	 caso.auditada_fecha = hoy_dato;

        		if (estado=="JUSTIFICADA") {
        			  $("#operacion-"+event.target.id).removeClass("badge-danger");
        				$("#operacion-"+event.target.id).addClass("badge-success");
        				$("#operacion-"+event.target.id).html("JUSTIFICADA<br>Actualizacion: "+hoy_dato);
        				console.log(estado)
        		}

        		if (estado=="INJUSTIFICADA") {
        			  $("#operacion-"+event.target.id).removeClass("badge-success");
        				$("#operacion-"+event.target.id).addClass("badge-danger");
        				$("#operacion-"+event.target.id).html("INJUSTIFICADA");
        				console.log(estado)
        		}

        		 $("#cerrar_estados").hide();
           	 $("#actualizar").show();
        		

        })
        .catch(e => {




        });


    },
		observarOperacionId(id, nombre){
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				this.modalObservar = true;
			}, 200);
		},
		async obtenerInformacionEmpresas(){
			
			this.loading = true;

			var lunes = new Date();
			lunes.setDate(lunes.getDate() - 5);
			var mma = lunes.getMonth() + 1;
			var mesma = mma < 10 ? "0" + mma : mma;
			var diama = lunes.getDate();
			diama = diama < 10 ? "0" + diama : diama;
			var pasado = lunes.getFullYear() + "-" + mesma + "-" + diama;
			
			


			var fecha1 = new Date(pasado);
			console.log(fecha1.getDay())
			var dias1 = (fecha1.getDay() - 1) * -1;

			var dias1b = (fecha1.getDay() + 4) * -1;

			fecha1.setDate(fecha1.getDate() + dias1);
			var mex = fecha1.getMonth() + 1;
			var mesex = mex < 10 ? "0" + mex : mex;
			var diaex = fecha1.getDate();
			diaex = diaex < 10 ? "0" + diaex : diaex;
			var lunespasadob = fecha1.getFullYear() + "-" + mesex + "-" + diaex;
			var dia1 = fecha1.getFullYear() + "-" + mesex + "-" + diaex;



			var fecha2 = new Date(lunespasadob);
			fecha2.setDate(fecha2.getDate() + 5);
			var mmax = fecha2.getMonth() + 1;
			var mesmax = mmax < 10 ? "0" + mmax : mmax;
			var diamax = fecha2.getDate();
			diamax = diamax < 10 ? "0" + diamax : diamax;
			var dia2 = fecha2.getFullYear() + "-" + mesmax + "-" + diamax;



			fecha1.setDate(fecha1.getDate() + dias1b);
			var mexb = fecha1.getMonth() + 1;
			var mesexb = mexb < 10 ? "0" + mexb : mexb;
			var diaexb = fecha1.getDate();
			diaexb = diaexb < 10 ? "0" + diaexb : diaexb;
			var lunespasadobb = fecha1.getFullYear() + "-" + mesexb + "-" + diaexb;
			var dia1b = fecha1.getFullYear() + "-" + mesexb + "-" + diaexb;


			var fecha2 = new Date(lunespasadobb);
			fecha2.setDate(fecha2.getDate() + 5);
			var mmaxb = fecha2.getMonth() + 1;
			var mesmaxb = mmaxb < 10 ? "0" + mmaxb : mmaxb;
			var diamaxb = fecha2.getDate();
			diamaxb = diamaxb < 10 ? "0" + diamaxb : diamaxb;
			var dia2b = fecha2.getFullYear() + "-" + mesmaxb + "-" + diamaxb;




				var ayerx = new Date();
				ayerx.setDate(ayerx.getDate() - 1);
				var mmax = ayerx.getMonth() + 1;
				var mesmax = mmax < 10 ? "0" + mmax : mmax;
				var diamax= ayerx.getDate();
				diamax = diamax < 10 ? "0" + diamax : diamax;
				var ayer = ayerx.getFullYear() + "-" + mesmax + "-" + diamax;



				var hoy = new Date();

				var mesh = hoy.getMonth() + 1;
				var meshoy = mesh < 10 ? "0" + mesh : mesh;
				var diahoy= hoy.getDate();
				diahoy = diahoy < 10 ? "0" + diahoy : diahoy;
				var hoy_dato = hoy.getFullYear() + "-" + meshoy + "-" + diahoy;

				console.log(dia1)
				console.log(dia2)
				console.log("------------------------------")
				console.log(dia1b)
				console.log(dia2b)

		const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
		const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
		const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
		const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/observacion.json?print=pretty');
		const axiosrequest6 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+dia1+'/'+dia2);
		const axiosrequest7 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+dia1b+'/'+dia2b);
		const axiosrequest8 = axios.get('https://consumos.foodservice.com.ar/api/totalEmpresas/'+ayer+'/'+ayer);
		const axiosPersonal = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
		const feriados_data = axios.get('https://aplicativos.firebaseio.com/feriados_data.json?print=pretty');
		const auditadas_dato = axios.get('https://aplicativos.firebaseio.com/auditadas_data.json?print=pretty');
		const regional_dato = axios.get('https://aplicativos.firebaseio.com/regional.json?print=pretty');

		
        


		await axios.all([axiosrequest1, axiosrequest2, axiosrequest4, axiosrequest5,axiosrequest6,axiosrequest7,axiosPersonal,axiosrequest8,feriados_data,auditadas_dato,regional_dato]).then(axios.spread((operaciones, zonales, distribucion, observaciones,servicios,serviciosb,personal,serviciosc,feriados,auditadas_data,regional_data) => {	





					// Crear un array para almacenar los días de lunes a viernes
					var diasSemanaPasada = [];

					// Recorrer los días de la semana pasada y agregar los días de lunes a viernes al array
					for (var i = 1; i <= 5; i++) {
					  
					  var dia = new Date(dia1);
					  dia.setDate(dia.getDate() + i);

					  

			      var fech = new Date(dia);
						
						var mmax_c = fech.getMonth() + 1;
						var mesmaxc = mmax_c < 10 ? "0" + mmax_c : mmax_c;
						var diamaxc = fech.getDate();
						diamaxc = diamaxc < 10 ? "0" + diamaxc : diamaxc;
						var dia_ok = fech.getFullYear() + "-" + mesmaxc + "-" + diamaxc;

						diasSemanaPasada.push(dia_ok);

					}





						// Crear un array para almacenar los días de lunes a viernes
					var diasSemanaPasadaAnterior = [];

					// Recorrer los días de la semana pasada y agregar los días de lunes a viernes al array
					for (var i = 1; i <= 5; i++) {
					  
					  var dia_b = new Date(dia1b);
					  dia_b.setDate(dia_b.getDate() + i);

					  

			      var fech_b = new Date(dia_b);
						
						var mmax_c_b = fech_b.getMonth() + 1;
						var mesmaxc_b = mmax_c_b < 10 ? "0" + mmax_c_b : mmax_c_b;
						var diamaxc_b = fech_b.getDate();
						diamaxc_b = diamaxc_b < 10 ? "0" + diamaxc_b : diamaxc_b;
						var dia_ok_b = fech_b.getFullYear() + "-" + mesmaxc_b + "-" + diamaxc_b;

						diasSemanaPasadaAnterior.push(dia_ok_b);

					}



						// Imprimir los días de la semana pasada
						console.log(diasSemanaPasada);

						// Imprimir los días de la semana pasada anterior
						console.log(diasSemanaPasadaAnterior);


				

					var dias_feriados=[];
					

					for (var i = 0; i < feriados.data.length; i++) {
						if (feriados.data[i]!=null) {
							
								        var dia_f = feriados.data[i].dia < 10 ? '0' + feriados.data[i].dia : feriados.data[i].dia.toString();
												var mes_f = feriados.data[i].mes < 10 ? '0' + feriados.data[i].mes : feriados.data[i].mes.toString();
												var anio_f =feriados.data[i].anio;

												dias_feriados.push(anio_f+"-"+mes_f+"-"+dia_f);
						}
						
					}


					


					const numerosComunes = diasSemanaPasada.filter(numero => dias_feriados.includes(numero));
					const cantidadNumerosComunes = numerosComunes.length;


					const numerosComunes_b = diasSemanaPasadaAnterior.filter(numero => dias_feriados.includes(numero));
					const cantidadNumerosComunes_b = numerosComunes_b.length;


					console.log(cantidadNumerosComunes)
					console.log(cantidadNumerosComunes_b)

					var divisor = 5-cantidadNumerosComunes;


					var estiloFeriado_act=5-cantidadNumerosComunes;
					var estiloFeriado_ant=5-cantidadNumerosComunes_b;



				 /**
				 * Se recorre el personal y comprueba si el cat_codigo es uno de part time
				 * Si lo es, en un array guarda true a partir de la clave legajo.
				 * Por ejemplo ["4658"] = true. Así se obtiene una complejidad O(n)
				 */
				var empleadosPartTime = [];
				$.each(personal.data,function(clave,item){ 
					
					let cat = item.cat_codigo;
				
					// Busqueda de legajos con las categorias: 65,77, 78, 79, 80, 81, 82
					if (cat == "65" || cat == "77" || cat == "78" || cat == "79" || cat=="80" || cat == "81" || cat == "82"|| cat == "73") {
	
						empleadosPartTime[item.leg_numero.toString()] = true
					}
				})
				
				
				var services = [];
				var servicesb = [];
				var servicesc = [];

				let jsons = Object.assign(servicios.data[0].CAT, servicios.data[1].SAL)

				console.log(jsons)
			
				$.each(jsons,function(clave,valor){				
					if(valor.Servicios.VIANDAS!=undefined){
						services.push({
							"cantidad": valor.Servicios.VIANDAS,
							"cantidad_categoria": valor.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.ALMUERZO!=undefined){
						services.push({
							"cantidad": valor.Servicios.ALMUERZO,
							"cantidad_categoria": valor.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.CENA!=undefined){
						services.push({
							"cantidad": valor.Servicios.CENA,
							"cantidad_categoria": valor.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valor.Servicios.DESAYUNOS!=undefined){

						services.push({
							"cantidad": valor.Servicios.DESAYUNOS,
							"cantidad_categoria": valor.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valor.Servicios.DESAYUNOS!=undefined){

						services.push({
							"cantidad": valor.Servicios.DESAYUNOS,
							"cantidad_categoria": valor.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valor.Servicios.MERIENDA!=undefined){

						services.push({
							"cantidad": valor.Servicios.MERIENDA,
							"cantidad_categoria": valor.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clave,
							"empresa_id": valor.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});



	/* ------ 2 SEMANAS ATRAS  ------- */


	let jsonsb = Object.assign(serviciosb.data[0].CAT, serviciosb.data[1].SAL)

				// console.log(jsons)
			
				$.each(jsonsb,function(claveb,valorb){				
					

					if(valorb.Servicios.VIANDAS!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.VIANDAS,
							"cantidad_categoria": valorb.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.ALMUERZO!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.ALMUERZO,
							"cantidad_categoria": valorb.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.CENA!=undefined){
						servicesb.push({
							"cantidad": valorb.Servicios.CENA,
							"cantidad_categoria": valorb.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valorb.Servicios.DESAYUNOS!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.DESAYUNOS,
							"cantidad_categoria": valorb.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorb.Servicios.DESAYUNOS!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.DESAYUNOS,
							"cantidad_categoria": valorb.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorb.Servicios.MERIENDA!=undefined){

						servicesb.push({
							"cantidad": valorb.Servicios.MERIENDA,
							"cantidad_categoria": valorb.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": claveb,
							"empresa_id": valorb.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});

			
		/* ------ CIERRA 2 SEMANAS ATRAS  ------- */		



/* ------ AYER   ------- */


	let jsonsc = Object.assign(serviciosc.data[0].CAT, serviciosc.data[1].SAL)

				
			
				$.each(jsonsc,function(clavec,valorc){				
					

					if(valorc.Servicios.VIANDAS!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.VIANDAS,
							"cantidad_categoria": valorc.Servicios.VIANDAS,
							"categoria": "VIANDAS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "VIANDAS",
							"servicio_id": 0
						});
					}



					if(valorc.Servicios.ALMUERZO!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.ALMUERZO,
							"cantidad_categoria": valorc.Servicios.ALMUERZO,
							"categoria": "ALMUERZO",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "ALMUERZO",
							"servicio_id": 0
						});
					}

					if(valorc.Servicios.CENA!=undefined){
						servicesc.push({
							"cantidad": valorc.Servicios.CENA,
							"cantidad_categoria": valorc.Servicios.CENA,
							"categoria": "CENA",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "CENA",
							"servicio_id": 0
						});
					}

					if(valorc.Servicios.DESAYUNOS!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.DESAYUNOS,
							"cantidad_categoria": valorc.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorc.Servicios.DESAYUNOS!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.DESAYUNOS,
							"cantidad_categoria": valorc.Servicios.DESAYUNOS,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "DESAYUNOS",
							"servicio_id": 0
						});

					}


					if(valorc.Servicios.MERIENDA!=undefined){

						servicesc.push({
							"cantidad": valorc.Servicios.MERIENDA,
							"cantidad_categoria": valorc.Servicios.MERIENDA,
							"categoria": "DESAYUNOS",
							"catering_id": 0,
							"empresa": clavec,
							"empresa_id": valorc.empresa_id,
							"nombre_servicio": "MERIENDA",
							"servicio_id": 0
						});

					}
					


				});

			
		/* ------ CIERRA AYER  ------- */		






			var lista = [];
			var irregulares = [];
			var justificadas = [];

			var zonal = "-";
			var regional = "-";
			var almuerzo = 0;
			var cena = 0;
			var vianda = 0;
			var desayuno = 0;
			var merienda = 0;
			var personal = 0;
			var num = 0;
			var status = "-";
			var observacion = false;
			var legales = false;
			

			var almuerzob = 0;
			var cenab = 0;
			var viandab = 0;



			var almuerzo_a = 0;
			var cena_a = 0;
			var vianda_a = 0;
			var desayuno_a = 0;
			var merienda_a = 0;

			var almuerzo_ab = 0;
			var cena_ab = 0;
			var vianda_ab = 0;


			var almuerzoc = 0;
			var cenac = 0;
			var viandac = 0;
			var almuerzo_ac = 0;
			var cena_ac = 0;
			var vianda_ac = 0;

		// console.log(operaciones.data);
		$.each(operaciones.data, function(clave, valor) {

			

			var acv = 0;
			var acvb = 0;
			var acvc = 0;

			var key_zonal = Object.keys(zonales.data).filter(key => key == valor.id);

			var result_zonal = key_zonal.reduce((r, k) => r.concat(zonales.data[k]), []);
			if (key_zonal.length!=0) {
				zonal = result_zonal[0].nombre;
			}else{
				zonal = '-';
			}



if (regional_data.data!=null) {
			var key_regional = Object.keys(regional_data.data).filter(key => key == valor.id);
			
			var result_regional = key_regional.reduce((r, k) => r.concat(regional_data.data[k]), []);
			
			if (result_regional[0]==null) {
				
				regional = "-";
			}else{
				regional = result_regional[0].nombre;
			}
}else{
		regional = '-';
}



			var key_distribucion = Object.keys(distribucion.data).filter(key => key == valor.id);

			if (distribucion.data[key_distribucion[0]] != null) {
				// Si la operación tiene personal distribuido. Se cuentan los part-time
				let cantPersonal = 0;
				Object.keys(distribucion.data[key_distribucion[0]]).forEach((i) => {
					let legajo = distribucion.data[key_distribucion[0]][i].legajo;
					// Si el legajo esta en el array EmpleadosPArtTime se suma 0.5
					if (empleadosPartTime[legajo] != undefined){
						cantPersonal += 0.5;
					}else{
						cantPersonal += 1;
					}
					//cantPersonal += (empleadosPartTime[legajo] != undefined) ? 0.5 : 1;
				});
				personal = cantPersonal;
			}else{
				personal = 0;
			}

			var consumo = services.filter((el) => el.empresa_id == valor.id);
			var consumob = servicesb.filter((el) => el.empresa_id == valor.id);
			var consumoc = servicesc.filter((el) => el.empresa_id == valor.id);


			/* --- CONSUMOS --- */







			

			// console.log(consumo);
			if (valor.estado=='ACTIVA') {

				/* --- CONSUMOS --- */
				var result_almuerzo = consumo.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzo.length != 0) {
					almuerzo = result_almuerzo[0].cantidad_categoria;
					almuerzo_a = Math.round(result_almuerzo[0].cantidad_categoria/divisor);
				}else{
					almuerzo = 0;
					almuerzo_a = 0;
				}

				var result_cena = consumo.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cena.length != 0) {
					cena = result_cena[0].cantidad_categoria;
					cena_a = Math.round(result_cena[0].cantidad_categoria/divisor);
				}else{
					cena = 0;
					cena_a = 0;
				}

				var result_viandas = consumo.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandas.length != 0) {
					vianda = result_viandas[0].cantidad_categoria;
					vianda_a = Math.round(result_viandas[0].cantidad_categoria/divisor);
				}else{
					vianda = 0;
					vianda_a = 0;
				}


				/*----  2 SEMANAS ANTERIOR ----- */

				/* --- CONSUMOS --- */
				var result_almuerzob = consumob.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzob.length != 0) {
					almuerzob = result_almuerzob[0].cantidad_categoria;
					almuerzo_ab= Math.round(result_almuerzob[0].cantidad_categoria/5);
				}else{
					almuerzob = 0;
					almuerzo_ab = 0;
				}

				var result_cenab = consumob.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cenab.length != 0) {
					cenab = result_cenab[0].cantidad_categoria;
					cena_ab = Math.round(result_cenab[0].cantidad_categoria/5);
				}else{
					cenab = 0;
					cena_ab = 0;
				}

				var result_viandasb = consumob.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandasb.length != 0) {
					viandab = result_viandasb[0].cantidad_categoria;
					vianda_ab = Math.round(result_viandasb[0].cantidad_categoria/5);
				}else{
					viandab = 0;
					vianda_ab = 0;
				}

				/*----  2 SEMANAS ANTERIOR ----- */



					/*----  AYER ----- */

				/* --- CONSUMOS --- */
				var result_almuerzoc = consumoc.filter(function(c) {
					return c.categoria == "ALMUERZO"
				});
				if (result_almuerzoc.length != 0) {
					almuerzoc = result_almuerzoc[0].cantidad_categoria;
					almuerzo_ac= Math.round(result_almuerzoc[0].cantidad_categoria);
				}else{
					almuerzoc = 0;
					almuerzo_ac = 0;
				}

				var result_cenac = consumoc.filter(function(c) {
					return c.categoria == "CENA"
				});
				if (result_cenac.length != 0) {
					cenac = result_cenac[0].cantidad_categoria;
					cena_ac = Math.round(result_cenac[0].cantidad_categoria);
				}else{
					cenac = 0;
					cena_ac = 0;
				}

				var result_viandasc = consumoc.filter(function(c) {
					return c.categoria == "VIANDAS"
				});
				if (result_viandasc.length != 0) {
					viandac = result_viandasc[0].cantidad_categoria;
					vianda_ac = Math.round(result_viandasc[0].cantidad_categoria);
				}else{
					viandac = 0;
					vianda_ac = 0;
				}

				/*----  2 AYER ----- */


				var result_desayuno = consumo.filter(function(c) {
					return c.categoria == "DESAYUNOS"
				});
				if (result_desayuno.length != 0) {
					desayuno = result_desayuno[0].cantidad_categoria;
					desayuno_a = Math.round(result_desayuno[0].cantidad_categoria/5);
				}else{
					desayuno = 0;
					desayuno_a = 0;
				}


				
				var result_merienda = consumo.filter(function(c) {
					return c.categoria == "MERIENDA"
				});
				if (result_merienda.length != 0) {
					merienda = result_merienda[0].cantidad_categoria;
					merienda_a = Math.round(result_merienda[0].cantidad_categoria/5);
				}else{
					merienda = 0;
					merienda_a = 0;
				}







				acv = parseInt(almuerzo_a) + parseInt(cena_a) + parseInt(vianda_a);
				acvb = parseInt(almuerzo_ab) + parseInt(cena_ab) + parseInt(vianda_ab);
				acvc = parseInt(almuerzo_ac) + parseInt(cena_ac) + parseInt(vianda_ac);
				/* --- CONSUMOS --- */
				acv = Math.round(acv);
				acvb = Math.round(acvb);
				acvc = Math.round(acvc);
				/* --- CONSUMOS --- */
				

				/* --  MANO DE OBRA  --*/
				

				if(valor.tipo_empresa=="COMEDOR"){



						if (acv < 300) {
							num = Math.round(acv / 40);
						}

						if (acv > 300) {
							num = Math.round(acv / 50);
						}

						




				}

				if(valor.tipo_empresa=="CATERING"){

						num = Math.round(acv / 70);



						

				}



				if (personal > num) {
					status = "Irregular";
				}

				if (personal < num) {

					status = "Correcto";
				}

				if (personal == num) {
					status = "Observar";
				}



				/* --  MANO DE OBRA  --*/


				var comentario = "-";
				var comentario_status = (observaciones.data[valor.id] != undefined) ? true : false;
				if (comentario_status) {
					comentario = observaciones.data[valor.id].observacion
				}

				
				

				// Observacion
				observacion = (observaciones.data[valor.id] != undefined) ? true : false;

				var chart = "igual";
				if (acv>acvb) {
					chart = "mayor"
				}

				if (acv<acvb) {
					chart = "menor"
				}

var emp_tipo = valor.tipo_empresa;


						if(valor.granel=="S"){

							num = Math.round(acv / 60);

							emp_tipo = "GRANEL";
						
						}

						if(valor.armado_tipo_empresa_id=="2"){

							num = Math.round(acv / 0);

							emp_tipo = "SAP";

							if (personal == 0) {
								status = "Correcto";
							}else{
								status = "Irregular";
							}
						
						}


						

var auditadas="INJUSTIFICADA";
var auditadas_fecha="-";

if (auditadas_data.data!=null) {
	if (auditadas_data.data[valor.id]!=null) {
		  
		  if (auditadas_data.data[valor.id].status=="INJUSTIFICADA") {
		  	auditadas="INJUSTIFICADA";
		  }

		  if (auditadas_data.data[valor.id].status=="JUSTIFICADA") {
		  	auditadas="JUSTIFICADA";
		  	auditadas_fecha=auditadas_data.data[valor.id].fecha;
		  }
	}
}


var estilo_act = "noferiado";
var estilo_ant = "noferiado";
if ((5-estiloFeriado_act)>0) {
	estilo_act = "feriado";
	
}


if ((5-estiloFeriado_ant)>0) {
	estilo_ant = "feriado";
}




					lista.push({
						"id_consumos": valor.id,
						"razonsocial": valor.empresa_madre,
						"id": valor.id,
						"centroapropiacion_id": valor.catering_id,
						"codigo_costos": valor.codigo_costos,
						"name": valor.name,
						"tipo_empresa": emp_tipo,
						"zonal": zonal,
						"regional": regional,
						"almuerzo": almuerzo_a,
						"cena": cena_a,
						"vianda": vianda_a,
						"desayuno": desayuno_a,
						"merienda": merienda_a,
						"acv": acv,
						"acvb": acvb,
						"ayer": acvc,
						"chart":chart,
						"personal": personal,
						"manoobra":status,
						"observacion":observacion,
						"auditada":auditadas,
						"auditada_fecha":auditadas_fecha,
						"feriados_act":estilo_act,
						"feriados_ant":estilo_ant
	                });


					if (status=="Irregular") {
							
						if (auditadas=="INJUSTIFICADA") {
							irregulares.push({
								"id_consumos": valor.id,
								"razonsocial": valor.empresa_madre,
								"id": valor.id,
								"centroapropiacion_id": valor.catering_id,
								"codigo_costos": valor.codigo_costos,
								"name": valor.name,
								"tipo_empresa": valor.tipo_empresa,
								"zonal": zonal,
								"regional": regional,
								"almuerzo": almuerzo_a,
								"cena": cena_a,
								"vianda": vianda_a,
								"desayuno": desayuno_a,
								"merienda": merienda_a,
								"acv": acv,
								"acvb": acvb,
								"ayer": acvc,
								"chart":chart,
								"personal": personal,
								"manoobra":status,
								"observacion":comentario,
								"auditada":auditadas

			                });
							}

							if (auditadas=="JUSTIFICADA") {
							justificadas.push({
								"id_consumos": valor.id,
								"razonsocial": valor.empresa_madre,
								"id": valor.id,
								"centroapropiacion_id": valor.catering_id,
								"codigo_costos": valor.codigo_costos,
								"name": valor.name,
								"tipo_empresa": valor.tipo_empresa,
								"zonal": zonal,
								"regional": regional,
								"almuerzo": almuerzo_a,
								"cena": cena_a,
								"vianda": vianda_a,
								"desayuno": desayuno_a,
								"merienda": merienda_a,
								"acv": acv,
								"acvb": acvb,
								"ayer": acvc,
								"chart":chart,
								"personal": personal,
								"manoobra":status,
								"observacion":comentario,
								"auditada":auditadas

			                });
							}
					}



                }


            });


/*

<td id="clientes_ame">0</td>
<td id="salida_ame">0</td>
<td id="catering_ame">0</td>
<td id="sap_ame">0</td>
<td id="granel_ame">0</td>

*/

var clientes_ame = 0;
var salida_ame = 0;
var catering_ame = 0;
var sap_ame = 0;
var granel_ame = 0;


var clientes_cat = 0;
var salida_cat = 0;
var catering_cat = 0;
var sap_cat = 0;
var granel_cat = 0;

$.each(lista,function(clave,valor){



if (valor.razonsocial=="Food Service America") {
		clientes_ame++

		if (valor.tipo_empresa=="COMEDOR") {
		
			salida_ame=salida_ame+valor.acv;

		}

		if (valor.tipo_empresa=="CATERING") {
		
			catering_ame=catering_ame+valor.acv;

		}

		if (valor.tipo_empresa=="SAP") {
		
			sap_ame=sap_ame+valor.acv;

		}

		if (valor.tipo_empresa=="GRANEL") {
		
			granel_ame=granel_ame+valor.acv;

		}

}



if (valor.razonsocial=="Food Service Catering") {
		clientes_cat++

		if (valor.tipo_empresa=="COMEDOR") {
		
			salida_cat=salida_cat+valor.acv;

		}

		if (valor.tipo_empresa=="CATERING") {
		
			catering_cat=catering_cat+valor.acv;

		}

		if (valor.tipo_empresa=="SAP") {
		
			sap_cat=sap_cat+valor.acv;

		}

		if (valor.tipo_empresa=="GRANEL") {
		
			granel_cat=granel_cat+valor.acv;

		}

}





});


$("#clientes_ame").html(clientes_ame)
$("#salida_ame").html(salida_ame)
$("#catering_ame").html(catering_ame)
$("#sap_ame").html(sap_ame)
$("#granel_ame").html(granel_ame)

var total_ame=clientes_ame+salida_ame+catering_ame+sap_ame+granel_ame;
$("#total_ame").html(total_ame)



$("#clientes_cat").html(clientes_cat)
$("#salida_cat").html(salida_cat)
$("#catering_cat").html(catering_cat)
$("#sap_cat").html(sap_cat)
$("#granel_cat").html(granel_cat)

var total_cat=clientes_cat+salida_cat+catering_cat+sap_cat+granel_cat;
$("#total_cat").html(total_cat)

			
            this.operaciones = lista;




            
             localStorage.removeItem("go");
             $("#actualizar").click(function(){

	             		


									localStorage.setItem("go","Empresas")
									router.go({ name: 'Dashboard' })

            })

            $("#cerrar_estados").click(function(){





            	$("#contenido_irregulares").html("");
            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td colspan="6">TOTAL DE OPERACIONES IRREGULARES INJUSTIFICADAS: '+irregulares.length+'</td></tr>');
            	$("#contenido_irregulares").append('<tr style="background-color: #6a1a1a;color:#fff;font-weight: bold;"><td>OPERACIÓN</td><td>TIPO</td><td>ACV/5</td><td>PERSONAL</td><td>STATUS</td><td>OBSERVACIÓN</td></tr>');
            	console.log("IRREGULARES")
            	
            	$("#irregulares_modalLabel").html("Operaciones IRREGULARES: "+hoy_dato)

            	

            	$.each(irregulares,function(clave,valor){
            		console.log(valor.name)
            		$("#contenido_irregulares").append("<tr><td align='left'>"+valor.name+"</td><td align='left'>"+valor.tipo_empresa+"</td><td align='left'>"+valor.acv+"</td><td align='left'>"+valor.personal+"</td><td align='left'>"+valor.manoobra+"</td><td align='left'>"+valor.observacion+"</td></tr>")
            	})






            	$("#contenido_injustificadas").html("");
            	$("#contenido_injustificadas").append('<tr style="background-color: #0f540d;color:#fff;font-weight: bold;"><td colspan="6">TOTAL DE OPERACIONES IRREGULARES JUSTIFICADAS: '+justificadas.length+'</td></tr>');
            	$("#contenido_injustificadas").append('<tr style="background-color: #0f540d;color:#fff;font-weight: bold;"><td>OPERACIÓN</td><td>TIPO</td><td>ACV/5</td><td>PERSONAL</td><td>STATUS</td><td>OBSERVACIÓN</td></tr>');
            	console.log("JUSTIFICADAS")
            	
            	$("#irregulares_modalLabel").html("Operaciones IRREGULARES: "+hoy_dato)

            	

            	$.each(justificadas,function(clave,valor){
            		console.log(valor.name)
            		$("#contenido_injustificadas").append("<tr><td align='left'>"+valor.name+"</td><td align='left'>"+valor.tipo_empresa+"</td><td align='left'>"+valor.acv+"</td><td align='left'>"+valor.personal+"</td><td align='left'>"+valor.manoobra+"</td><td align='left'>"+valor.observacion+"</td></tr>")
            	})

            	
            	


            })

let obj3 = Object.assign(justificadas, irregulares);


             $("#guardar_estados").click(function(){
            	Swal.showLoading()
            	firebase
			        .database()
			        .ref("operaciones_irregulares/"+hoy_dato)
			        .push(obj3,(error) => {
			            
					            if (error) {
					              alert(
					                "error: no se pudo asignar el empleado en la operacion /n verificá la conexión a internet o reintentalo"
					              );
					            } else {
					              console.log("DATOS GUARDADOS EXITOSAMENTE");

					              
					              
					            }
			          }
			        ).then((snap) => {
					     const key = snap.key 
					     var encodedData = window.btoa(hoy_dato+"/"+snap.key);
					     console.log(encodedData)

							     axios
		                          .post(
		                            " https://consumos.foodservice.com.ar/api/enviomail",
		                            {
		                              from: "no-reply@foodservice.com.ar",
		                              to: "amiranda@foodservice.com.ar",
		                              subject: "TIENES OPERACIONES IRREGULARES QUE APROBAR!",
		                              body: "<br><center><div style='background-color:#f9fafb;width:800px;padding:30px;'><table  width='700px' align='center' cellspacing='10'><td>Ingresa a Genesis con tus datos de acceso. Verifica y desaprueba las operaciónes irregulares que consideres para ser enviadas a los regionales y responsables para corregir la situación.<br><br> <a href='https://genesis.foodservice.com.ar'>https://genesis.foodservice.com.ar</a> <br> <br> Recuerda acceder con tu clave segura.</td></table></div></center>",
		                            }
		                          )
		                          .then((response) => {
		                           				 Swal.close();
                                    Swal.fire(
                                      "Datos guardados y enviados correctamente!",
                                      "Si el responsable no encuentra en su bandeja de entrada informa que verifique en su casilla de spam",
                                      "success"
                                    );
		                            })

		                           

            })
			         })


		})).then(() => {
			this.loading = false;
			this.$nextTick(()=>{
				console.log("hola mundo")


				
				$('#empresas').DataTable({
					"columnDefs": [
    { "width": "200px", "targets": 0 }
  ],
					"language": {
						"decimal": ",",
						"thousands": ".",
						"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
						"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
						"infoPostFix": "",
						"infoFiltered": "(filtrado de un total de _MAX_ registros)",
						"loadingRecords": "Cargando...",
						"lengthMenu": "Mostrar _MENU_ registros",
						"paginate": {
							"first": "Primero",
							"last": "Último",
							"next": "Siguiente",
							"previous": "Anterior"
						},
						"processing": "Procesando...",
						"search": "Buscar:",
						"searchPlaceholder": "Término de búsqueda",
						"zeroRecords": "No se encontraron resultados",
						"emptyTable": "Ningún dato disponible en esta tabla",
						"aria": {
							"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
							"sortDescending": ": Activar para ordenar la columna de manera descendente"
						},
					},

					"scrollX": true, 
					"search": {regex:true},
					dom: 'Bfrtip',
					responsive: true,
					nowrap: false,
					buttons: [
						'copy',
						 {
        extend: 'excel',
        text: 'Exportar a Excel',
        customizeData: function (data) {
          // Recorrer los datos
          for (var i = 0; i < data.body.length; i++) {
            var row = data.body[i];
            for (var j = 0; j < row.length; j++) {
              var cellData = row[j];
              // Verificar si el valor contiene una coma
              if (typeof cellData === 'string' && cellData.includes(',')) {
                // Reemplazar la coma por el punto o cualquier otro separador deseado
                row[j] = cellData.replace(',', '.');
              }
            }
          }
        }
      },
						'csv',
						'pdf'
					]
				})
			})
		});

		},
		refrescarEmpresas(seReasigno){
            this.modalAdministrar = false;
            this.modalObservar = false;
			this.modalZonal = false;
			this.modalRegional = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerInformacionEmpresas();
            }
        },
    },
	async mounted() {
		this.obtenerInformacionEmpresas();		
    },
}

</script>

<style scoped>
.observacion-existente {
	background: #10b759;
	border-radius: 50%;
	padding: 3px 4px;
	padding-top: 4px;
	width: 16px;
	height: 16px;
	position: absolute;
	transform: translate(52px, -20px);
	font-size: 12px;
}

.modal-lg, .modal-xl {
    max-width: 90%;
}

.feriado{
	background-color: #f1e7e78f;
}


#resumen td{
	padding: 10px;
}

</style>